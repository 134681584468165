export const feedbackConstants = {

    ADD_CONTACT_DETAILS_REQUEST: 'ADD_CONTACT_DETAILS_REQUEST',
    ADD_CONTACT_DETAILS_SUCCESS: 'ADD_CONTACT_DETAILS_SUCCESS',
    ADD_CONTACT_DETAILS_FAILURE: 'ADD_CONTACT_DETAILS_FAILURE',

    ADD_NEWSLETTER_REQUEST: 'ADD_NEWSLETTER_REQUEST',
    ADD_NEWSLETTER_SUCCESS: 'ADD_NEWSLETTER_SUCCESS',
    ADD_NEWSLETTER_FAILURE: 'ADD_NEWSLETTER_FAILURE',

    ADD_TESTIMONIALS_REQUEST: 'ADD_TESTIMONIALS_REQUEST',
    ADD_TESTIMONIALS_SUCCESS: 'ADD_TESTIMONIALS_SUCCESS',
    ADD_TESTIMONIALS_FAILURE: 'ADD_TESTIMONIALS_FAILURE',

    GET_FAQ_REQUEST: 'GET_FAQ_REQUEST',
    GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
    GET_FAQ_FAILURE: 'GET_FAQ_FAILURE',

    GET_COMPETITION_REQUEST: 'GET_COMPETITION_REQUEST',
    GET_COMPETITION_SUCCESS: 'GET_COMPETITION_SUCCESS',
    GET_COMPETITION_FAILURE: 'GET_COMPETITION_FAILURE',

    GET_MARKETING_REQUEST: 'GET_MARKETING_REQUEST',
    GET_MARKETING_SUCCESS: 'GET_MARKETING_SUCCESS',
    GET_MARKETING_FAILURE: 'GET_MARKETING_FAILURE',

    GET_TESTIMONIALS_REQUEST: 'GET_TESTIMONIALS_REQUEST',
    GET_TESTIMONIALS_SUCCESS: 'GET_TESTIMONIALS_SUCCESS',
    GET_TESTIMONIALS_FAILURE: 'GET_TESTIMONIALS_FAILURE'

}