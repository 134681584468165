import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { apiConstants } from '../../constants';
import { nomineeActions } from '../../actions/nominee.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Card from 'react-bootstrap/Card';

export const NomineeUser = () => {
  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState([]);
  const rejectedDomain = ["mailinator.com"];

  const { alert, language, nomineeUserData } = useSelector(
    state => ({
      alert: state.alert,
      language: state.language,
      nomineeUserData: state.getNominee
    })
  );

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      contact: "",
      email: ""
    },
    validationSchema: Yup.object().shape({
      firstname: Yup.string()
        .required("Please Enter first name!"),
      lastname: Yup.string()
        .required("Please Enter last name!"),
      contact: Yup.string()
        .required("Please Enter Contact"),
      email: Yup.string()
        .required("Please Enter Email")
        .email('Must be a valid email')
        .test('email', 'Domain not accepted',
          function (value) {

            if (value !== undefined) {
              var splitArray = value.split('@');
              if (rejectedDomain.indexOf(splitArray[1]) >= 0) {
                return false
              }
              else {
                return true
              }
            }
          })
    }),

    onSubmit: values => {
      let obj = {
        firstname: values.firstname,
        lastname: values.lastname,
        contact: values.contact,
        email: values.email
      }
      dispatch(nomineeActions.updateNominee(apiConstants.UPDATE_NOMINEE, obj))
    }
  });

  useEffect(() => {
    getNomineeDetails();
    return () => {
      setAlertRes('')
    }
  }, [])

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr = [];
      if (alert.type === 'alert-success') {
        tempErr = [{ 'type': alert.type, 'message': alert.message }]
      }
      else if (alert.type === 'alert-danger') {
        if (alert.message.errors !== undefined) {
          if (typeof alert.message.errors == "string") {
            tempErr.push({ 'type': alert.type, message: alert.message.errors })
          }
          else {
            let keys = Object.keys(alert.message.errors)
            if (keys.length !== 0) {
              keys.map(data => {
                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
              })
            }
            else {
              tempErr = [{ 'type': alert.type, message: alert.message }]
            }
          }
        }
        else {
          tempErr = [{ 'type': alert.type, message: alert.message }]
        }

      }
      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 3000);
    }
  }, [alert]);

  const getNomineeDetails = () => {
    dispatch(nomineeActions.getNominee(apiConstants.GET_NOMINEE));
  }

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language]);

  useEffect(() => {
    if (Object.keys(nomineeUserData).length > 0) {

      if (nomineeUserData.loading) {

        if (nomineeUserData.nominee !== undefined && nomineeUserData.nominee !== null) {

          if (nomineeUserData !== '') {

            let details = nomineeUserData.nominee
            formik.values.firstname = details.firstname !== null ? details.firstname : '';
            formik.values.lastname = details.lastname !== null ? details.lastname : '';
            formik.values.contact = details.contact !== '' ? details.contact : '';
            formik.values.email = details.email !== null ? details.email : '';

          }
        }
      }
    }

  }, [nomineeUserData])

  return (
    <div className="main-wrap">
      <Header />
      <div className="dashboard-wraper">
        <Sidebar />
        <div className="content-wrap">
          {alertRes !== '' ?
            alertRes.length > 0 ? alertRes.map((data, index) => {
              return (
                <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'} className="alertBox">
                  {data.message}
                </Alert>)
            }) : '' : ''}
          <div className="dashboard-content p">
            <div className="dash-body-blk">
              <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>
              <Card>
                <Card.Body>
                  <Card.Title bg="light">Nominee User</Card.Title>
                  {/* <Card.Text> */}
                  <section className="User-details-sec">
                    <div className="top-use-details" style={{ 'padding': '5px' }}>
                      <div className="container">
                        <div className="row">
                          {/* <div className="col-md-3">
                            <div className="image-container">
                              <img className="header-profile-icon" src="/assets/images/user.png" />
                              <div className="after"><i className="fas fa-edit"></i></div>
                              <input type="file" name="userImage" style={{ display: "none" }}
                              />
                            </div>
                          </div> */}

                          <div className="col-md-9">
                            <div className="admin-form">
                              <form onSubmit={formik.handleSubmit}>
                                <div className="form-row justify-content-between">
                                  <div className="form-group col-lg-5 col-md-6">
                                    <label>{siteConstants.LABEL_FIRST_NAME}</label>
                                    <input type="text" className="form-control" name="firstname"
                                      value={formik.values.firstname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <label className="text-danger">
                                      {formik.errors.firstname && formik.touched.firstname ?
                                        formik.errors.firstname : ''
                                      }
                                    </label>
                                  </div>

                                  <div className="form-group col-lg-5 col-md-6">
                                    <label>{siteConstants.LABEL_LAST_NAME}</label>
                                    <input type="text" className="form-control" name="lastname"
                                      value={formik.values.lastname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                  </div>
                                </div>

                                <div className="form-row justify-content-between">


                                  <div className="form-group col-lg-5 col-md-12">
                                    <div className="row">

                                      <div className="col-lg-7">
                                        <label>{siteConstants.LABEL_CONTACT}</label>
                                        <input type="text" className="form-control"
                                          value={formik.values.contact} name="contact" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        <label className="text-danger">
                                          {formik.errors.contact && formik.touched.contact ?
                                            formik.errors.contact : ''
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-row justify-content-between">
                                  <div className="form-group col-lg-5 col-md-6">
                                    <label>{siteConstants.LABEL_EMAIL}</label>
                                    <input type="text" className="form-control" name="email"
                                      value={formik.values.email} onChange={formik.handleChange}
                                      onBlur={formik.handleBlur} />
                                    <label className="text-danger" style={{ 'display': 'block' }}>
                                      {formik.errors.email && formik.touched.email ?
                                        formik.errors.email : ''
                                      }
                                    </label>

                                  </div>
                                </div>

                                <button type="submit" className="btn btn-primary">Submit</button>
                              </form>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* </Card.Text> */}

                </Card.Body>
              </Card>
            </div>
            {/* Banner Scetion */}
            <div className='col-md-12 mt-3'>
              <BannerRandomFooter />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
