import React from 'react';
const Pagination = ({ totalPage, currentPage, funPagination }) => {
   
    const pageNumbers = [];
    if (totalPage !== null && totalPage !== undefined && totalPage > 0) {

        if (totalPage === 1) { pageNumbers.push(1); }
        else {
            for (let i = 1; i <= Math.ceil(totalPage); i++) {

                if (i === (currentPage)) {
                    var j = i - 1;
                    var k = i + 1;
                    if (i === 1) {
                        pageNumbers.push(i);
                        pageNumbers.push(k);
                    }
                    else if (i === Math.ceil(totalPage)) {
                        pageNumbers.push(j);
                        pageNumbers.push(i);
                    }
                    else {
                        pageNumbers.push(j);
                        pageNumbers.push(i);
                        pageNumbers.push(k);
                    }
                }


            }
        }

        var renderPageNumbers = pageNumbers.map(number => {
            
            let tempClass = currentPage === number ? 'paginate_button page-item active' : 'paginate_button page-item';
            return (
                <li className={tempClass} key={number}><a key={number} href="#/" aria-controls="dataTable" data-dt-idx="1" tabIndex="0" className="page-link" onClick={() => funPagination(number)}>{number}</a></li>
            );
        });

        //-- first page
        let firstPageClass = currentPage === 1 ? 'paginate_button page-item next disabled' : 'paginate_button page-item next';
        //-- last page
        let lastPageClass = currentPage === totalPage ? 'paginate_button page-item next disabled' : 'paginate_button page-item next';
        return (

            <div className="row">
                <div className="col-sm-12 col-md-5">
                 
                </div>
                <div className="col-sm-12 col-md-7 mt-4 mb-4">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                        <ul className="pagination" style={{'justifyContent': 'flex-end'}}>
                          
                            <li className={firstPageClass} id="dataTable_previous">
                                <a key={0} href="#/" aria-controls="dataTable" data-dt-idx="0" tabIndex="0" className="page-link" onClick={() => funPagination(1)}>First</a>
                            </li>
                            {renderPageNumbers !== undefined && renderPageNumbers}
                            <li className={lastPageClass} id="dataTable_next">
                                <a key={totalPage} href="#/" aria-controls="dataTable" data-dt-idx="1" tabIndex="0" className="page-link" onClick={() => funPagination(totalPage)}>Last</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    } else { return ''; }

}

export default Pagination;