import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackAction } from '../../actions';
import { apiConstants } from '../../constants';
import draftToHtml from 'draftjs-to-html';

const Faq = () => {
    const dispatch = useDispatch();
    const [siteConstants, setSiteConstants] = useState([])
    const [faq, setFaq] = useState([]);
    const { language, getFaqList } = useSelector(
        state => ({
            language: state.language,
            getFaqList: state.getFaqList
        })
    );

    useEffect(() => {
        let obj = {
            page: 1,
            perPage: 10,
            orderByAscDesc: -1
        };
        dispatch(feedbackAction.getFaqList(apiConstants.GET_FAQ, obj));
    }, [])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

        if (getFaqList !== undefined && getFaqList !== null) {
            if (Object.keys(getFaqList).length > 0) {
                if (getFaqList.getFaqList !== undefined) {
                    if (getFaqList.getFaqList.data !== undefined) {

                        const { data } = getFaqList.getFaqList.data.data;
                        if (data.length > 0) {
                            setFaq(data);
                        }
                    }
                }
            }
        }
    }, [language, getFaqList]);

    return (
        <>
            <section className="banner-inner" style={{ "backgroundImage": "url(assets/images/banner-faq.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>{siteConstants.LABEL_FAQ}</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-box text-center">
                                <p className="sub-heading">{siteConstants.LABEL_QUERIES}</p>
                                <h2>{siteConstants.LABEL_FREQUENT_ASKED_QUESTION}</h2>
                            </div>
                            <div className="inner-faq-sec">

                                <div className="accordion" id="accordionExample">
                                    {faq.map((data, index) => {

                                        let raw1 = draftToHtml(data.answer);
                                        // eslint-disable-next-line jsx-a11y/alt-text

                                        let element = document.getElementById("answer-replace" + index);
                                        if (element !== null) {
                                            element.innerHTML = raw1
                                        }
                                        return (
                                            <div className="card" key={index}>
                                                <div className="card-header" id={'heading' + index}>
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={'#collapse' + index} aria-expanded="true"
                                                            aria-controls={'collapse' + index}>{data.question}</button>
                                                    </h2>
                                                </div>

                                                <div id={'collapse' + index} className="collapse" aria-labelledby={'heading' + index} data-parent="#accordionExample">
                                                    {/* <div className="card-body">{ans !== undefined ? ans : ''}</div> */}
                                                    <div className="card-body faq-img-layout" id={"answer-replace" + index}></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq;