import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, supportActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Card from 'react-bootstrap/Card'
import Select from 'react-select';

const Support = () => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fileData, setFileData] = useState('');
    const [selectCategory, setCategory] = useState({})
    const [selectPriority, setPriority] = useState({})
    const { alert, language } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language
        })
    );
    const categoryList = [
        { 'value': 'general', 'label': 'general', 'subject': '' },
        { 'value': 'position', 'label': 'position', 'subject': 'Issue is related to Position' },
        { 'value': 'transaction', 'label': 'transaction', 'subject': 'Issue is related to Transaction' }
    ];

    const priorityList = [
        { 'value': 'low', 'label': 'low' },
        { 'value': 'medium', 'label': 'medium' },
        { 'value': 'high', 'label': 'high' }
    ]

    const formik = useFormik({
        initialValues: {
            subject: "",
            description: "",
            category: "",
            priority: "",
            attachment: ""

        },
        validationSchema: Yup.object().shape({
            subject: Yup.string()
                .required("Please Enter your subject!"),
            description: Yup.string()
                .required("Please Enter your description!"),
            category: Yup.string()
                .required("Please Enter your category!")
        }),

        onSubmit: values => {
            let obj = {
                subject: values.subject,
                description: values.description,
                category: values.category,
                priority: values.priority,
                attachment: fileData
            }
            setIsLoading(true)
            dispatch(supportActions.addSupportTicket(apiConstants.ADD_SUPPORT_TICKET, obj));
            setTimeout(() => {
                formik.values.subject = "";
                formik.values.description = "";
                formik.values.category = "";
                formik.values.priority = "";
                formik.values.attachment = "";
                if(document.getElementById("fileData")){
                    document.getElementById("fileData").value = ''
                }
                setFileData('')
                setCategory({});
                setPriority({})
                setIsLoading(false)
            }, 500);
        }
    });

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    useEffect(() => {
        dispatch(alertActions.clear());
        return () => {
            setAlertRes('')
        }
    }, []);

    useEffect(() => {

        if (Object.keys(alert).length > 0) {

            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                setIsLoading(false)
                if (alert.message.errors !== undefined) {

                    if (typeof alert.message.errors == "string") {
                        tempErr.push({ 'type': alert.type, message: alert.message.errors })
                    }
                    else {
                        let keys = Object.keys(alert.message.errors)
                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                            })
                        }
                        else {
                            tempErr = [{ 'type': alert.type, message: alert.message }]
                        }
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);
        }
    }, [alert]);

    const selectHandler = (e, name) => {
        if (name === "category") {
            formik.values.category = e.value
            formik.values.subject = e.subject
            setCategory(e)
        }
        else {
            formik.values.priority = e.value
            setPriority(e)
        }
    }

    const handleFileChange = (e) => {

        let file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            // use a regex to remove data url part
            const base64String = reader.result
            // let attachment = [
            //     {
            //         content: base64String.split(',')[1],
            //         filename: file.name,
            //         type:file.type,
            //         disposition: "attachment" 
            //     }
            // ]  
            setFileData(base64String);
        };
        reader.readAsDataURL(file);

    }


    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}
                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <Card.Title bg="light" className='text-primary'>{siteConstants.LABEL_GENERATE_NEW_TICKET}</Card.Title>
                                            <a href="/track-ticket" className='text-secondary font-weight-bold shadow-sm p-2 bg-white rounded'>Track your existing tickets here . . .</a>
                                        </div>
                                        <section className="User-details-sec">
                                            <div className="top-use-details">
                                                <div className="container">
                                                    <div className="row d-flex justify-content-center">

                                                        <div className="col-md-9">
                                                            <div className="admin-form">
                                                                <form onSubmit={formik.handleSubmit}>

                                                                    <div className="form-row justify-content-between">
                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_CATEGORY}</label>
                                                                            <Select
                                                                                name="category"
                                                                                placeholder="category"
                                                                                onChange={(e) => selectHandler(e, 'category')}
                                                                                options={categoryList ? categoryList : []}
                                                                                onBlur={formik.handleBlur}
                                                                                classNamePrefix="select-layout"
                                                                                value={selectCategory ? selectCategory : ''}
                                                                            />
                                                                            <label className="text-danger">
                                                                                {formik.errors.category && formik.touched.category ?
                                                                                    formik.errors.category : ''
                                                                                }
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_SUBJECT}</label>
                                                                            <input type="text" className="form-control" name="subject"
                                                                                value={formik.values.subject} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                            <label className="text-danger">
                                                                                {formik.errors.subject && formik.touched.subject ?
                                                                                    formik.errors.subject : ''
                                                                                }
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_PRIORITY}</label>
                                                                            <Select
                                                                                name="priority"
                                                                                placeholder="priority"
                                                                                onChange={(e) => selectHandler(e, 'priority')}
                                                                                options={priorityList ? priorityList : []}
                                                                                onBlur={formik.handleBlur}
                                                                                classNamePrefix="select-layout"
                                                                                value={selectPriority && selectPriority !== '' ? selectPriority : ''}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_DESCRIPTION}</label>
                                                                            <textarea type="text" className="form-control" name="description"
                                                                                value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                            <label className="text-danger">
                                                                                {formik.errors.description && formik.touched.description ?
                                                                                    formik.errors.description : ''
                                                                                }
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_FILE}</label>
                                                                            <input accept="image" type="file" className="btn form-control mt-2 mb-2" id="fileData"
                                                                                onChange={(e) => handleFileChange(e)} name="fileData" />

                                                                        </div>
                                                                    </div>
                                                                    {!isLoading ?
                                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                                        :
                                                                        <button type="button" className="btn btn-new blue-layout" disabled={isLoading}>
                                                                            <i className="fas fa-spinner fa-spin"></i></button>
                                                                    }
                                                                </form>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                    </Card.Body>
                                </Card>
                            </div>
                            <p className='text-secondary m-2'>Please feel free to reach out to us if need further assistance <b>{process.env.REACT_APP_FRONTEND_SUPPORT_EMAIL}</b></p>
                            {/* Banner Scetion */}
                            <div className='col-md-12 mt-3'>
                                <BannerRandomFooter />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;
