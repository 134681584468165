import { apiConstants, walletConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "./";

export const walletAction = {
  addWallet,
  sendMoney,
  getTransaction,
  getWallet,
  getBalance,
  getWalletUser,
  withdrawMoney,
  recoverWallet,
  getWithdrawTransaction,
  updateWalletKey
};

//-- withdraw transaction History function
function getWithdrawTransaction(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.GET_WITHDRAW_TRANSACTION_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.GET_WITHDRAW_TRANSACTION_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.GET_WITHDRAW_TRANSACTION_FAILURE, error));
        if (error !== undefined) {
          if (error.response !== undefined) {
            dispatch(alertActions.error(error.response.data.error));
          }
          else {
            dispatch(alertActions.error('Network Error...'));
          }
        }
      }
    );
  };
}

//-- recover wallet details function
function recoverWallet(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.RECOVER_WALLET_REQUEST));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.RECOVER_WALLET_SUCCESS, user));
        dispatch(alertActions.success("Wallet Password Recover Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.RECOVER_WALLET_FAILURE, error));
        if (error !== undefined && error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    );
  };
}


//-- Add wallet details function
function addWallet(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.ADD_WALLET_REQUEST));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.ADD_WALLET_SUCCESS, user));
        dispatch(alertActions.success("Wallet Added Successfully..."));
        // setTimeout(() => {
        //   dispatch(getWallet(apiConstants.GET_WALLET));
        // }, 2000);
      },
      error => {
        dispatch(commonAction.failure(walletConstants.ADD_WALLET_FAILURE, error));
        if (error !== undefined && error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    );
  };
}

//-- Send Money function
function sendMoney(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.SEND_MONEY_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.SEND_MONEY_SUCCESS));
        dispatch(alertActions.success("Money Sent Successfully..."));
        setTimeout(() => {
          dispatch(getWallet(apiConstants.GET_WALLET));
        }, 2000);
      },
      error => {
        dispatch(commonAction.failure(walletConstants.SEND_MONEY_FAILURE, error));
        dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

//-- transaction History function
function getTransaction(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.GET_TRANSACTION_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.GET_TRANSACTION_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.GET_TRANSACTION_FAILURE, error));
        if (error !== undefined) {
          if (error.response !== undefined) {
            dispatch(alertActions.error(error.response.data.error));
          }
          else {
            dispatch(alertActions.error('Network Error...'));
          }
        }
      }
    );
  };
}

//-- Get Wallet function
function getWallet(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.GET_WALLET_REQUEST, obj));

    commonService.getDataWithToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.GET_WALLET_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.GET_WALLET_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error...'));
        }
      }
    );
  };
}

//-- Get Balance function
function getBalance(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.GET_WALLET_BALANCE_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.GET_WALLET_BALANCE_SUCCESS, user));
        dispatch(alertActions.success("Balance updated successfully..."));
        dispatch(getWallet(apiConstants.GET_WALLET));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.GET_WALLET_BALANCE_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error...'));
        }
      }
    );
  };
}

function getWalletUser(apiName, obj) {

  return dispatch => {
    dispatch(commonAction.request(walletConstants.GET_WALLET_USER_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.GET_WALLET_USER_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.GET_WALLET_USER_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error...'));
        }
      }
    );
  };
}

//-- Withdraw Money function
function withdrawMoney(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.WITHDRAW_MONEY_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.WITHDRAW_MONEY_SUCCESS));
        dispatch(alertActions.success("Withdraw request sended successfully..."));
        setTimeout(() => {
          dispatch(getWallet(apiConstants.GET_WALLET));
        }, 2000);
      },
      error => {
        dispatch(commonAction.failure(walletConstants.WITHDRAW_MONEY_FAILURE, error));
        if (error && error.response && error.response.data) {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    );
  };
}

//-- upda function
function updateWalletKey(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(walletConstants.UPDATE_WALLET_KEY_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(walletConstants.UPDATE_WALLET_KEY_SUCCESS));
        dispatch(alertActions.success("wallet key updated successfully..."));
      },
      error => {
        dispatch(commonAction.failure(walletConstants.UPDATE_WALLET_KEY_FAILURE, error));
        dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}


