import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { alertActions, advertismentAction } from "../../actions";
import { apiConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Common/Pagination/Pagination";

import Header from "../Common/Dashboard/Header";
import Footer from "../Common/Dashboard/Footer";
import Sidebar from "../Common/Dashboard/Sidebar";
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';

var momentTimeZone = require("moment-timezone");
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const CreditList = () => {
  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState("");

  const [creditList, setCreditList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalpages] = useState(1);

  const { alert, getCreditList } = useSelector((state) => ({
    alert: state.alert,

    getCreditList: state.getCreditList,
  }));

  useEffect(() => {

    if (getCreditList) {

      if (Object.keys(getCreditList).length > 0) {
        if (getCreditList.getCreditList) {
          if (getCreditList.getCreditList.data.data) {
            const { data } = getCreditList.getCreditList.data;

            if (data.data.length > 0) {
              setCreditList(data.data);
              setTotalpages(data.totalPages);
              setCurrentPage(data.currentPage)
            }
          }
        }
      }
    }
  }, [getCreditList]);

  useEffect(() => {
    dispatch(alertActions.clear());
    getCredit(1);

    return () => {
      setAlertRes("");
    };
  }, []);

  useEffect(() => {
    if (Object.keys(alert).length > 0) {
      let tempErr = [];
      if (alert.type === "alert-success") {
        tempErr = [{ type: alert.type, message: alert.message }];
      } else if (alert.type === "alert-danger") {
        if (alert.message.errors !== undefined) {
          let keys = Object.keys(alert.message.errors);
          if (keys.length !== 0) {
            keys.map((data) => {
              return tempErr.push({
                type: alert.type,
                message: alert.message.errors[data],
              });
            });
          }
        } else {
          tempErr = [{ type: alert.type, message: alert.message }];
        }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes("");
      }, 8000);
    }
  }, [alert]);

  const getCredit = (pageNumber) => {
    let obj = {
      page: pageNumber,
      perPage: 10,
      orderByAscDesc: -1,
      limitQuery: false,
    };
    dispatch(
      advertismentAction.getCreditList(apiConstants.GET_CREDIT, obj)
    );
  };

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    getCredit(pageNumber);
  };


  let totalPage =
    typeof creditList !== "undefined" &&
      Object.keys(creditList).length > 0
      ? totalPages
      : 0;

  return (
    <>
      <div className="main-wrap">
        <Header />
        <div className="dashboard-wraper">
          <Sidebar />
          <div className="content-wrap">
            {alertRes !== undefined && alertRes !== null && alertRes !== ""
              ? alertRes.length > 0
                ? alertRes.map((data, index) => {
                  return (
                    <Alert
                      key={index}
                      variant={
                        data.type === "alert-danger" ? "danger" : "success"
                      }
                      className="alertBox"
                    >
                      {data.message}
                    </Alert>
                  );
                })
                : ""
              : ""}

            <div className="dashboard-content p">
              <div className="dash-body-blk">
                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row progress-bar-sec">
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                          <div className="card dash-card tag-block p-3 box_shadow">
                            <div className="row">
                              <div className="col-lg-9">
                                {" "}
                                <h2 className="heading-top">
                                  Advertisment Transaction
                                </h2>{" "}
                              </div>
                            </div>

                            <div className="owned-sec scroll-layout transaction-layout">

                              <table className="m-2">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Created Date</th>
                                    <th>Banner Credit</th>
                                    <th>Login Credit</th>
                                    <th>Text Credit</th>
                                    <th>Remark</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {creditList && Array.isArray(creditList) ? (
                                    creditList.length > 0 ? (
                                      creditList.map((data, index) => {
                                        let d = new Date(data.createdAt);
                                        return (
                                          <tr key={index}>
                                            <td>
                                              {data === 1
                                                ? index + 1
                                                : index + 1}
                                            </td>
                                            <td>{momentTimeZone.tz.guess() ?
                                              momentTimeZone(d).tz(momentTimeZone.tz.guess()).format('DD MMMM YYYY')
                                              :
                                              momentTimeZone(d).tz(timeZone).format('DD MMMM YYYY')}</td>

                                            <td className="tdTextCenter notify-cursor">
                                              {data.bannerCredit}
                                            </td>
                                            <td className="tdTextCenter notify-cursor">
                                              {data.loginCredit}
                                            </td>
                                            <td className="tdTextCenter notify-cursor">
                                              {data.textCredit}
                                            </td>
                                            <td className={data.remark.debit ? 'text-danger' : ''}>{data.remark && data.remark.debit ? data.remark.debit : data.remark.credit}</td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td className="mt-2" colSpan="8">
                                          No Record Found{" "}
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </table>

                              {typeof creditList !== "undefined" ? (
                                <Pagination
                                  totalPage={totalPage}
                                  currentPage={currentPage}
                                  funPagination={makeHttpRequestWithPage}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Banner Scetion */}
              <div className='col-md-12 mt-3'>
                <BannerRandomFooter />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

    </>
  );
};

export default CreditList;
