import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import TermsAndCondition from '../../TermsAndCondition/TermsAndCondition';
import CookiesNotice from '../../TermsAndCondition/CookiesNotice';
import PrivacyPolicy from '../../TermsAndCondition/PrivacyPolicy';
import CookiesPolicy from '../../TermsAndCondition/CookiesPolicy';

export default function TermsAndConditionPopup({ onSelect, referalLink }) {

    const [siteConstants, setSiteConstants] = useState([]);
    const [page, setPage] = useState(1);
    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        onSelect(val)
    }

    const pageHandler = (name) => {
        if (page >= 1 && page <= 4) {
            if (name === "prev") {
                setPage(page - 1)
            }
            else {
                setPage(page + 1)
            }
        }
    }

    return (
        <Modal show={true} onHide={()=>handleSubmit('No')} size='lg' className='f-10' dialogClassName="modal-70w" animation={true}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_TERMS_AND_CONDITION} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                {page === 1 ?
                    <><h5 className='text-info'> {siteConstants.GEN_TERMS_AND_CONDITION} </h5>
                        <TermsAndCondition /></>
                    : page === 2 ?
                        <><h5 className='text-info'> {siteConstants.GEN_PRIVACY_POLICY} </h5>
                            <PrivacyPolicy /></>
                        : page === 3 ?
                            <><h5 className='text-info'> {siteConstants.GEN_COOKIES_NOTICE} </h5>
                                <CookiesNotice /></>
                            : page === 4 ?
                                <><h5 className='text-info'> {siteConstants.GEN_COOKIES_POLICY} </h5>
                                    <CookiesPolicy /></>
                                : ''}
                <div className='d-flex justify-content-end'>
                    {page === 1 ? '' : <button className='btn btn-outline-danger mr-2' onClick={() => pageHandler('prev')}>Previous</button>}
                    {page === 4 ? '' : <button className='btn btn-outline-success' onClick={() => pageHandler('next')}>Next</button>}
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
            </Modal.Footer> */}

        </Modal>
    )
}
