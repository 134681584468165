export const supportConstants = {
    GET_SUPPORT_TICKET_REQUEST: 'GET_SUPPORT_TICKET_REQUEST',
    GET_SUPPORT_TICKET_SUCCESS: 'GET_SUPPORT_TICKET_SUCCESS',
    GET_SUPPORT_TICKET_FAILURE: 'GET_SUPPORT_TICKET_FAILURE',

    EDIT_SUPPORT_TICKET_REQUEST: 'EDIT_SUPPORT_TICKET_REQUEST',
    EDIT_SUPPORT_TICKET_SUCCESS: 'EDIT_SUPPORT_TICKET_SUCCESS',
    EDIT_SUPPORT_TICKET_FAILURE: 'EDIT_SUPPORT_TICKET_FAILURE',

    DELETE_SUPPORT_TICKET_REQUEST: 'DELETE_SUPPORT_TICKET_REQUEST',
    DELETE_SUPPORT_TICKET_SUCCESS: 'DELETE_SUPPORT_TICKET_SUCCESS',
    DELETE_SUPPORT_TICKET_FAILURE: 'DELETE_SUPPORT_TICKET_FAILURE',

    COMMENT_SUPPORT_TICKET_REQUEST: 'COMMENT_SUPPORT_TICKET_REQUEST',
    COMMENT_SUPPORT_TICKET_SUCCESS: 'COMMENT_SUPPORT_TICKET_SUCCESS',
    COMMENT_SUPPORT_TICKET_FAILURE: 'COMMENT_SUPPORT_TICKET_FAILURE',

    ADD_SUPPORT_TICKET_REQUEST: "ADD_SUPPORT_TICKET_REQUEST",
    ADD_SUPPORT_TICKET_SUCCESS: "ADD_SUPPORT_TICKET_SUCCESS",
    ADD_SUPPORT_TICKET_FAILURE: "ADD_SUPPORT_TICKET_FAILURE",

    GET_SUPPORT_TICKET_BY_ID_REQUEST: "GET_SUPPORT_TICKET_BY_ID_REQUEST",
    GET_SUPPORT_TICKET_BY_ID_SUCCESS: "GET_SUPPORT_TICKET_BY_ID_SUCCESS",
    GET_SUPPORT_TICKET_BY_ID_FAILURE: "GET_SUPPORT_TICKET_BY_ID_FAILURE"
}