import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { feedbackAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function ContactUs() {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '', email: '', message: ''
  });
  const [errState, setErrState] = useState({});
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState([])

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const { language, alert } = useSelector(
    state => ({
      alert: state.alert,
      language: state.language
    })
  );

  const errInterval = (name) => {
    let target = name + 'Err'
    setTimeout(() => {
      setErrState({ ...errState, [target]: '' })
    }, 2000);
  }

  const handleErr = (name, msg) => {
    let target = name + 'Err';
    setErrState((state) => {
      return { ...state, [target]: msg }
    });
    errInterval(name);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.name === '') {
      handleErr('name', 'ERR_BLANK_USER_NAME')
    }
    if (formData.email === '') {
      handleErr('email', 'ERR_BLANK_EMAIL')
    }
    else {
      let obj = { "name": formData.name, "email": formData.email, "message": formData.message }
      dispatch(feedbackAction.addContactDetails(apiConstants.CONTACT_US, obj));
    }
  }

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language])

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr;
      if (alert.type === 'alert-success') {
        tempErr = { 'type': alert.type, 'message': alert.message }
      }
      else {
        let keys = Object.keys(alert.message.errors)
        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 3000);
    }
  }, [alert]);



  return (
    <>
      {alertRes !== '' ? <Alert variant={alertRes.type === 'alert-danger' ? 'danger' : 'success'}
        className="alertBox">{alertRes.message} </Alert> : ''}
      <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{siteConstants.LABEL_CONTACT_US}</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-page-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="heading-box">
                <h2>{siteConstants.LABEL_CONTACT_SUBHEADING}</h2>
              </div>
              <div className="contact-us-sec">
                <form>
                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-user"></i>
                    </div>
                    <input type="text" className="form-control" placeholder={siteConstants.LABEL_NAME} name="name" onChange={(e) => onChangeHandler(e)} />
                  </div>
                  <span className="error-text text-danger">{siteConstants[errState.nameErr]}</span>

                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-envelope"></i>
                    </div>
                    <input type="email" className="form-control" placeholder={siteConstants.LABEL_EMAIL} name="email" onChange={(e) => onChangeHandler(e)} />
                  </div>
                  <span className="error-text text-danger">{siteConstants[errState.emailErr]}</span>

                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-comment-alt"></i>
                    </div>
                    <textarea className="form-control" id="form6Example7" rows="4" placeholder={siteConstants.GEN_MESSAGE} name="message" onChange={(e) => onChangeHandler(e)}></textarea>
                  </div>

                  <button type="button" onClick={(e) => onSubmit(e)} className="btn btn-primary mt-3">{siteConstants.LABEL_SUBMIT}</button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="right-contact-sec">
                <h2>{siteConstants.LABEL_CONTACT_DETAILS}</h2>

                <ul>
                  <li>
                    <p><strong>{siteConstants.LABEL_ADDRESS}</strong></p>
                    <h6><img src="assets/images/location.png" alt='' className='m-2' style={{width: '20px',height: '20px'}} />Oldham,OL8 2XQ,England, UK</h6>
                  </li>
                  <li>
                    <p><strong>{siteConstants.LABEL_EMAIL}</strong></p>
                    <h6><img src="assets/images/multimedia.png" alt='' className='m-2' style={{width: '20px',height: '20px'}} />contactus@diamondj7.com</h6>
                  </li>

                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2371.642028232162!2d-2.0901626846071557!3d53.52844976875708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb79489813b79%3A0x2f0c23e776cff2ac!2sApril%20Cl%2C%20Oldham%20OL8%202XQ%2C%20UK!5e0!3m2!1sen!2sin!4v1642682127067!5m2!1sen!2sin" title='map' width="100%" height="350" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
      </section>
    </>
  );
}