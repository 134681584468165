import React from 'react';

const PrivacyPolicy = () => {

    return (
        <>

            <section>
                <div className="container">
                    <div className="top-about-box-sec d-flex flex-wrap align-items-center">
                        <div>
                                <div className="heading-box text-center f-10 scroll-layout" style={{'height':'70vh'}}>
                                  <p className='f-12'>  PRIVACY POLICY
                                    1. Introduction
                                    This is the Diamondj7.com Privacy Policy. The policy explains how we handle and use your personal information and your rights in relation to that information. Under data protection law, Diamonj7.com (operated by Global Generational Wealth Ltd) is the controller of that information and responsible for its use and protection. Diamondj7.com (DJ7, the company, we or us) is committed to protecting and respecting your privacy.
                                    This policy explains why and how we will use the personal information that we have obtained from you or others with whom we share it, and the rights you have in connection with the information we use. Please read the following carefully.
                                    This policy describes the way we handle and use the personal information that we obtain from all the different interactions you may have with us as a business, including when you visit our offices, social media pages or our websites, including our main website currently located at www.diamondj7.com (collectively, Sites) or when you contact us or take part in any of our competitions or promotions.
                                    We, Diamondj7.com, are the controller in relation to the processing activities described below. This means that we decide why and how your personal information is processed. Please see the section at the end of this policy for our contact and legal information.
                                    Our Subscribers/Campaign Organisers run crypto-currency raising campaigns from our site, and in doing so doing may collect personal information from you for subscription to our site as their ‘downlines’. Their use of that information during and after their campaign (e.g. to keep in contact with you) are uses of your information which those members determine, independently of us. As a result, those members will be separate controllers of your personal information.
                                    We refer to ‘subscribers, campaign organisers and donations’ throughout this document. For more information regarding these, please see our Terms and Conditions.
                                    We also refer to our ‘partners’ in these Terms. These are commercial partners with whom we work in a variety of ways to expand the features on our Site.  Our partners include ‘programme partners’ who work with us to provide technical services on our platforms;
                                    2. How and when we collect personal information about you
                                    This is personal information about you that you give to us when:
                                    <ul className='ul-layout'>
                                    • submitting personal information via our Site
                                    • following us, interacting with us and posting on our social media pages including our official pages on Facebook, Telegram, Twitter and other social media platforms
                                    • participating in surveys for research purposes
                                    • you visit our offices
                                    • we interact with you at events
                                    • you correspond with us by phone, email or in another way.
                                    </ul>
                                    This information is provided by you entirely voluntarily. This includes information provided on the Site at the time of registering as a Subscriber, using any of the communication tools we provide for our members/subscribers, making donations, and when you generally interact with us or use our Sites. For example you may give us your personal information by filling in forms, or when you exercise your legal rights.
                                    Information that we collect about you
                                    We may automatically collect the following information:
                                    <ul className='ul-layout'>
                                    • details of your visits to our Sites, including, but not limited to traffic data, location data, weblogs and other communication data, and the resources you access;
                                    • technical information, including anonymous data collected by the hosting server for statistical purposes, the Internet protocol (IP) address used to connect your computer or device to the Internet, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform. Please see our ‘Cookies Policy’ for further information; and
                                    • details regarding when and how you consented to receive marketing communications from us (including the time and date you provided your consent).
                                    We may also view any personal information which you allow to be shared, such as the information you upload to the Sites (such as your Diamondj7.com profile), and information you share on third party social networks. When you visit our social media pages we collect:
                                    • the information you post on those pages;
                                    • information regarding your interactions with the content we post; and
                                    • statistical information regarding all our followers’ activities (but from which we cannot identify you as we only have access to this information in aggregated form).
                                    </ul>
                                    Personal information we may receive from other sources
                                    We may obtain certain personal information about you from other sources (including those outside of our business) which may include our suppliers and our clients. The third parties that may send us personal information about you are as follows:
                                    Other members/subscribers
                                    Members/Subscribers to the Site may share personal information about you with us, for example, as a ‘downline’ or if you misuse the Sites or breach applicable Terms and Conditions.
                                    Our partners
                                    We may receive your personal information from our partners if you have indicated to them that you would like us to contact you about our services. We will only use this personal information for the purposes of email marketing if our partners have obtained your prior consent for us to do so.
                                    Governmental and other public authorities
                                    We may receive your personal information from the police, HMRC and other public authorities, for example, in connection with their investigations. We may also receive your personal information indirectly from such authorities e.g. from a third party sharing information originating from a court, or otherwise relating to legal or regulatory proceedings.
                                    Social media networks (publicly available source)
                                    We may view your social media profile registration data where you choose to register with our Sites using your existing social media profile and log-in details.

                                    3. Categories of personal information we use about you
                                    We process different types of personal information about you. To make it easier to understand the information that we use about you, we have categorised this information in the table below and provided a short explanation of the type of information each category covers. We process the following categories of personal information about you:
                                    Behavioural (Your activities, actions and behaviours), Contact and Correspondence (Information which can be used to address, send or otherwise communicate a message to you), Billing and Donations (Information used to send/receive crypto-funds to/from you), Fraud detection (Information relating to the occurrence, investigation or prevention of fraud), Identity (Information that verifies your identity including formal identification documents or unique identification numbers linked to you), Legal (Information relating to legal claims made by you or against you or the claims process) and Marketing Preferences (Your preferences in respect of any marketing communications from us from time to time in relation to products or services which we believe may be of interest to you).
                                    Sensitive data
                                    We do not knowingly collect personal information about children or personal information that is ‘sensitive’ personal data from a legal perspective (also known as ‘special category data’) or that relates to criminal convictions or offences.

                                    4. Use of your personal information
                                    We use your personal information in the following ways:
                                    4.1 Where you have provided CONSENT
                                    We may use and process your personal information for the following purposes where you have consented for us to do so (whether by giving that consent to us directly or indirectly, for example to one of our partners):
                                    • to contact you via email with marketing information about our projects, services, opportunities and business; and
                                    • to use any personal data we access through the use of cookies (see our Cookies Policy for further details).
                                    You may withdraw your consent for us to use your information in any of these ways at any time.
                                    4.2 Where necessary for us to carry out PRE-CONTRACT STEPS you have requested or for the performance of our CONTRACT
                                    We will use your personal information where this is necessary for us to perform our contract with you or to carry out any pre-contract steps you’ve asked us to so that you can enter into that contract, for the following purposes:
                                    <ul className='ul-layout'>
                                    • to register and set you up as a member on our Sites;
                                    • to process your donations, whether received or made;
                                    • to run our competitions and promotions that you enter from time to time and to distribute prizes.
                                    </ul>
                                    4.3 Where necessary to comply with our LEGAL OBLIGATIONS
                                    We will use your personal information to comply with our legal obligations:
                                    <ul className='ul-layout'>
                                    • to keep a record relating to the exercise of any of your rights relating to our processing of your personal information;
                                    • to perform anti-money laundering and related checks where the law requires these;
                                    • to render anonymous or destroy your personal information in accordance with our retention policies and data protection law; and,
                                    • to handle and resolve any complaints we receive relating to the services we provide.
                                    </ul>
                                    4.4 Where necessary for us to pursue a LEGITIMATE INTEREST
                                    We may use and process your personal information where it is necessary for us to pursue our legitimate interests as a business for the following purposes:
                                    <ul className='ul-layout'>
                                    • to promote our business, brand and activities and measure the reach and effectiveness of our campaigns
                                    • for analysis and insight conducted to inform our marketing strategies, and to enhance your visitor experience;
                                    • to identify and record when you have received, opened or engaged with our website or electronic communications (please see our ‘Cookies Policy’ for more information);
                                    • to respond to correspondence you send to us and fulfil the requests you make to us.
                                    </ul>
                                    Processing necessary for us to respond to changing market conditions and the needs of our guests and visitors
                                    <ul className='ul-layout'>
                                    • to analyse, evaluate and improve our Site and other services so that your visit and use of our Sites, support and other services and social media pages, are more useful and enjoyable (we will generally use data amalgamated from many people so that it does not identify you personally);
                                    • to undertake market analysis and research (including contacting you with surveys) so that we can better understand you as a user of our services;
                                    </ul>
                                    Processing necessary for us to operate the administrative and technical aspects of our business efficiently and effectively
                                    <ul className='ul-layout'>
                                    • to administer our Sites and our social media pages and for internal operations, including troubleshooting, testing and statistical purposes;
                                    • for the prevention or detection of fraud and other criminal activities or to assist in the apprehension of offenders, including by sharing your personal information with the relevant authorities;
                                    • to verify the accuracy of data that we hold about you and create a better understanding of you as an account holder or visitor;
                                    • for network and information security in order for us to take steps to protect your information against loss or damage, theft or unauthorised access, including to archive, destroy or render your personal information anonymous;
                                    • to comply with a request from you in connection with the exercise of your rights (for example where you have asked us not to contact you for marketing purposes, we will keep a record of this on our suppression lists in order to be able to comply with your request);
                                    • for the purposes of corporate restructuring, reorganisation or sale of our business or assets;
                                    • for efficiency, accuracy or other improvements of our databases and systems, for example, by combining systems or consolidating records we hold about you;
                                    • to enforce or protect our contractual or other legal rights or to bring or defend legal proceedings;
                                    • to inform you of updates to our terms and conditions and policies; and
                                    • for other general administrative purposes including managing your queries, complaints, or claims, and to send service messages to you.
                                    </ul>
                                    Marketing communications: We may use your personal information to contact you by email to send you newsletters or to notify you with details of projects, services and competitions. We try to adapt any marketing material that we send to you, for example by notifying you of projects that apply to your interests and in your location. If you do not wish to receive email communications from us, please inform us by using the unsubscribe link inside the email messages we send, by using our ‘Contact Us’ form or, if you have a registered account on our Site, by changing your Profile settings from within your account.
                                    If you opt-out of receiving marketing communications from us, we keep your email address on our suppression list for a defined period to ensure that we comply with your wishes. Please see further 'The periods for which we retain your personal information.'

                                    5. Disclosure and sharing of your personal information by us
                                    We only disclose and share your personal information outside our business in limited circumstances. If we do, we will put in place a contract that requires recipients to protect your personal information, unless we are legally required to share that information. Any suppliers that work for us will be obliged to follow our instructions. We do not sell your personal information to third parties.
                                    As the controller of your personal information, we decide why and how it is processed. Our responsibility for that processing extends to processing by our service providers if they process your personal information based on our instructions.  We also work with other more independent organisations in connection with some of the processing activities described in this statement, such as our partners, social media platforms and certain suppliers and service providers we work with.
                                    Where that personal information is collected and sent to other organisations for a processing purpose that is in our mutual interests, or where we make decisions together in relation to that particular processing, we will be “joint controllers” with the organisations involved.  Where this applies, we and the other organisation will be jointly responsible to you under data protection laws for this processing.  If, however, we pass your personal information to an organisation that independently decides why and how to use your personal information then it will be separately responsible to you for that processing and use of your personal information, in the ways described in its privacy policy (and not ours).
                                    We may disclose your information to our partners, who include the following:
                                    <ul className='ul-layout'>
                                    • Social media platforms: to communicate with you and to promote products and services. See further our list of suppliers below and also ‘Our use of social media’;
                                    • Third party service providers, agents and subcontractors (Suppliers): for the purposes of providing services to us or directly to you on our behalf, including the operation and maintenance of our Sites and social media pages. Our Suppliers can be categorised as follows: Professional Services (Accountancy, Security & Legal), Media (Advertising & PR), Finance (Banking & Payment Processing), IT (Business Performance), IT (Cloud Services), Customer Services (Support), IT (Customer Relationship Management), Logistics (Delivery Service), Events (Booking), IT (Data Management), Anti-fraud and IT (Verification), Government (Tax Administration), Media (Market Research), IT (Survey), IT (Web-hosting, Data Analytics, Marketing, Software maintenance and Development)
                                    </ul>
                                    When we use Suppliers, we only disclose to them any personal information that is necessary for them to provide their services and only where we have a contract in place that requires them to keep your information safe and secure.
                                    We may disclose the personal information to other third parties as follows:
                                    <ul className='ul-layout'>
                                    • any third party who is restructuring, selling or acquiring some or all of our business or assets or otherwise in the event of a merger, re-organisation or similar event; and
                                    • if we are under a duty to disclose or share your information in order to comply with any legal or regulatory obligation or request, including by the police, courts, tribunals, regulators or similar authorities or if we voluntarily decide to cooperate with such authorities.
                                    </ul>
                                    6. Our use of social media
                                    We use social media platforms in a variety of different ways, including by publishing pages through which you can interact; running competitions or advertising to you using information you have provided on those platforms, or which has been provided by us or collected from this Site.  Our legal relationship with each platform varies with the particular way we use that platform. We process your personal information using social media platforms in a variety of ways, as follows:
                                    Pages:  We use your personal information when you post content or otherwise interact with us on our official pages Facebook, Telegram and other social media platforms. We also use the Page Insights service for Facebook, Telegram and other platforms to view statistical information and reports regarding your interactions with the pages we administer on those platforms and their content.  Where those interactions are recorded and form part of the information we access through the Page Insights services, we and the relevant platform are joint controllers of the processing necessary to provide that service to us.
                                    Single sign-on: Our Sites may incorporates a feature provided by social media and other digital service providers that allows you to log into your account on this Site using the same login you have already set up with those providers. This feature is known as a ‘single sign-on service’.  Any use we make of the personal information we receive from the platform using this feature is processing for which we are the sole controller.
                                    Targeted advertising: We use social media platforms (as well as search engines and third party websites and other platforms) to deliver targeted advertising to you via those platforms, unless you object.  You may receive advertising because you have previously interacted with us (such as by visiting one of our Sites) or because of your profile on a social media platform on which you have an account. We use social media platforms to send targeted advertising using two main methods:
                                    <ul className='ul-layout'>
                                    • ‘Custom audience’ targeting:  You may receive advertising based on information about you that we have provided to the platform or allowed it to collect using cookies/pixels or code embedded in our Site (or a combination of the two).  For example, we may target you if you have signed up to our mailing list and where cookies have detected that you have visited our Site in the last month.  Please see the paragraph below regarding our use of cookies to send targeted advertising using social media.
                                    • ‘Lookalike audience’ targeting: You may also receive advertising because, at our request,  the social media platform has identified you as falling within a group or ‘audience’ whose attributes we have selected, or a group that has similar attributes to you (or a combination of the two).
                                    </ul>
                                    Cookies:  We use cookies and similar technologies in this Site to collect and send information to Social Media platforms about actions you take on this Site. In particular, Facebook and Telegram use this information to provide services to us and also for further processing for their own business purposes.  We and Facebook or Telegram are joint controllers of the processing involved in collecting and sending your personal information to them using cookies and similar technologies as each of us has a business interest in these social media platforms receiving this information.   You can find out more about these technologies by visiting our Cookies Policy.  The services we receive from Facebook or Telegram that use this information are delivered to us through their Business Tools, which may include Login (e.g. single sign-on services and Website Custom Audiences (which includes ‘lookalike audience’ targeting).  The data from these tools allows us to target advertising to you within the social media platforms by creating audiences based on your actions on our website and applications. They also allow these social media platforms to improve and optimise the targeting and delivery of our advertising campaigns for us.  Please see the paragraph above for further information.
                                    Our relationship with social media platforms:  As we are joint controllers with these platforms for certain processing, we and each platform have taken some or all of the following steps, depending on the platform:
                                    <ul className='ul-layout'>
                                    • entered into agreements in which we have agreed each of our data protection responsibilities for the processing of your personal information described above;
                                    • agreed that we are responsible for providing to you the information in this privacy statement about our relationship with each platform; and
                                    • agreed that each platform is responsible for responding to you when you exercise your rights under data protection law in relation to that platform’s processing of your personal information as a joint controller.
                                    </ul>
                                    Social media platforms also process, as our processors, personal information that we submit for the purposes of matching, online targeting, measurement, reporting, and analytics purposes.  These services include the processing these platforms carry out when they display our advertisements to you in your social media feed at our request after matching contact details for you that we have uploaded to them.  These advertisements may include forms through which we collect contact information you give to us.
                                    7. Transfers of your personal information outside of Europe
                                    We take specific safety measures when we transfer your personal information anywhere in the world. All the personal information collected about you by us or on our behalf may be transferred to countries outside the United Kingdom (UK) and European Economic Area (EEA). By way of example, this may happen where any of our suppliers are located in a country outside of the UK and EEA or if any of our servers or those of our third party service providers are from time to time located in a country outside of the EEA. These countries may not have similar data protection laws to the UK and so they may not protect the use of your personal information to the same extent.
                                    If we transfer your information outside of the UK and EEA in this way, we will take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in this policy. These steps include imposing contractual obligations on the recipients of your personal information or ensuring that the recipients are subscribed to ‘international frameworks’ that aim to ensure adequate protection. If you use our services whilst or from outside the UK and EEA, your information may be transferred outside the UK and EEA in order to provide you with those services.
                                    8. Security and links to other websites
                                    We take the security of your personal information seriously and use a variety of measures based on good industry practice to keep it secure. Nonetheless, transmissions over the internet and to our Sites may not be completely secure, so please exercise caution. When accessing links to other websites, their privacy policies, not ours, will apply to your personal information.
                                    We employ security measures to protect the personal information you provide to us to prevent unauthorised access, unlawful processing, accidental loss, destruction and damage. When we have provided (or you have chosen) a password or pin allowing you access to certain parts of the Sites, you are responsible for safeguarding it and keeping it confidential and you promise not to allow it to be used by third parties, except when you appoint a nominee user as permitted by the sites. The transmission of information via the internet is not completely secure. Although we will do everything possible to protect your personal information, we cannot guarantee the security of any personal information during its transmission to us online. You accept the inherent security implications of using the internet and will not hold us responsible for any breach of security unless we are at fault.
                                    Our Sites and social media pages may contain links to other websites run by other organisations which we do not control. This policy does not apply to those other websites‚ so we encourage you to read their privacy policies. We are not responsible for the privacy policies and practices of other websites (even if you access them using links we have provided), and we provide links to those websites solely for your information and convenience. We specifically disclaim responsibility for their content, privacy practices and terms of use, and we make no endorsements, representations or promises about their accuracy, content or thoroughness. Your disclosure of personal information to third party websites is at your own risk.
                                    9. The periods for which we retain your personal information
                                    We will not hold your personal information in an identifiable format for any longer than is necessary for the purposes for which we collected it. For certain purposes we retain your personal information for a very short period whilst for others we retain it for a period of 7 years after the information is no longer required for business reasons so that we can deal with any legal proceedings that could arise.
                                    We retain your personal information for the following periods:
                                    <ul className='ul-layout'>
                                    • Registration information - 7 years from the date your account is closed for any reason.
                                    • Identity documents - If we require these for fraud verification purposes, 7 years. If we require these to verify your identity for a request by you in connection with your rights over your personal information (see below), 2 years.
                                    • Web traffic and device information – 2 years from the date of collection.
                                    • Social media handles - Until you stop following our social media account or page.
                                    • Marketing preferences - For as long as you have not opted-out and if you opt-out, indefinitely after we place you on our suppression list to ensure we respect your wishes.
                                    • Complaints and queries - 2 years, except where these relate to legal claims, in which case 7 years.
                                    </ul>
                                    The only exceptions to the periods mentioned above are where:
                                    <ul className='ul-layout'>
                                    • you exercise your right to have the information erased (where it applies) and we do not need to hold it in connection with any of the reasons permitted or required under the law (see further 'Your rights in relation to your personal information');
                                    • you exercise your right to require us to retain your personal information for a period longer than our stated retention period (see further Your rights in relation to your personal information);
                                    • we bring or defend a legal claim or other proceedings during the period we retain your personal information, in which case we will retain your personal information until those proceedings have concluded and no further appeals are possible;
                                    • we archive the information, in which case we will delete it in accordance with our deletion cycle; or
                                    • in limited cases, existing or future law or a court or regulator requires us to keep your personal information for a longer or shorter period.
                                    </ul>
                                    10. Your rights in relation to your personal information
                                    You have a number of rights in relation to your personal information under data protection law. In relation to certain rights, we may ask you for information to verify your identity and, where applicable, to help us to search for your personal information. Except in rare cases, we will respond to you within 30 days after we have received this information or, where no such information is required, after we have received full details of your request.  You have the following rights, some of which may only apply in certain circumstances:
                                    10.1. To be informed about the processing of your personal information (this is what this policy sets out to do)
                                    10.2. To have your personal information corrected if it is inaccurate and to have incomplete personal information completed
                                    The accuracy of your information is important to us and we make it easy for you to review and correct the personal information that we hold about you in your Profile. If you change your name or address/email address, or you discover that any of the other information we hold is inaccurate or out of date, you can let us know by contacting us, but we ask you to first check that you cannot correct your details using the tools in your ‘Profile’.
                                    10.3. To object to processing of your personal information
                                    Where we rely on our legitimate interests as the legal basis for processing your personal information for particular purposes, you may object to us using your personal information for these purposes by emailing or writing to us at the address at the end of this policy. Except for the purposes for which we are sure we can continue to process your personal information, we will temporarily stop processing your personal information in line with your objection until we have investigated the matter. If we agree that your objection is justified in accordance with your rights under data protection laws, we will permanently stop using your data for those purposes. Otherwise we will provide you with our justification as to why we need to continue using your data.
                                    You may object to us using your personal information for direct marketing purposes and we will automatically comply with your request. If you would like to do so, please use our unsubscribe tool.
                                    10.4. To withdraw your consent to processing your personal information
                                    Where we rely on your consent as the legal basis for processing your personal information, you may withdraw your consent at any time by contacting us using the details at the end of this policy. If you would like to withdraw your consent to receiving any direct marketing to which you previously opted-in, you can also do so using our unsubscribe tool. You can also opt out of receiving our newsletter at any time by using the unsubscribe tool.
                                    If you withdraw your consent, our use of your personal information before you withdraw is still lawful.
                                    10.5. To restrict processing of your personal information
                                    You may ask us to restrict the processing your personal information where you believe it is unlawful for us to do so, or you have objected to its use and our investigation is pending or you require us to keep it in connection with legal proceedings.
                                    In these situations, we may only process your personal information whilst its processing is restricted if we have your consent or are legally permitted to do so, for example for storage purposes, to protect the rights of another individual or company or in connection with legal proceedings.
                                    10.6. To have your personal information erased
                                    In certain circumstances, you may ask for your personal information to be removed from our systems via the ‘Contact Us’ form, or by emailing or writing to us at the address at the end of this policy. Unless there is a reason that the law allows us to use your personal information for longer, we will make reasonable efforts to comply with your request.
                                    10.7. To request access to your personal information and information about how we process it
                                    You have the right to ask for a copy of the information that we hold about you by emailing or writing to us at the address at the end of this policy. We may not provide you with a copy of your personal information if this concerns other individuals, or we have another lawful reason to withhold that information.
                                    Remember, you can access a lot of your personal information through your account. You can also review and edit some of the personal information you have provided to Diamondj7.com via your Profile settings within the website.
                                    If you wish to make a Subject access request please first check you cannot get the data from the Log-in and Profile settings. If you wish to access data not listed there please be specific in your request and send us a message via email or letter, or the ‘Contact Us’ form.
                                    10.8. To electronically move, copy or transfer your personal information in a standard, machine-readable form
                                    Where we rely on your consent as the legal basis for processing your personal information or need to process it in connection with a contract in place directly with you, you may ask us to provide you with a copy of that information in a structured data file. We will provide this to you electronically in a structured, commonly used and machine readable form.
                                    You can ask us to send your personal information directly to another service provider, and we will do so if this is technically possible. We may not provide you with a copy of your personal information if this concerns other individuals, or we have another lawful reason to withhold that information.
                                    10.9. Rights relating to automated decision making, including profiling
                                    We do not envisage that any decisions that have a legal or significant effect on you will be taken about you using purely automated means, however we will update this policy and inform you if this position changes.
                                    You have the right to lodge a complaint with the Information Commissioner’s Office (ICO), the data protection regulator in the UK (or if you are located outside the UK, a similar regulator in the country in which you live) where your personal information has or is being used in a way that you believe does not comply with privacy laws.  The contact details for the ICO are available on the ICO website.  However, we encourage you to contact us before making any complaint and we will seek to resolve any issues or concerns you may have.

                                    11. Changes to our Privacy Policy
                                    We may review this policy from time to time and any changes will be notified to you by posting an updated version on our Sites and, where appropriate, by contacting you by email. Any changes will take effect 7 days after we post the modified terms on our Sites or after the date we notify you by email. We recommend you regularly check this page for changes and review this policy each time you visit our Sites.

                                    12. Contact and legal information
                                    You can contact us with your queries in relation to this policy or for any other reason using our ‘Contact Us’ web-form or by post or email.
                                    To contact us in relation to this policy, including in exercising any of your rights in relation to your personal information, please contact us or write to us by email at madumem@gmail.com
                                    Global Generational Wealth Limited (the owners of Diamondj7.com) is a company registered in England and Wales, company number 13554534 and with its registered office address at 4 April Close, Oldham, OL8 2XQ, England, UK.
                                    </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    );
}

export default PrivacyPolicy;