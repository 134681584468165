import React from 'react'

const Footer = () => {

    return (
        <>
            <footer>
                <div className="footer w-100" style={{'bottom':'0px','position':'fixed'}}>
                    <div className="sec-footer-box text-center">
                        <p>Copyright © . All Rights Reserved DiamondJ7</p>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer;