import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, globalPoolUserActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter'
import { Table } from 'react-bootstrap';
import ConfirmPopup from '../Common/Popup/ConfirmPopup';
var moment = require('moment')

const GlobalPoolUser = (props) => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [existingPage, setExistingPage] = useState(1);
    const [listing, setListing] = useState(1);
    const [totalPages, setTotalpages] = useState(1);
    const [query, setQuery] = useState('');
    const [availablePlan, setAvailablePlan] = useState('')
    const [isShow, setIsShow] = useState(false)
    const [tempActiveuser, setTempActiveuser] = useState('')

    const { alert, getPoolUser, getAvailablePlan } = useSelector(
        state => ({
            alert: state.alert,
            getPoolUser: state.getPoolUser,
            getAvailablePlan: state.getAvailablePlan
        })
    );

    const initialApiCalls = (pg) => {
        let obj = { page: pg, perPage: 10 };
        if (query) {
            obj.query = query
        }
        dispatch(alertActions.clear());
        dispatch(globalPoolUserActions.getPoolUser(apiConstants.GET_POOL_USER, obj));
        dispatch(globalPoolUserActions.getAvailablePlan(apiConstants.GET_AVAILABLE_PLAN));
    }

    useEffect(() => {
        if (getPoolUser && Object.keys(getPoolUser).length > 0) {
            if (getPoolUser.isGetPoolUser) {
                const { totalPages, currentPage, data } = getPoolUser.getPoolUser.data.data;
                setListing(data);
                setTotalpages(totalPages);
                setExistingPage(currentPage)
            }
        }
    }, [getPoolUser])


    useEffect(() => {
        if (getAvailablePlan && Object.keys(getAvailablePlan).length > 0) {
            if (getAvailablePlan.isGetAvailablePlan) {
                setAvailablePlan(getAvailablePlan.getAvailablePlan.data.data)
            }
        }
    }, [getAvailablePlan])

    useEffect(() => {
        setExistingPage(1)
        initialApiCalls(1)
        return () => {
            setAlertRes('')
        }
    }, []);

    useEffect(() => {
        if (Object.keys(alert).length > 0) {
            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                if (alert.message.errors !== undefined) {
                    let keys = Object.keys(alert.message.errors)
                    if (keys.length !== 0) {
                        keys.map(data => {
                            return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                        })
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 8000);
        }
    }, [alert]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setExistingPage(pageNumber)
        initialApiCalls(pageNumber)
    }

    const searchUser = (e) => {
        let obj = { page: 1, perPage: 10 };
        obj.query = e.target.value;
        setQuery(obj.query)
        dispatch(globalPoolUserActions.getPoolUser(apiConstants.GET_POOL_USER, obj));
    }

    const activateHandler = (data) => {
        dispatch(globalPoolUserActions.activateGlobalUser(apiConstants.ACTIVATE_GLOBAL_USER, { activatingUser: data }));
        initialApiCalls(existingPage)
        // initialApiCalls(existingPage)
    }

    const confirmPopupHandler = (e) => {
        setIsShow(true)
        setTempActiveuser(e)
    }

    const eventRes = (data) => {
        if (data.response === "Yes") {
            apiTimeStopper(activateHandler(data.data), 2000);
            // activateHandler(data.data)
            setTimeout(() => {
                setIsShow(false)
            }, 1000);
        } else {
            setIsShow(false)
        }
    }

    const apiTimeStopper = function apiTimeStopper(fun, delay) {
        let timer;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fun();
            }, delay);
        }
    }

    // const requestHandler = (type, data) => {
    //     dispatch(globalPoolUserActions.requestPoolUser(apiConstants.REQUEST_POOL_USER, { requestUser: type, chatUser: data._id }));
    //     initialApiCalls(existingPage)
    // }

    let totalPage = typeof listing !== 'undefined' && Object.keys(listing).length > 0 ? totalPages : 0;

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className="card dash-card tag-block p-3 box_shadow ">
                                                        <div className="row">
                                                            <div className="col-lg-9">  <h2 className="heading-top">Global Pool Users</h2>  </div>
                                                        </div>

                                                        <div className="row m-2">
                                                            <div className="col-sm-12 col-md-12 d-flex justify-content-end">
                                                                <div id="dataTable_filter" className="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" placeholder="search"
                                                                            className="form-control form-control-sm"
                                                                            aria-controls="dataTable"
                                                                            onChange={(e) => searchUser(e)} />

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="scroll-layout">
                                                            {/* owned-sec */}
                                                            <Table>
                                                                <thead className='border-bottom border-top mb-2'>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>UserName</th>
                                                                        <th>Time Left</th>
                                                                        <th>Chat</th>
                                                                        <th className='text-center'>Action</th>
                                                                        {/* <th>Status</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='text-secondary'>
                                                                    {listing && listing.length > 0 ?
                                                                        listing.map((data, index) => {
                                                                            let currentDate = moment(new Date(data.pool_created_date)).add(2, 'M') // 11 nov 2022
                                                                            let tempDate = moment(new Date()) // 22 sept 2022
                                                                            let startDate = moment(new Date(data.pool_created_date)); // 11 sept 2022
                                                                            var years = currentDate.diff(tempDate, 'year');
                                                                            tempDate.add(years, 'years');

                                                                            var months = currentDate.diff(tempDate, 'months');
                                                                            tempDate.add(months, 'months');

                                                                            var days = currentDate.diff(tempDate, 'days');
                                                                            let requestStatus = data?.recieverDetails[0] || data?.senderDetails[0] ?
                                                                                data.recieverDetails[0] ? data.recieverDetails[0] : data.senderDetails[0] : '';

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{data.firstname + data.lastname}</td>
                                                                                    <td>{data.username}</td>
                                                                                    <td>{data.pool_created_date ? years + ' years ' + months + ' months ' + days + ' days' : ''}</td>
                                                                                    <td>
                                                                                        <span className="menu-icon text-primary notify-cursor" onClick={() => { props.history.push({ pathname: '/message', state: { userInfo: data } }) }}>
                                                                                            <i className="far fa-comment" aria-hidden="true"></i>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        <p className='text-info font-weight-bold notify-cursor' onClick={() => confirmPopupHandler(data)}>Activate</p>


                                                                                        {/* {requestStatus ?
                                                                                            <>
                                                                                                {requestStatus === "request" ? <small className='m-1 text-secondary'>Request sent to user</small> :
                                                                                                    <small className='m-1 text-secondary'>User {requestStatus} your request</small>
                                                                                                }

                                                                                                <div className='d-flex justify-content-center'>
                                                                                                    {requestStatus === "accepted" ? <small className='text-info font-weight-bold notify-cursor mr-2' onClick={() => confirmPopupHandler(data)}>Activate</small> : ''}
                                                                                                    {requestStatus === "rejected" ? <small className='text-info font-weight-bold notify-cursor mr-2' onClick={() => requestHandler('request', data)}>Request Again</small> : ''}
                                                                                                </div></>
                                                                                            : ''}
                                                                                        {
                                                                                            !requestStatus ?
                                                                                                data.pool_acceptance === "single" ?
                                                                                                    <p className='text-primary font-weight-bold notify-cursor' onClick={() => requestHandler("request", data)}>Request</p> :
                                                                                                    <p className='text-info font-weight-bold notify-cursor' onClick={() => confirmPopupHandler(data)}>Activate</p>
                                                                                                : ''
                                                                                        } */}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                        : <tr><td colSpan="8">No Record Found</td></tr>
                                                                    }
                                                                </tbody>
                                                            </Table>

                                                            {typeof listing !== 'undefined' ?
                                                                <Pagination totalPage={totalPage} currentPage={existingPage} funPagination={makeHttpRequestWithPage} />
                                                                : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Banner Scetion */}
                            <div className='col-md-12 mt-3'>
                                <BannerRandomFooter />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>

            </div>

            <ConfirmPopup
                show={isShow}
                header="Are You sure you want purchase position for this user ?"
                details={tempActiveuser}
                planDetails={availablePlan}
                eventHandler={(e) => eventRes(e)}
                showDescription={true}
                fromComponent="globalPool"
                resetPopup={true}
            />

        </>
    );
}

export default GlobalPoolUser;
