import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Nav } from 'react-bootstrap';
import { userActions, alertActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import TermsAndConditionPopup from '../Common/Popup/TermsAndConditionPopup';
// import WalletUpdatePopup from '../Common/Popup/WalletUpdatePopup';
// import CreateWalletPopup from '../Common/Popup/CreateWalletPopup';
// import RecoverWalletPopup from '../Common/Popup/RecoverWalletPopup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AfterLoginAds from '../Advertisment/AfterLoginAds';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const speakeasy = require("speakeasy");
const CryptoJS = require("crypto-js");
const publicIp = require('public-ip');

const Login = ({ history, location }, props) => {
  var total_attempt = process.env.REACT_APP_TOTAL_ATTEMPT;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: '', password: ''
  });
  const [errState, setErrState] = useState({});
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState({})
  const [show, setShow] = useState(false);
  const [remember, setRemember] = useState(false);
  const [flag, setFlag] = useState(false);
  const handleClose = () => { setShow(false); setIsLoading(false); };
  const [isLoading, setIsLoading] = useState(false);
  const [systemVerified, setSystemVerified] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [termsPopup, setTermsPopup] = useState(false);
  const [isTerms, setTerms] = useState(false);
  const [otpFlag, setOtpFlag] = useState(false)
  const [userLoginFlag, setUserLoginFlag] = useState(false)
  const [displayAd, setDisplayAd] = useState(false)
  // toast.configure()

  const { alert, language, verifyUser, userLogin, userOtp, getProfile, loginError } = useSelector(
    state => ({
      alert: state.alert,
      language: state.language,
      loginError: state.userLogin,
      verifyUser: state.verifyUser,
      userLogin: state.userLogin,
      userOtp: state.userOtp,
      getProfile: state.getProfile
    })
  );
  // console.log("error", loginError.isLogin, userLogin)


  //   const formik = useFormik({
  //     initialValues: {
  //         firstname: "",

  //     },


  //     onSubmit: values => {
  //         let obj = {
  //             firstname: values.firstname,
  //             lastname: values.lastname,
  //             countryCode: values.countryCode,
  //             contact: values.contact,
  //             country: values.country,
  //             email: values.email,
  //             otp: values.otp
  //         }
  //         setFormData(obj);

  //     }
  // });


  useEffect(() => {
    if (Object.keys(getProfile).length > 0) {

      if (getProfile.isGetProfile) {

        const { data } = getProfile.getProfile;
        // console.log("data", data)
      }
    }
  }, [getProfile])


  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const errInterval = (name) => {
    let target = name + 'Err'
    setTimeout(() => {
      setErrState({ ...errState, [target]: '' })
    }, 3000);
  }

  const handleErr = (name, msg) => {
    let target = name + 'Err';
    setErrState((state) => {
      return { ...state, [target]: msg }
    });
    errInterval(name);
  }

  const notify = () => toast.error("Please verify your Email!");

  useEffect(() => {
    // console.log("userLogin******************", userLogin )
      if (userLogin ) {
        if (userLogin?.isLogin === false) {
          // notify();
          setAlertRes([{ 'type': 'alert-error', 'message': 'Email verification is required...' }])
          // console.log("$$$$$$$$$$$$$")
        }
      }

  }, [userLogin])
  
  const onSubmit = async (e) => {
    // console.log("first@@@@@@@@@@@@@@@@@@", userLogin)
    e.preventDefault();
    if (userLogin?.isLogin === false ) {
      // console.log("dsjkfhjkfhdjk", userLogin?.isLogin)
      setAlertRes([{ 'type': 'alert-error', 'message': 'Email verification is required...' }])
    } else {
      setShow(false)
      e.preventDefault();
      if (Object.keys(formData).length === 0) {
        setAlertRes({ 'type': 'danger', 'message': 'ERR_ENTER_CREDENTIALS' })
      }
      else {
        if (formData.email === '') {
          handleErr('email', 'ERR_BLANK_EMAIL')
        }
        if (formData.password === '') {
          handleErr('password', 'ERR_BLANK_USER_PASSWORD')
        }
        else if (formData.email !== '' && formData.password !== '') {
          setFlag(true);
          let ipAddress = await publicIp.v4()
          let obj = { "email": formData.email, "password": formData.password, "ipAddress": ipAddress }
          if (formData.terms_condition) {
            obj = { ...obj, terms_condition: formData.terms_condition }
          }
          setIsLoading(false);
          dispatch({ type: 'RESTORE_VERIFY_USER' })
          callUserLoginApi()
          // history.push('/AfterLoginAds');
          // dispatch(userActions.userOtp(apiConstants.USER_OTP, obj))
          setOtpFlag(true)
          // dispatch(userActions.userVerify(apiConstants.USER_VERIFY, obj));
        }
      }
    }
  }

  const onVerify = async () => {
    // if (formData.verificationCode !== undefined && formData.verificationCode !== null) {
    let key = localStorage.getItem('loginKey');
    if (!systemVerified) {
      var tokenValidates = speakeasy.totp.verify({
        secret: key,
        encoding: "ascii",
        token: formData.verificationCode.trim(),
        window: 1,
        step: 900
      });
      // console.log(tokenValidates)
    }

    if (tokenValidates || systemVerified) {
      setShow(false);
      let ipAddress = await publicIp.v4()
      let obj = { 'email': formData.email, 'password': formData.password, 'ipAddress': ipAddress };
      if (formData.terms_condition) {
        obj = { ...obj, terms_condition: formData.terms_condition }
      }
      setUserLoginFlag(true)
      dispatch(userActions.userLogin(apiConstants.USER_LOGIN, obj));
    }
    else {
      if (!systemVerified) {
        setAlertRes([{ 'type': 'alert-error', 'message': 'Code Expires...' }])
      }
    }
    //}
  }

  const onRemember = (e) => {
    if (e.target.checked) {
      if (formData.email === '') {
        handleErr('email', 'ERR_BLANK_EMAIL')
      }
      if (formData.password === '') {
        handleErr('password', 'ERR_BLANK_USER_PASSWORD')
      }
      else if (formData.email !== '' && formData.password !== '') {
        setRemember(true)
        var encodedPass = CryptoJS.AES.encrypt(formData.password, 'keyin').toString()
        let obj = { 'onRemember': true, 'email': formData.email, 'password': encodedPass }
        localStorage.setItem('remember', JSON.stringify(obj))
      }
    }
    else {
      setRemember(false)
      localStorage.removeItem('remember')
    }
  }

  const Toggle = () => {
    var temp = document.getElementById("typepass");
    if (temp.type === "password") {
      temp.type = "text";
      setViewPassword(true)
    }
    else {
      temp.type = "password";
      setViewPassword(false);
    }
  }

  const selectedTermsRes = (val) => {

    if (val === 'Yes') {
      setTermsPopup(false);
    }
    else {
      setTermsPopup(false)
    }
  }

  useEffect(() => {
    setShow(false);
    setUserLoginFlag(false)
    localStorage.removeItem('loginKey');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user')
    dispatch(alertActions.clear());
    dispatch(userActions.changeLanguage('ENGLISH'));
    let credentials = JSON.parse(localStorage.getItem('remember'));

    // const params = match.params;
    let path = location.pathname
    const splitData = path.split('/login/');

    if (splitData[1] !== undefined && splitData[1] !== null) {
      let bytes = CryptoJS.AES.decrypt(splitData[1], 'cisSecret');
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (originalText) {
        let parseData = JSON.parse(originalText);
        sessionStorage.setItem('token', parseData.token);
        sessionStorage.setItem('user', JSON.stringify(parseData.userData));
        history.push("/dashboard");
      }
    }

    if (credentials !== undefined && credentials !== null) {

      if (credentials.email !== '' && credentials.password !== '') {
        setRemember(true)
        let encodedPwd = credentials.password
        let bytes = CryptoJS.AES.decrypt(encodedPwd, 'keyin');
        var decryptPass = bytes.toString(CryptoJS.enc.Utf8);
        setFormData({ ...formData, 'email': credentials.email, 'password': decryptPass })
      }
    }
    else {
      setRemember(false)
    }
    return () => {
      setAlertRes('')
      setShow(false)
    }
  }, []);

  const callUserLoginApi = async () => {
    let ipAddress = await publicIp.v4()
    let obj = { 'email': formData.email, 'password': formData.password, 'ipAddress': ipAddress };
    if (formData.terms_condition) {
      obj = { ...obj, terms_condition: formData.terms_condition, obj }
    }
    setUserLoginFlag(true)
    dispatch(userActions.userLogin(apiConstants.USER_LOGIN, obj));
  }

  const callVerifyApi = async () => {
    let ipAddress = await publicIp.v4()
    let obj = { "email": formData.email, "password": formData.password, "ipAddress": ipAddress };
    if (formData.terms_condition) {
      obj = { ...obj, terms_condition: formData.terms_condition }
    }
    dispatch(userActions.userVerify(apiConstants.USER_VERIFY, obj));
  }

  useEffect(() => {
    if (otpFlag) {
      if (userOtp?.userOtp?.data?.data?.otp) {
        callUserLoginApi()
      } else {
        callVerifyApi()
      }
      setOtpFlag(false)
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [userOtp]);

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }

    if (Object.keys(verifyUser).length > 0) {
      if (verifyUser.verified !== undefined) {
        if (verifyUser.isVerified) {
          if (flag) {
            let TA = total_attempt
            if (total_attempt === undefined || total_attempt === null) {
              TA = 3
            }
            const { data } = verifyUser.verified
            if (data.data.attempt < TA) {
              if (!data.data.systemVerified) {
                setShow(true);
                localStorage.setItem('loginKey', data.data.key)
                setFlag(false)
              }
              else {
                setIsLoading(false)
                setFlag(false)
                setSystemVerified(data.data.systemVerified);
              }
            }
            else {
              setIsLoading(false);
              setAlertRes([{ 'type': 'alert-error', 'message': '"No attempts left.Please reset your password...' }])
              setTimeout(() => {
                history.push('/forgot-password')
              }, 2000);
            }
          }
        }
      }
    }

    if (systemVerified) {
      setTimeout(() => {
        setSystemVerified(false)
        onVerify();
      }, 2000);
    }

  }, [language, verifyUser, systemVerified])

  useEffect(() => {
    if (Object.keys(alert).length > 0) {
      setIsLoading(false);
      let tempErr = [];
      if (alert.type === 'alert-success') {
        tempErr = [{ 'type': alert.type, 'message': alert.message }]
      }
      else if (alert.type === 'alert-danger') {
        // console.log("alert.message.errors0", alert)
        if (alert.message.errors !== undefined) {
          if (typeof alert.message.errors == "string") {
            tempErr.push({ 'type': alert.type, message: alert.message.errors })
          }
          else {
            let keys = Object.keys(alert.message.errors)
            if (keys.length !== 0) {
              keys.map((data, index) => {
                if (keys[index] === "terms_condition") {
                  setTerms(true)
                }
                // else if (keys[index] == "wallet_condition") {
                //   setWalletUpdatePopup(true)
                // }
                // else if (keys[index] == "wallet_create") {
                //   setWalletCreatePopup(true)
                // }
                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
              })
            }
          }
        }
        else {
          if (typeof alert.message == "object") {
            let keys = Object.keys(alert.message)
            if (keys.length !== 0) {
              keys.map(data => {
                return tempErr.push({ 'type': alert.type, message: alert.message[data] })
              })
            } else {
              tempErr = [{ 'type': alert.type, message: alert.message }]
            }
          }
          else {
            tempErr = [{ 'type': alert.type, message: alert.message }]
          }
        }


      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 3000);

    }
  }, [alert]);

  useEffect(() => {
    if (userLoginFlag) {
      if (userLogin && userLogin.isLogin) {
        if (userLogin.userLogin) {
          setDisplayAd(true)
          // let timer1 = setTimeout(
          //   () => {
          //     sessionStorage.setItem('token', userLogin.userLogin);
          //     history.push('/dashboard');
          //     setDisplayAd(false)
          //   }, 15000)
          // return () => {
          //   clearTimeout(timer1)
          // }
        }
      }
    }

  }, [userLogin])

  const closeAdvertisment = () => {
    if (userLogin && userLogin.isLogin) {
      if (userLogin.userLogin) {
        sessionStorage.setItem('token', userLogin.userLogin);
        history.push('/dashboard');
        setDisplayAd(false)
      }
    }
  }

  return (
    !displayAd ?
      <>
        {alertRes && alertRes.length > 0 ? alertRes.map((data, index) => {
          return (
            <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ zIndex: '2000' }}
              className="alertBox login-alert">
              {data.message}
            </Alert>)
        }) : ''}

        <section className="account-sec">

          <div className="inner-account d-flex flex-wrap align-items-center">
            <div className="account-box" style={{ "backgroundImage": "url(/assets/images/account-side-bg.jpg)" }}>
              <div className="logo-side">
                <Nav.Link onClick={() => history.push('/')}>
                  <img src="/assets/images/logo1.png" alt="logo" />
                </Nav.Link>
              </div>
            </div>
            <div className="account-box-right">

              <div className="account-left-box">
                <h1>{siteConstants.LABEL_LOGIN}</h1>
                <form>
                  <div className="input-group mb-3">
                    <div className="input-group-img">
                      <i className="far fa-envelope"></i>
                    </div>
                    <input type="email" className="form-control" value={formData.email}
                      name="email" placeholder={siteConstants.GEN_EMAIL_ADDRESS} onChange={(e) => onChangeHandler(e)} />
                  </div>
                  <span className="error-text text-danger text-left">{siteConstants[errState.emailErr]}</span>

                  <div className="input-group mb-3">
                    <div className="input-group-img">
                      <i className="fas fa-unlock-alt"></i>
                    </div>
                    <input type="password" id="typepass" className="form-control" value={formData.password} name="password"
                      placeholder={siteConstants.LABEL_PASSWORD} onChange={(e) => onChangeHandler(e)} style={{ 'width': '90%', 'display': 'inline' }} />
                    {!viewPassword ? <i className="fas fa-eye" style={{ 'marginTop': '5%' }} onClick={() => Toggle()}></i> :
                      <i className="fas fa-eye-slash" style={{ 'marginTop': '5%' }} onClick={() => Toggle()}></i>}

                  </div>
                  <span className="error-text text-danger text-left">{siteConstants[errState.passwordErr]}</span>

                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="checkbox">
                      <label><input type="checkbox" checked={remember} onChange={(e) => onRemember(e)} />{siteConstants.LABEL_REMEMBER_ME}</label>
                    </div>
                    <a href='/forgot-password'>{siteConstants.LABEL_FORGET_PASSWORD}</a>
                  </div>

                  {isTerms ?
                    <div className='mt-2 mb-2 d-flex checkbox'>
                      <label className='f-14'><input style={{ 'width': 'fit-content' }} type='checkbox' name="terms_condition" checked={formData.terms_condition}
                        onChange={(e) => setFormData({ ...formData, 'terms_condition': e.target.checked })}></input>
                        <span>I agree to the
                          <span className='text-primary ml-1 mr-2' onClick={() => setTermsPopup(true)}> Terms And Condition</span> and
                          <span className='text-primary ml-1 mr-2' onClick={() => setTermsPopup(true)}> Privacy Policy</span>
                        </span>
                      </label>

                      <span className="text-danger font-error error-text">{siteConstants[errState.terms_conditionErr]}</span>

                    </div> : ''}

                  {!isLoading ?
                    <button type="submit" onClick={(e) => onSubmit(e)} className="btn btn-primary mt-3 d-block">{siteConstants.LABEL_LOGIN}</button>
                    :
                    <button type="button" className="btn btn-default">
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>}
                  {/* {isActivation ? <button type="button" onClick={(e)=>onResend(e)} style={{'margin':'20px 0 0 !important'}} 
                 className="link-btn btn btn-info d-block">Resend Activation Link</button>
                  : ''} */}

                </form>
                {/* 
              <div className="or-sec"><span className="left-or"></span> or <span className="right-or"></span></div>

              <Nav.Link className="google-login "><i className="fab fa-google mr-2"></i>{siteConstants.LABEL_LOGIN_WITH_GOOGLE}</Nav.Link>
              <Nav.Link className="google-fb "><i className="fab fa-facebook-f mr-2"></i>{siteConstants.LABEL_LOGIN_WITH_FACEBOOK}</Nav.Link> */}

              </div>

              <div className="account-sec">
                <p> {siteConstants.LABEL_NOT_HAVE_ACCOUNT} <Nav.Link onClick={() => history.push('/register')}>{siteConstants.SIGNUP_NOW}</Nav.Link></p>
                <p className='text-secondary'>Please feel free to reach out to us if need further assistance
                  <b className='text-info'>{" " + process.env.REACT_APP_FRONTEND_SUPPORT_EMAIL}</b></p>
              </div>
            </div>
          </div>

          <Modal show={show} onHide={() => handleClose()} size="xxl" animation={true}>
            <Modal.Header closeButton>
              <div>
                <Modal.Title>{siteConstants.LOGIN_CODE}</Modal.Title>
                <small className="font-gray mt-2">We have sent an OTP to your given email address. It will expire in 5 minutes.</small>
              </div>
            </Modal.Header>

            <Modal.Body>
              <div className="modal-input form-group">
                <input type="text" className="form-control" id="verificationCode" onChange={(e) => onChangeHandler(e)}
                  placeholder={siteConstants.ENTER_VERIFICATION_CODE + ":"} name="verificationCode" />
              </div>
              {/* <small className="mt-4">Code send on your mail id. Valid for <span className="text-danger">5</span> min</small>  */}
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary btn-gray" onClick={() => handleClose()}>{siteConstants.LABEL_CLOSE}</Button>
              <Button variant="primary" onClick={() => onVerify()}>{siteConstants.BTN_SAVE}</Button>
            </Modal.Footer>
          </Modal>

          {termsPopup ? <TermsAndConditionPopup onSelect={selectedTermsRes} /> : ''}

          {/* {walletUpdatePopup ? <WalletUpdatePopup onSelect={selectedWalletRes} /> : ''}

          {walletCreatePopup ? <CreateWalletPopup onSelect={selectedCreateWalletRes} /> : ''}

          {recoverWalletPopup ? <RecoverWalletPopup onSelect={selectedRecoverWalletRes} /> : ''} */}

        </section>
      </>

      :
      <AfterLoginAds closeAd={() => closeAdvertisment()} />

  )
}

export default Login
