import { pushNotificationConstants } from '../constants';

//-- get the list of notification 
export function getPushNotifictaionList(state = {}, action) {
  switch (action.type) {
    case pushNotificationConstants.GET_PUSH_NOTIFICATION_LIST_REQUEST:
      return {
        isGetPushNotifictaionList: false
      };

    case pushNotificationConstants.GET_PUSH_NOTIFICATION_LIST_SUCCESS:
      return { isGetPushNotifictaionList:true , getPushNotifictaionList:action.successAction};

    case pushNotificationConstants.GET_PUSH_NOTIFICATION_LIST_FAILURE:
      return {
        isGetPushNotifictaionList: false,
      };

    default:
      return state
  }
}

//-- get count of notification
export function getPushNotifictaionCount(state = {}, action) {
    switch (action.type) {
      case pushNotificationConstants.GET_PUSH_NOTIFICATION_COUNT_REQUEST:
        return {
          isGetPushNotifictaionCount: false
        };
  
      case pushNotificationConstants.GET_PUSH_NOTIFICATION_COUNT_SUCCESS:
        return { isGetPushNotifictaionCount :true , getPushNotifictaionCount:action.successAction};
  
      case pushNotificationConstants.GET_PUSH_NOTIFICATION_COUNT_FAILURE:
        return {
          isGetPushNotifictaionCount: false,
        };
  
      default:
        return state
    }
}

//-- get count of notification
export function getPushNotificationById(state = {}, action) {
  switch (action.type) {
    case pushNotificationConstants.GET_PUSH_NOTIFICATION_BY_ID_REQUEST:
      return {
        isGetPushNotificationById: false
      };

    case pushNotificationConstants.GET_PUSH_NOTIFICATION_BY_ID_SUCCESS:
      return { isGetPushNotificationById :true , getPushNotificationById:action.successAction};

    case pushNotificationConstants.GET_PUSH_NOTIFICATION_BY_ID_FAILURE:
      return {
        isGetPushNotificationById: false,
      };

    default:
      return state
  }
}
  