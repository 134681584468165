import { feedbackConstants } from '../constants';

export function addContactDetails(state={},action){
    switch(action.type){
        case feedbackConstants.ADD_CONTACT_DETAILS_REQUEST:
            return{
                isAddContactDetails: false
            };
        case feedbackConstants.ADD_CONTACT_DETAILS_SUCCESS:
            return{
                isAddContactDetails: true,addContactDetails:action.successAction
            }
        case feedbackConstants.ADD_CONTACT_DETAILS_FAILURE:
            return{
                isAddContactDetails: false
            }
        default:
            return state
    }
}

export function addNewsLetter(state={},action){
    switch(action.type){
        case feedbackConstants.ADD_NEWSLETTER_REQUEST:
            return{
                isAddNewsletter: false
            };
        case feedbackConstants.ADD_NEWSLETTER_SUCCESS:
            return{
                isAddNewsletter: true,addNewsletter:action.successAction
            }
        case feedbackConstants.ADD_NEWSLETTER_FAILURE:
            return{
                isAddNewsletter: false
            }
        default:
            return state
    }
}

export function getFaqList(state={},action){
    switch(action.type){
        case feedbackConstants.GET_FAQ_REQUEST:
            return{
                isGetFaqList: false
            };
        case feedbackConstants.GET_FAQ_SUCCESS:
            return{
                isGetFaqList: true,getFaqList:action.successAction
            }
        case feedbackConstants.GET_FAQ_FAILURE:
            return{
                isGetFaqList: false
            }
        default:
            return state
    }
}

export function getCompetition(state={},action){
    switch(action.type){
        case feedbackConstants.GET_COMPETITION_REQUEST:
            return{
                isGetCompetition: false
            };
        case feedbackConstants.GET_COMPETITION_SUCCESS:
            return{
                isGetCompetition: true,getCompetition:action.successAction
            }
        case feedbackConstants.GET_COMPETITION_FAILURE:
            return{
                isGetCompetition: false
            }
        default:
            return state
    }
}

export function getMarketing(state={},action){
    switch(action.type){
        case feedbackConstants.GET_MARKETING_REQUEST:
            return{
                isGetMarketing: false
            };
        case feedbackConstants.GET_MARKETING_SUCCESS:
            return{
                isGetMarketing: true,getMarketing:action.successAction
            }
        case feedbackConstants.GET_MARKETING_FAILURE:
            return{
                isGetMarketing: false
            }
        default:
            return state
    }
}

export function getTestimonials(state={},action){
    switch(action.type){
        case feedbackConstants.GET_TESTIMONIALS_REQUEST:
            return{
                isGetTestimonials: false
            };
        case feedbackConstants.GET_TESTIMONIALS_SUCCESS:
            return{
                isGetTestimonials: true,getTestimonials:action.successAction
            }
        case feedbackConstants.GET_TESTIMONIALS_FAILURE:
            return{
                isGetTestimonials: false
            }
        default:
            return state
    }
}
