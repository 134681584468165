import React, { useState, useEffect } from 'react'
import { advertismentAction, userActions } from '../../actions';
import { apiConstants, bannerTypeConstants } from '../../constants';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
const publicIp = require("public-ip");

function AfterLoginAds(props) {
    const dispatch = useDispatch()
    const [timeSetter, setTimeSetter] = useState(15);
    const [defaultLoginLink, setDefaultLoginLink] = useState()
    const [randomAdvertismentList, setRandomAdvertismentList] = useState([]);
    const [adId, setAdId] = useState("")
    const { getRandomAdvertismentList, getSetting } = useSelector((state) => ({
        getRandomAdvertismentList: state.getRandomAdvertismentList,
        getSetting: state.getSetting,
    }));

    const fetchRandomList = async () => {
        let ipAddress = await publicIp.v4();
        let obj = {
            orderByAscDesc: -1,
            limitQuery: false,
            ipAddress: ipAddress,
            type: bannerTypeConstants.LOGIN_ADS,
            perPage: bannerTypeConstants.LOGIN_ADS_LIMIT
        }
        dispatch(advertismentAction.getRandomAdvertismentList(apiConstants.GET_RANDOM_ADVERTISMENT, obj));
        dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
    }

    // get login ads from this useEffect
    useEffect(() => {
        if (getRandomAdvertismentList) {
            if (Object.keys(getRandomAdvertismentList).length > 0) {
                if (getRandomAdvertismentList.getRandomAdvertismentList) {
                    if (getRandomAdvertismentList.getRandomAdvertismentList.data) {
                        const { data } = getRandomAdvertismentList.getRandomAdvertismentList.data.data;
                        if (data.length > 0) {
                            setRandomAdvertismentList(data);
                            if (data && data.length > 0) {
                                setAdId(data[0]._id)
                            }
                        }
                    }
                }
            }
        }
    }, [getRandomAdvertismentList]);

    // GET SETTING START (useEffect)
    useEffect(() => {
        if (Object.keys(getSetting).length > 0) {
            if (getSetting.isGetSetting) {
                if (getSetting.getSetting.data) {

                    const { data } = getSetting.getSetting;
                    setDefaultLoginLink(data.data.textLink ? data.data.textLink : '#')
                }
            }
        }
    }, [getSetting]);
    // GET SETTING END (useEffect)

    // this useeffect called initially
    useEffect(() => {
        fetchRandomList()
    }, [])

    useEffect(() => {
        if (defaultLoginLink || (randomAdvertismentList && randomAdvertismentList.length > 0) ) {
            if (timeSetter) {
                setTimeout(() => {
                    setTimeSetter(timeSetter - 1)
                }, 1000)
            }
        }
    }, [timeSetter, defaultLoginLink])

    // default login ads template when no login ads available
    const defaultLoginAd = () => {
        if (defaultLoginLink && defaultLoginLink!=='#') {
            return (<iframe key="1" className='w-100 h-90vh' src={defaultLoginLink} title="title" />)
        } else {
            return (<div className='bg-primary'></div>)
        }
    }

    return (
        <>
            {randomAdvertismentList && timeSetter === 0 ?
                <div className='p-2 after-login-ads blue-layout d-flex flex-row align-items-center justify-content-center text-white'>
                    <b>Thanks for viewing the site</b>
                    <button className='ml-2 no-hover-btn link-btn font-weight-bold' onClick={() => props.closeAd(true)}>
                        Click here to go to Dashboard
                    </button>
                    {randomAdvertismentList.length > 0 && randomAdvertismentList[0].link ?
                        <>
                            <button className='no-hover-btn ml-2 success-btn'><a className='text-white font-weight-bold' href={randomAdvertismentList[0].link} target='_blank' rel="noreferrer">Open site in new window</a></button>
                        </> : ""}
                </div>
                :
                <div className='after-login-ads bg-warning d-flex flex-column align-items-center justify-content-center text-dark'>
                    <b> You will be automatically redirected to your area after {timeSetter} seconds </b>
                    <b> To Add your site here click on Login Ads link inside the members area !!</b>
                    {randomAdvertismentList.length > 0 ? <><b>
                        <a href={`/adverisment-report/${adId}`} target='_blank' rel="noreferrer">Report Advertisment</a>
                    </b></> : ""}
                </div>


            }

            <div>
                <section className="d-flex align-items-center justify-content-center" style={{
                    backgroundRepeat: "no-repeat", backgroundSize: "cover",
                    backgroundImage: `url('/assets/images/BGR.png')`, height: '93.2vh',
                }}>

                    {randomAdvertismentList ? (
                        randomAdvertismentList.length > 0 ? (
                            randomAdvertismentList.map((data, index) => {
                                if (data.type === bannerTypeConstants.LOGIN_ADS) {
                                    if (data.files) {
                                        return (
                                            <a href={data.link} target='blank'>
                                                <img className="ad-banner" src={data.files} alt="bannerImg" />
                                            </a>)
                                    } else {
                                        return (
                                            <iframe key={index} className='w-100 h-90vh' src={data.link} title={data.bannerTitle ? data.bannerTitle : 'Header'} />
                                        );
                                    }
                                }
                            })
                        ) : (
                            defaultLoginAd()
                        )) : (
                        defaultLoginAd()
                    )}

                </section>
            </div>
        </>
    )
}

export default AfterLoginAds