import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, pushNotificationActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
var momentTimeZone = require('moment-timezone');
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const NotificationDetails = (props) => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [notificationDetails, setNotificationDetails] = useState([]);

    const { alert, getPushNotificationById } = useSelector(
        state => ({
            alert: state.alert,
            getPushNotificationById: state.getPushNotificationById
        })
    );

    useEffect(() => {
      
        if (getPushNotificationById !== undefined && getPushNotificationById !== null) {
            if (Object.keys(getPushNotificationById).length > 0) {
                if (getPushNotificationById.getPushNotificationById !== undefined) {
                    if (getPushNotificationById.getPushNotificationById.data !== undefined) {

                        if (getPushNotificationById.getPushNotificationById.data.data) {
                            setNotificationDetails(getPushNotificationById.getPushNotificationById.data.data);
                        }
                    }
                }
            }
        }
    }, [ getPushNotificationById]);

    useEffect(() => {
        let obj = { _id: props && props.location && props.location.state && props.location.state.detail ? props.location.state.detail : null }
        getNotificationDetails(obj)
        dispatch(alertActions.clear());
        return () => {
            setAlertRes('')
        }
    }, []);

    const getNotificationDetails = (obj) => {
        dispatch(pushNotificationActions.getPushNotificationById(apiConstants.GET_USER_NOTIFICATION_BY_ID, obj));
    }

    useEffect(() => {
        if (alert !== undefined && alert !== null) {
            if (Object.keys(alert).length > 0) {

                let tempErr;
                if (alert.type === 'alert-success') {
                    tempErr = [{ 'type': alert.type, 'message': alert.message }]
                }
                else {
                    if (alert.message.errors !== undefined && alert.message.errors !== null) {
                        let keys = Object.keys(alert.message.errors)
                        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
                    }
                }

                setAlertRes(tempErr);
                setTimeout(() => {
                    setAlertRes('')
                }, 3000);
            }
        }
    }, [alert]);


    const backHandler = () => {
        try {
            props.history.goBack();
        }
        catch (err) {
            console.log("err----", err)
        }
    }

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== undefined && alertRes !== null && alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className="card dash-card tag-block p-3 box_shadow">
                                                        <div className="row">
                                                            <div className="col-lg-9 d-flex align-items-center">
                                                                <h5 className="text-primary heading-top"><i className="m-2 fas fa-arrow-left" onClick={() => { backHandler() }}></i>Notification Details</h5>
                                                            </div>
                                                        </div>

                                                        <div className="owned-sec scroll-layout">
                                                            <h4 className='text-dark'>{notificationDetails.notification_subject}</h4>
                                                            <div className="p-2 bg-light">
                                                                <p className='text-secondary f-18'>{notificationDetails.notification_message}</p>
                                                                <span className='d-flex justify-content-between'>
                                                                    <small>{
                                                                        momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                                                            momentTimeZone(notificationDetails.created_at).tz(momentTimeZone.tz.guess()).format('DD MMMM YYYY')
                                                                            :
                                                                            momentTimeZone(notificationDetails.created_at).tz(timeZone).format('DD MMMM YYYY')
                                                                    }</small>
                                                                     {/* <small className='text-primary'>Hide</small> */}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>

            </div >

        </>
    );
}

export default NotificationDetails;
