import { walletConstants } from '../constants';

export function recoverWallet(state = {}, action) {
    switch (action.type) {
        case walletConstants.RECOVER_WALLET_REQUEST:
            return {
                isRecoverWallet: false
            };
        case walletConstants.RECOVER_WALLET_SUCCESS:
            return {
                isRecoverWallet: true, recoverWallet: action.successAction
            }
        case walletConstants.RECOVER_WALLET_FAILURE:
            return {
                isRecoverWallet: false, recoverWallet: false
            }
        default:
            return state
    }
}

export function getTransaction(state = {}, action) {
    switch (action.type) {
        case walletConstants.GET_TRANSACTION_REQUEST:
            return {
                isGetTransaction: false
            };
        case walletConstants.GET_TRANSACTION_SUCCESS:
            return {
                isGetTransaction: true, getTransaction: action.successAction
            }
        case walletConstants.GET_TRANSACTION_FAILURE:
            return {
                isGetTransaction: false
            }
        default:
            return state
    }
}

export function getWithdrawTransaction(state = {}, action) {
    switch (action.type) {
        case walletConstants.GET_WITHDRAW_TRANSACTION_REQUEST:
            return {
                isGetWithdrawTransaction: false
            };
        case walletConstants.GET_WITHDRAW_TRANSACTION_SUCCESS:
            return {
                isGetWithdrawTransaction: true, getWithdrawTransaction: action.successAction
            }
        case walletConstants.GET_WITHDRAW_TRANSACTION_FAILURE:
            return {
                isGetWithdrawTransaction: false
            }
        default:
            return state
    }
}

export function getWallet(state = {}, action) {
    switch (action.type) {
        case walletConstants.GET_WALLET_REQUEST:
            return {
                isGetWallet: false
            };
        case walletConstants.GET_WALLET_SUCCESS:
            return {
                isGetWallet: true, getWallet: action.successAction
            }
        case walletConstants.GET_WALLET_FAILURE:
            return {
                isGetWallet: false
            }
        default:
            return state
    }
}

export function addWallet(state = {}, action) {
    switch (action.type) {
        case walletConstants.ADD_WALLET_REQUEST:
            return {
                isAddWallet: false
            };
        case walletConstants.ADD_WALLET_SUCCESS:
            return {
                isAddWallet: true, addWallet: action.successAction
            }
        case walletConstants.ADD_WALLET_FAILURE:
            return {
                isAddWallet: false, addWallet: false
            }
        default:
            return state
    }
}

export function getBalance(state = {}, action) {
    switch (action.type) {
        case walletConstants.GET_WALLET_BALANCE_REQUEST:
            return {
                isgetBalance: false
            };
        case walletConstants.GET_WALLET_BALANCE_SUCCESS:
            return {
                isgetBalance: true, getBalance: action.successAction
            }
        case walletConstants.GET_WALLET_BALANCE_FAILURE:
            return {
                isgetBalance: false
            }
        default:
            return state
    }
}

export function sendMoney(state = {}, action) {
    switch (action.type) {
        case walletConstants.SEND_MONEY_REQUEST:
            return {
                isSendMoney: false
            };
        case walletConstants.SEND_MONEY_SUCCESS:
            return {
                isSendMoney: true, sendMoney: true
            }
        case walletConstants.SEND_MONEY_FAILURE:
            return {
                isSendMoney: false, sendMoney: false
            }
        case walletConstants.SEND_MONEY_RESTORE_DEFAULT:
            return {
                isSendMoney: false
            }
        default:
            return state
    }
}

export function getWalletUser(state = {}, action) {
    switch (action.type) {
        case walletConstants.GET_WALLET_USER_REQUEST:
            return {
                isGetWalletUser: false
            };
        case walletConstants.GET_WALLET_USER_SUCCESS:
            return {
                isGetWalletUser: true, getWalletUser: action.successAction
            }
        case walletConstants.GET_WALLET_USER_FAILURE:
            return {
                isGetWalletUser: false, getWalletUser: false
            }
        default:
            return state
    }
}

export function withdrawMoney(state = {}, action) {
    switch (action.type) {
        case walletConstants.WITHDRAW_MONEY_REQUEST:
            return {
                isWithdrawMoney: false
            };
        case walletConstants.WITHDRAW_MONEY_SUCCESS:
            return {
                isWithdrawMoney: true, withdrawMoney: true
            }
        case walletConstants.WITHDRAW_MONEY_FAILURE:
            return {
                isWithdrawMoney: false, withdrawMoney: false
            }
        default:
            return state
    }
}

export function updateWalletKey(state = {}, action) {
    switch (action.type) {
        case walletConstants.UPDATE_WALLET_KEY_REQUEST:
            return {
                isUpdateWalletKey: false
            };
        case walletConstants.UPDATE_WALLET_KEY_SUCCESS:
            return {
                isUpdateWalletKey: true, updateWalletKey: true
            }
        case walletConstants.UPDATE_WALLET_KEY_FAILURE:
            return {
                isUpdateWalletKey: false, withdrawMoney: false
            }
        default:
            return state
    }
}
