import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, supportActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import EditTicket from '../Common/Popup/EditTicket';
import CommentPopup from '../Common/Popup/CommentPopup';
import ViewTicket from '../Common/Popup/ViewTicket';
var momentTimeZone = require('moment-timezone');
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const TrackTicket = () => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [ticketList, setTicketList] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpages] = useState(1);
    const [ticketPopup, setTicketPopup] = useState(false);
    const [editTicket, setEditTicket] = useState('');
    const [commentPopup, setCommentPopup] = useState(false);
    const [viewTicket, setViewTicket] = useState(false)

    const { alert, getSupportTicket, getSupportTicketById } = useSelector(
        state => ({
            alert: state.alert,
            getSupportTicket: state.getSupportTicket,
            getSupportTicketById: state.getSupportTicketById
        })
    );

    useEffect(() => {

        if (getSupportTicket !== undefined && getSupportTicket !== null) {
            if (Object.keys(getSupportTicket).length > 0) {
                if (getSupportTicket.isGetSupportTicket) {
                    const { totalPages } = getSupportTicket.getSupportTicket.data.data;
                    setTicketList(getSupportTicket.getSupportTicket.data.data.data);
                    setTotalpages(totalPages);
                }
            }
        }
        if (getSupportTicketById !== undefined && getSupportTicketById !== null) {
            if (Object.keys(getSupportTicketById).length > 0) {
                if (getSupportTicketById.isGetSupportTicketById) {
                    let doc = getSupportTicketById.getSupportTicketById.data.data;

                    setEditTicket({
                        ...editTicket, '_id': doc._id, 'priority': { 'value': doc.priority, 'label': doc.priority },
                        'status': { 'value': doc.status, 'label': doc.status },
                        comment: doc.comment,
                        // 'assignedTo': supportUserOption[tempIndex], 
                        category: doc.category, description: doc.description,
                        ticketNumber: doc.ticketNumber, subject: doc.subject
                    })

                }
            }
        }
    }, [getSupportTicket, getSupportTicketById]);

    useEffect(() => {
        let obj = {
            page: 1,
            perPage: 10
        };
        dispatch(alertActions.clear());
        dispatch(supportActions.getSupportTicket(apiConstants.GET_SUPPORT_TICKET, obj));
        return () => {
            setAlertRes('')
        }
    }, []);


    useEffect(() => {

        if (Object.keys(alert).length > 0) {

            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                if (alert.message && alert.message.errors) {
                    if (typeof alert.message.errors == "string") {
                        tempErr.push({ 'type': alert.type, message: alert.message.errors })
                    }
                    else {
                        let keys = Object.keys(alert.message.errors)
                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                            })
                        }
                        else {
                            tempErr = [{ 'type': alert.type, message: alert.message }]
                        }
                    }
                }
                else {
                    if (typeof alert.message == "object") {
                        let keys = Object.keys(alert.message)
                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message[data] })
                            })
                        } else {
                            tempErr = [{ 'type': alert.type, message: alert.message }]
                        }
                    }
                    else {
                        tempErr = [{ 'type': alert.type, message: alert.message }]
                    }
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);
        }
    }, [alert]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        let obj = {
            page: pageNumber,
            perPage: 10,
            // orderByAscDesc: -1,
            // limitQuery: false
        };
        dispatch(supportActions.getSupportTicket(apiConstants.GET_SUPPORT_TICKET, obj));
    }

    const searchTicket = (e) => {
        let obj = {
            page: 1,
            perPage: 10
        };

        if (e.target.value !== '') {
            obj.qwery = e.target.value;
            dispatch(supportActions.getSupportTicket(apiConstants.GET_SUPPORT_TICKET, obj));
        } else {
            dispatch(supportActions.getSupportTicket(apiConstants.GET_SUPPORT_TICKET, obj));
        }
    }

    const selectedRes = (val) => {

        if (val.res) {
            dispatch(supportActions.editSupportTicket(apiConstants.EDIT_SUPPORT_TICKET,
                { ticketId: val.ticketId, data: val.data }));
            setTicketPopup(false);
            setCommentPopup(false);
            setViewTicket(false);
        }
        else {
            setTicketPopup(false);
            setCommentPopup(false);
            setViewTicket(false);
        }
    }

    const deleteTicket = (ticketId) => {
        dispatch(supportActions.deleteSupportTicket(apiConstants.DELETE_SUPPORT_TICKET, { ticketId: ticketId }));
    }

    const sendComment = (userComment) => {
        dispatch(supportActions.commentSupportTicket(apiConstants.COMMENT_ON_SUPPORT_TICKET,
            { ticketId: userComment._id, 'message': userComment.message }));
        setTimeout(() => {
            dispatch(supportActions.getSupportTicketById(apiConstants.GET_SUPPORT_TICKET_BY_ID, { _id: userComment._id }))
        }, 1000);
    }

    let totalPage = typeof ticketList !== 'undefined' && Object.keys(ticketList).length > 0 ? totalPages : 0;

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== undefined && alertRes !== null && alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className="card dash-card tag-block p-3 box_shadow">
                                                        <div className="row">
                                                            <div className="col-lg-9">  <h2 className="heading-top  mb-1">Track Ticket</h2>  </div>
                                                        </div>

                                                        <div className="row m-2">
                                                            <div className="col-sm-12 col-md-12 d-flex justify-content-end">
                                                                <div id="dataTable_filter" className="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" placeholder="search"
                                                                            className="form-control form-control-sm"
                                                                            aria-controls="dataTable"
                                                                            onChange={(e) => searchTicket(e)} />

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="owned-sec scroll-layout">

                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Ticket Number</th>
                                                                        <th>Subject</th>
                                                                        <th>Description</th>
                                                                        <th>Status</th>
                                                                        <th>Priority</th>
                                                                        <th>Last Updated At</th>
                                                                        <th>Last Comment</th>
                                                                        {/* <th>Assigned To</th> */}
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {ticketList !== undefined && ticketList !== null ?
                                                                        ticketList.length > 0 ? ticketList.map((data, index) => {
                                                                            let d = new Date(data.updatedAt);

                                                                            let comment = data.comment && data.comment.length > 0 ?
                                                                                data.comment[data.comment.length - 1].message : <i className="fas fa-plus"></i>

                                                                            return (
                                                                                <tr className='text-secondary' key={index}>
                                                                                    <td>{data.ticketNumber}</td>
                                                                                    <td>{data.subject}</td>
                                                                                    <td>{data.description ? data.description : '-'}</td>
                                                                                    <td>{data.status ? data.status : '-'}</td>
                                                                                    <td className={data.priority ? data.priority === "low" ? 'text-primary' :
                                                                                        data.priority === "medium" ? 'text-warning' :
                                                                                            data.priority === "high" ? 'text-danger' : '' : ''}>{data.priority ? data.priority : '-'}</td>
                                                                                    <td>{momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                                                                        momentTimeZone(d).tz(momentTimeZone.tz.guess()).format('DD MMMM YYYY')
                                                                                        :
                                                                                        momentTimeZone(d).tz(timeZone).format('DD MMMM YYYY')}</td>
                                                                                    <td className='text-primary notify-cursor' onClick={() => { setCommentPopup(true); setEditTicket(data) }}>{comment ? comment : '-'}</td>
                                                                                    {/* <td>{assigneeName ? assigneeName : 'Not assigned'}</td> */}
                                                                                    <td className="tdTextCenter notify-cursor">
                                                                                        <i className='fa fa-eye mr-2' onClick={() => { setViewTicket(true); setEditTicket(data) }}></i>
                                                                                        <i className="fas fa-trash-alt mr-2" onClick={() => deleteTicket(data._id)}></i>
                                                                                        <i className="far fa-edit" onClick={() => { setTicketPopup(true); setEditTicket(data) }}></i>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                            : <tr><td colSpan="8">No Record Found</td></tr>
                                                                        : <tr><td>-</td></tr>}
                                                                </tbody>
                                                            </table>

                                                            {typeof ticketList !== 'undefined' ?
                                                                <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                                                                : ''}



                                                        </div>
                                                    </div>
                                                    <p className='text-secondary m-2'>Please feel free to reach out to us if need further assistance <b>{process.env.REACT_APP_FRONTEND_SUPPORT_EMAIL}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Banner Scetion */}
                            <div className='col-md-12 mt-3'>
                                <BannerRandomFooter />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>

            </div >
            {ticketPopup ? <EditTicket onSelect={selectedRes} editData={editTicket} /> : ''}
            {commentPopup ? <CommentPopup onSelect={selectedRes} editData={editTicket} sendComment={sendComment} /> : ''}
            {viewTicket ? <ViewTicket onSelect={selectedRes} editData={editTicket} /> : ''}

        </>
    );
}

export default TrackTicket;
