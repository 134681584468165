import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';

export default function ViewTicket({ onSelect, editData, sendComment }) {

    const [values, setValues] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        let assigneeName = '';
        let firstname = editData.assigneeDetail.firstname && editData.assigneeDetail.firstname !== null ? editData.assigneeDetail.firstname : ''
        let lastname = editData.assigneeDetail.lastname && editData.assigneeDetail.lastname !== null ? editData.assigneeDetail.lastname : ''
        assigneeName = firstname + ' ' + lastname
        setValues({ ...values, assignedTo: assigneeName, comment: editData.comment, ticketId: editData._id, 'priority': editData.priority, 'description': editData.description })

    }, [editData])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        if (val === "Yes") {
            let obj = { 'priority': values.priority, 'description': values.description }
            onSelect({ res: true, data: obj, ticketId: values.ticketId })
        }
        else {
            onSelect({ res: false, obj: val })
        }
    }

    //Usage example:
    const downloadFile = (attachment) => {
       
        var result = null;

        if (typeof attachment !== 'string') {
            return result;
        }

        var mime = attachment.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        var element = document.createElement('a');
        element.setAttribute('href', attachment);

        if (result) {
            element.setAttribute('download', result);
        }
        else {
            element.setAttribute('download', 'text');
        }
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }


    return (
        <Modal show={true} size="lg" animation={true}>
            <Modal.Header>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_VIEW_TICKET} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row p-3">
                    <div className='col-md-12 m-1 p-2  shadow bg-white rounded'><h5 className='font-blue'>Ticket Number</h5><p className='text-secondary'>{editData.ticketNumber ? '#' + editData.ticketNumber : '-'}</p></div>
                    <div className="mt-2 mb-2 col-lg-12 p-2">
                        <div className='d-flex'>
                            <div className='col-md-6'><h6 className='font-blue'>Proiority</h6><p className='text-secondary border-bottom'>{editData.priority ? editData.priority : '-'}</p></div>
                            <div className='col-md-6'><h6 className='font-blue'>Status</h6><p className='text-secondary border-bottom'>{editData.status ? editData.status : '-'}</p></div>
                        </div>
                        <div className='col-md-12'><h6 className='font-blue'>Category</h6><p className='text-secondary border-bottom'>{editData.category}</p></div>
                        <div className='d-flex'>
                            <div className='col-md-6'><h6 className='font-blue'>Subject</h6><p className='text-secondary border-bottom'>{editData.subject}</p></div>
                            {/* <div className='col-md-6'><h6 className='font-blue'>Assigned To</h6><p className='text-secondary border-bottom'>{values.assignedTo}</p></div> */}
                        </div>
                        <div className='col-md-12'><h6 className='font-blue'>Description</h6><p className='text-secondary border p-2' style={{ 'minHeight': '150px' }}>{editData.description}</p></div>
                        {editData.attachment ?
                            <div className='col-lg-12 font-weight-bold text-secondary' onClick={() => downloadFile(editData.attachment)}>
                                <h6 className='font-blue'>Attachment</h6>
                                <p className='text-secondary border p-2 notify-cursor'>Click here to download ....</p>
                            </div>
                            : ''}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
            </Modal.Footer>

        </Modal>
    )
}
