import React, { useEffect, useState } from 'react'
import './DiamondStructure.css';
import { genealogyActions } from '../../actions';
import { history } from '../../helpers';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiConstants } from '../../constants';

const DiamondStructure = ({ matrixPosition, previousBoard }) => {

    const userName = JSON.parse(sessionStorage.getItem('user'));
    const location = useLocation();
    const [rootVal, setRootVal] = useState([]);
    const [arr, setArr] = useState([]);
    const dispatch = useDispatch();

    const [levelZero, setLevelZero] = useState([]);
    const [levelOne, setLevelOne] = useState([]);
    const [levelTwo, setLevelTwo] = useState([]);
    const [levelThree, setLevelThree] = useState([]);
    const [levelFour, setLevelFour] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [positionColor, setPositionColor] = useState('');
    const [imgPath, setImgPath] = useState('./assets/images/Diamond.png');
    const [imgFlag, setImgFlag] = useState('')

    var positionName = [
        "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten",
        "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen", "twenty",
        "twenty-one", "twenty-two", "twenty-three", "twenty-four", "twenty-five", "twenty-six",
        "twenty-seven", "twenty-eight", "twenty-nine", "thirty",
        "thirty-one", "thirty-two", "thirty-three", "thirty-four",
        "thirty-five", "thirty-six", "thirty-seven", "thirty-eight", "thirty-nine", "forty",
        "forty-one", "forty-two", "forty-three", "forty-four", "forty-five", "forty-six", "forty-seven",
        "forty-eight", "forty-nine", "fifty",
        "fifty-one", "fifty-two", "fifty-three", "fifty-four", "fifty-five", "fifty-six", "fifty-seven",
        "fifty-eight", "fifty-nine", "sixty",
        "sixty-one", "sixty-two", "sixty-three"
    ];

    useEffect(() => {
        let original_path = "./assets/images/Diamond_Img/Diamond";
        setImgPath(original_path + '_' + imgFlag + '.png')
    }, [imgFlag])

    const intializeLevel = () => {
        setLevelZero([]);
        setLevelOne([]);
        setLevelTwo([]);
        setLevelThree([]);
        setLevelFour([]);
        setTempArr([])
    }

    const fetchData = async () => {
       
        const level0 = [];
        const level1 = [];
        const level2 = [];
        const level3 = [];
        const level4 = [];

        intializeLevel();
        let matrixPos = matrixPosition.data;

        if (matrixPos !== undefined && matrixPos !== null) {

            if (matrixPos.length > 0) {
                if (matrixPosition.rootData !== undefined && matrixPosition.rootData !== null) {

                    if (matrixPosition.rootData.length > 0) {
                        setRootVal(matrixPosition.rootData);
                        await matrixPosition.data[0].items.map((data) => {
                            if (data.positionDetails.level === 0) {
                                // here 2,3 number place
                                level0.push(data);
                                setLevelZero(level0)
                            }
                            else if (data.positionDetails.level === 1) {
                                // here 4,5,6,7 place
                                level1.push(data);
                                setLevelOne(level1)
                            }
                            else if (data.positionDetails.level === 2) {
                                // here 8,9....,15 place
                                level2.push(data)
                                setLevelTwo(level2)
                            }
                            else if (data.positionDetails.level === 3) {
                                // here 16,17.........,31 place
                                level3.push(data)
                                setLevelThree(level3)
                            }
                            else if (data.positionDetails.level === 4) {
                                // here 31,32.........,62 place
                                level4.push(data)
                                setLevelFour(level4)
                            }
                        });

                        setTempArr(matrixPos[0].items);
                        setPositionColor(matrixPosition.userPosition)
                    }
                }
            }
        }
    }

    const tempFunc = async () => {
        let arr1 = tempArr;
        let level0 = levelZero;
        let level1 = levelOne;
        let level2 = levelTwo;
        let level3 = levelThree;
        let level4 = levelFour;
        let tempLevel0 = [];
        let tempLevel1 = [];
        let tempLevel2 = [];
        let tempLevel3 = [];
        let tempLevel4 = [];

        if (arr1 !== undefined) {
            const newIndex = { val: 0 };
            const levelCheck = { level: 0 };
            const indexCheck = { indexLevel: 0, step: 0 };
            var arrayOfObject = await positionName.map(function (value, index) {

                if (index === 0) {

                }
                else {
                    if (levelCheck.level === 0) {

                        if (newIndex.val < level0.length) {
                            let prevIndex = newIndex.val;
                            newIndex.val++;
                            if (newIndex.val === level0.length) {
                                levelCheck.level = 1;
                                newIndex.val = 0;
                                tempLevel0.push([value, level0[prevIndex]])
                                return [value, level0[prevIndex]];
                            } else {
                                tempLevel0.push([value, level0[prevIndex]])
                                return [value, level0[prevIndex]];
                            }
                        }

                    }
                    else if (levelCheck.level === 1) {

                        if (newIndex.val <= 4) {
                            let prevIndex = newIndex.val;
                            newIndex.val++;
                            if (newIndex.val === 4) {
                                levelCheck.level = 2
                                newIndex.val = 0;
                                tempLevel1.push([value, level1[prevIndex]])
                                return [value, level1[prevIndex]];
                            }
                            else {
                                tempLevel1.push([value, level1[prevIndex]])
                                return [value, level1[prevIndex]];
                            }
                        }

                    }
                    else if (levelCheck.level === 2) {

                        if (newIndex.val <= 8) {
                            // let prevIndex = newIndex.val;
                            let assignedIndex;

                            if (level1[indexCheck.indexLevel] !== undefined) {

                                if (level1[indexCheck.indexLevel].positionDetails.downlineUsers.length !== 0) {
                                    assignedIndex = level2.findIndex((data) => { return data.positionDetails._id === level1[indexCheck.indexLevel].positionDetails.downlineUsers[indexCheck.step] })
                                    indexCheck.step++;
                                    if (indexCheck.step === 2) {
                                        indexCheck.indexLevel++;
                                        indexCheck.step = 0
                                    }
                                }
                                else {
                                    assignedIndex = undefined;
                                    indexCheck.step++;
                                    if (indexCheck.step === 2) {
                                        indexCheck.indexLevel++;
                                        indexCheck.step = 0
                                    }
                                }
                            }

                            if (assignedIndex !== undefined) {
                                newIndex.val++;
                                if (newIndex.val === 8) {
                                    levelCheck.level = 3
                                    newIndex.val = 0;
                                    indexCheck.indexLevel = 0;
                                    tempLevel2.push([value, level2[assignedIndex]])
                                    return [value, level2[assignedIndex]];
                                }
                                else {
                                    tempLevel2.push([value, level2[assignedIndex]])
                                    return [value, level2[assignedIndex]];
                                }
                            }
                            else {
                                newIndex.val++;
                                if (newIndex.val === 8) {
                                    levelCheck.level = 3
                                    newIndex.val = 0
                                    indexCheck.indexLevel = 0;
                                    tempLevel2.push([value, undefined])
                                    return [value, undefined]
                                }
                                else {
                                    tempLevel2.push([value, undefined])
                                    return [value, undefined]
                                }
                            }

                        }

                    }
                    else if (levelCheck.level === 3) {

                        let assignedIndex;
                        if (newIndex.val <= 16) {

                            // let prevIndex = newIndex.val;
                            if (tempLevel2[indexCheck.indexLevel] !== undefined && tempLevel2[indexCheck.indexLevel][1] !== undefined) {

                                if (tempLevel2[indexCheck.indexLevel][1].positionDetails.downlineUsers.length !== 0) {
                                    assignedIndex = level3.findIndex((data) => { return data.positionDetails._id === tempLevel2[indexCheck.indexLevel][1].positionDetails.downlineUsers[indexCheck.step] })
                                    indexCheck.step++;
                                    if (indexCheck.step === 2) {
                                        indexCheck.indexLevel++;
                                        indexCheck.step = 0
                                    }
                                }
                                else {
                                    assignedIndex = undefined;
                                    indexCheck.step++;
                                    if (indexCheck.step === 2) {
                                        indexCheck.indexLevel++;
                                        indexCheck.step = 0
                                    }
                                }
                            }
                            else {
                                assignedIndex = undefined;
                                indexCheck.step++;
                                if (indexCheck.step === 2) {
                                    indexCheck.indexLevel++;
                                    indexCheck.step = 0
                                }
                            }

                            if (assignedIndex !== undefined) {
                                newIndex.val++;
                                if (newIndex.val === 16) {
                                    levelCheck.level = 4
                                    newIndex.val = 0
                                    indexCheck.indexLevel = 0;
                                    tempLevel3.push([value, level3[assignedIndex]])
                                    return [value, level3[assignedIndex]]
                                }
                                else {
                                    tempLevel3.push([value, level3[assignedIndex]])
                                    return [value, level3[assignedIndex]];
                                }
                            }
                            else {
                                newIndex.val++;
                                if (newIndex.val === 16) {
                                    levelCheck.level = 4
                                    newIndex.val = 0
                                    indexCheck.indexLevel = 0;
                                    tempLevel3.push([value, undefined])
                                    return [value, undefined]
                                }
                                else {
                                    tempLevel3.push([value, undefined])
                                    return [value, undefined]
                                }
                            }
                        }

                    }
                    else if (levelCheck.level === 4) {

                        if (newIndex.val <= 32) {
                            // let prevIndex = newIndex.val;
                            let assignedIndex;


                            if (tempLevel3[indexCheck.indexLevel] !== undefined && tempLevel3[indexCheck.indexLevel][1] !== undefined) {
                                if (tempLevel3[indexCheck.indexLevel][1].positionDetails.downlineUsers.length !== 0) {
                                    assignedIndex = level4.findIndex((data) => {

                                        return data.positionDetails._id === tempLevel3[indexCheck.indexLevel][1].positionDetails.downlineUsers[indexCheck.step]
                                    })
                                    indexCheck.step++;
                                    if (indexCheck.step === 2) {
                                        indexCheck.indexLevel++;
                                        indexCheck.step = 0
                                    }
                                }
                                else {
                                    assignedIndex = undefined;
                                    indexCheck.step++;
                                    if (indexCheck.step === 2) {
                                        indexCheck.indexLevel++;
                                        indexCheck.step = 0
                                    }
                                }
                            }
                            else {
                                assignedIndex = undefined;
                                indexCheck.step++;
                                if (indexCheck.step === 2) {
                                    indexCheck.indexLevel++;
                                    indexCheck.step = 0
                                }
                            }


                            if (assignedIndex !== undefined) {

                                newIndex.val++;
                                if (newIndex.val === 32) {
                                    levelCheck.level = 5
                                    newIndex.val = 0
                                    tempLevel4.push([value, level4[assignedIndex]])
                                    return [value, level4[assignedIndex]];
                                } else {
                                    tempLevel4.push([value, level4[assignedIndex]])
                                    return [value, level4[assignedIndex]];
                                }

                            }
                            else {
                                newIndex.val++;
                                if (newIndex.val === 32) {
                                    levelCheck.level = 4
                                    newIndex.val = 0
                                    indexCheck.indexLevel = 0;
                                    tempLevel4.push([value, undefined])
                                    return [value, undefined]
                                }
                                else {
                                    tempLevel4.push([value, undefined])
                                    return [value, undefined];
                                }
                            }
                        }
                    }
                }
            });

            setArr(arrayOfObject)
        }
    }

    useEffect(() => {
        if (arr !== undefined && arr !== null && positionColor.positionNumber !== undefined) {
            let index = arr.findIndex((data, index) => {
                if (data !== undefined) {
                    if (data[1] !== undefined) {
                        if (data[1].positionDetails.positionNumber === positionColor.positionNumber) { return index }
                    }
                }
            })

            if (index !== -1) {
                setImgFlag(index + 1)
            }
            else {
                if (arr[1] !== undefined) {
                    if (arr[1][1].positionNumber === positionColor.positionNumber) {
                        setImgFlag(1)
                    } else {
                        setImgFlag('')
                    }
                }
            }
        }
    }, [arr])

    useEffect(() => {
        fetchData();
    }, [matrixPosition])

    useEffect(() => {
        tempFunc();
    }, [tempArr])

    const viewAll = (positionNo) => {
        let sessionPlan = sessionStorage.getItem('planType');
        let positionNoApiName = apiConstants.POSITION_ALL;
        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
            positionNoApiName = apiConstants.PLAN_POSITION_ALL;
        }
        if (location.pathname === '/genealogy') {
            previousBoard({ positionNumber: positionNo, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } })
            dispatch(genealogyActions.positionByNumber(positionNoApiName, { positionNumber: positionNo, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }));
        }
        else {
            history.push('/genealogy', { state: positionNo });
        }
    }

    const view = (positionNo) => {
        let sessionPlan = sessionStorage.getItem('planType');
        let positionNoApiName = apiConstants.POSITION_BY_NUMBER;
        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
            positionNoApiName = apiConstants.PLAN_POSITION_BY_NUMBER;
        }
        setPositionColor({ 'positionNumber': positionNo })      
        if (location.pathname === '/genealogy') {
            previousBoard({ positionNumber: positionNo, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } })
        }
        dispatch(genealogyActions.positionByNumber(positionNoApiName, { positionNumber: positionNo, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }));
    }


    return (
        // <>
           
            <div className="img-box-sec" key={rootVal !== undefined && rootVal !== null ? rootVal.length > 0 ? rootVal[0].positionNumber : 'one' : 'one'}>
                <div className="inner-img-sec diamond-img-scale" key={rootVal !== undefined && rootVal !== null ? rootVal.length > 0 ? rootVal[0].positionNumber : 'one' : 'one'}>
                    {/* <img src="./assets/images/Diamond.png" alt="diamond"/> */}
                    <img src={imgPath} alt="diamond" className='diamond-img-scale' key={rootVal !== undefined && rootVal !== null ? rootVal.length > 0 ? rootVal[0].positionNumber : 'one' : 'one'}/>
                </div>
                {
                    arr !== undefined && arr !== null ?
                        arr.length > 0 ?

                            arr.map((data, index) => {
                                if (data !== undefined || index === 0) {

                                    if (index === 0) {

                                        return (
                                            <div key={index} className={"tooltip-pos com-position one "
                                            }>
                                                {rootVal !== undefined && rootVal !== null ?
                                                    rootVal.length > 0 ?
                                                        <span className="tooltiptext">
                                                            <p className="mt-2 mb-2">Username: {rootVal[0] !== undefined && rootVal[0] !== null ?
                                                                rootVal[0].username : ''}</p>
                                                            {/* <p className="mt-2 mb-2">Contact:  {rootVal[0] !== undefined && rootVal[0] !== null ?
                                                                rootVal[0].contact : ''} </p> */}
                                                            <p className="mt-2 mb-2">Position Number: {rootVal[0] !== undefined && rootVal[0] !== null ?
                                                                rootVal[0].positionNumber : ''} </p>
                                                        </span>
                                                        : '' : ''}
                                                {/* {rootVal !== undefined && rootVal !== null ?
                                                    rootVal.length > 0 ? '1' : '' : ''} */}
                                                <div className={"diamond-text" + (rootVal !== undefined && rootVal.length > 0 ? rootVal[0] !== undefined ?
                                                    positionColor !== undefined ? positionColor.positionNumber === rootVal[0].positionNumber ? ' text-dark' :
                                                        userName.username.toLowerCase() === rootVal[0].username.toLowerCase() ?
                                                            ' bg-green' :
                                                            'no' : '' : '' : '')} >
                                                    {rootVal !== undefined && rootVal !== null ?
                                                        rootVal.length > 0 ? '1' : '' : ''}
                                                </div>
                                            </div>
                                        )
                                    }

                                    else {
                                        return (

                                            <div key={index} className={"tooltip-pos com-position " + data[0]
                                            }>
                                                {data[1] !== undefined && data[1] !== null ?

                                                    <span className="tooltiptext">
                                                        <p className="mt-2 mb-2">Username: {data[1] !== undefined && data[1] !== null ? data[1].userDetails[0] !== undefined && data[1].userDetails[0] !== null ? data[1].userDetails[0].username : '' : ''}</p>
                                                        {/* <p className="mt-2 mb-2">Contact:  {data[1] !== undefined && data[1] !== null ? data[1].userDetails[0] !== undefined && data[1].userDetails[0] !== null ? data[1].userDetails[0].contact : '' : ''} </p> */}
                                                        <p className="mt-2 mb-2">Position Number:  {data[1].positionDetails.positionNumber} </p>
                                                        {parseInt(data[1].positionDetails.colorCode) === 0 ? <button type="button" className="button-layout btn-outline-warning mr-2"
                                                            onClick={() => view(data[1].positionDetails.positionNumber)}>View</button> : ''}
                                                        {parseInt(data[1].positionDetails.colorCode) === 0 ? <button type="button" className="button-layout btn-outline-warning"
                                                            onClick={() => viewAll(data[1].positionDetails.positionNumber)}>View All</button> : ''}
                                                    </span>

                                                    : ''}
                                                <div className={"diamond-text " + (data && userName ? data[1] ?
                                                    positionColor ? positionColor.positionNumber ===
                                                        data[1].positionDetails.positionNumber ?
                                                        'text-dark col-' + data[0] :
                                                        userName.username.toLowerCase() === data[1].userDetails[0].username.toLowerCase() ?
                                                            ' bg-green col-' + data[0]
                                                            : ' no' : '' : '' : '')} >
                                                    {data[1] !== undefined && data[1] !== null ?
                                                        data[1].positionDetails !== undefined && data[1].positionDetails !== null ?
                                                            index + 1 : '' : ''} </div>

                                            </div>
                                        )

                                    }

                                }
                                else {
                                    return (<div></div>)
                                }
                            })
                            : ''
                        : ""
                }
                {/* <div className="com-position one">
                        1
                    </div>
                    <div className="com-position two">
                        2
                    </div>
                    <div className="com-position three">
                        3
                    </div>
                    <div className="com-position four">
                        4
                    </div>
                    <div className="com-position five">
                        5
                    </div>
                    <div className="com-position six">
                        6
                    </div>
                    <div className="com-position seven">
                        7
                    </div>
                    <div className="com-position eight">
                        8
                    </div>
                    <div className="com-position nine">
                        9
                    </div>
                    <div className="com-position ten">
                        10
                    </div>
                    <div className="com-position eleven">
                        11
                    </div>
                    <div className="com-position twelve">
                        12
                    </div>
                    <div className="com-position thirteen">
                        13
                    </div>
                    <div className="com-position fourteen">
                        14
                    </div>
                    <div className="com-position fifteen">
                        15
                    </div>
                    <div className="com-position sixteen">
                        16
                    </div>
                    <div className="com-position seventeen">
                        17
                    </div>
                    <div className="com-position eighteen">
                        18
                    </div>
                    <div className="com-position nineteen">
                        19
                    </div>
                    <div className="com-position twenty">
                        20
                    </div>
                    <div className="com-position twenty-one">
                        21
                    </div>
                    <div className="com-position twenty-two">
                        22
                    </div>
                    <div className="com-position twenty-three">
                        23
                    </div>
                    <div className="com-position twenty-four">
                        24
                    </div>
                    <div className="com-position twenty-five">
                        25
                    </div>
                    <div className="com-position twenty-six">
                        26
                    </div>
                    <div className="com-position twenty-seven">
                        27
                    </div>
                    <div className="com-position twenty-eight">
                        28
                    </div>
                    <div className="com-position twenty-nine">
                        29
                    </div>
                    <div className="com-position thirty">
                        30
                    </div>
                    <div className="com-position thirty-one">
                        31
                    </div>
                    <div className="com-position thirty-two">
                        32
                    </div>
                    <div className="com-position thirty-three">
                        33
                    </div>
                    <div className="com-position thirty-four">
                        34
                    </div>
                    <div className="com-position thirty-five">
                        35
                    </div>
                    <div className="com-position thirty-six">
                        36
                    </div>
                    <div className="com-position thirty-seven">
                        37
                    </div>
                    <div className="com-position thirty-eight">
                        38
                    </div>
                    <div className="com-position thirty-nine">
                        39
                    </div>
                    <div className="com-position forty">
                        40
                    </div>
                    <div className="com-position forty-one">
                        41
                    </div>
                    <div className="com-position forty-two">
                        42
                    </div>
                    <div className="com-position forty-three">
                        43
                    </div>
                    <div className="com-position forty-four">
                        44
                    </div>
                    <div className="com-position forty-five">
                        45
                    </div>
                    <div className="com-position forty-six">
                        46
                    </div>
                    <div className="com-position forty-seven">
                        47
                    </div>
                    <div className="com-position forty-eight">
                        48
                    </div>
                    <div className="com-position forty-nine">
                        49
                    </div>
                    <div className="com-position fifty">
                        50
                    </div>
                    <div className="com-position fifty-one">
                        51
                    </div>
                    <div className="com-position fifty-two">
                        52
                    </div>
                    <div className="com-position fifty-three">
                        53
                    </div>
                    <div className="com-position fifty-four">
                        54
                    </div>
                    <div className="com-position fifty-five">
                        55
                    </div>
                    <div className="com-position fifty-six">
                        56
                    </div>
                    <div className="com-position fifty-seven">
                        57
                    </div>
                    <div className="com-position fifty-eight">
                        58
                    </div>
                    <div className="com-position fifty-nine">
                        59
                    </div>
                    <div className="com-position sixty">
                        60
                    </div>
                    <div className="com-position sixty-one">
                        61
                    </div>
                    <div className="com-position sixty-two">
                        62
                    </div>
                    <div className="com-position sixty-three">
                        63
                    </div> */}



            </div>
           
        // </>
    )
}

export default DiamondStructure;