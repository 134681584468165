import { genealogyConstants } from '../constants';

export function getPendingPositionCount(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.GET_PENDING_POSITION_REQUEST:
            return {
                isGetPendingPositionCount: false
            };
        case genealogyConstants.GET_PENDING_POSITION_SUCCESS:
            return {
                isGetPendingPositionCount: true, getPendingPositionCount: action.successAction
            }
        case genealogyConstants.GET_PENDING_POSITION_FAILURE:
            return {
                isGetPendingPositionCount: false
            }
        default:
            return state
    }
}

export function getNewMember(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.GET_NEW_MEMBER_REQUEST:
            return {
                isGetNewMember: false
            };
        case genealogyConstants.GET_NEW_MEMBER_SUCCESS:
            return {
                isGetNewMember: true, getNewMember: action.successAction
            }
        case genealogyConstants.GET_NEW_MEMBER_FAILURE:
            return {
                isGetNewMember: false
            }
        default:
            return state
    }
}

export function getPositionView(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.GET_POSITION_VIEW_REQUEST:
            return {
                isGetPositionView: false
            };
        case genealogyConstants.GET_POSITION_VIEW_SUCCESS:
            return {
                isGetPositionView: true, getPositionView: action.successAction
            }
        case genealogyConstants.GET_POSITION_VIEW_FAILURE:
            return {
                isGetPositionView: false
            }
        case genealogyConstants.POSITION_RESTORE_DEFAULT:
                return {
                    isGetPositionView: false, getPositionView: false
                }
        default:
            return state
    }
}

export function getPurchasedPosition(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.GET_POSITION_PURCHASED_REQUEST:
            return {
                isGetPurchasedPosition: false
            };
        case genealogyConstants.GET_POSITION_PURCHASED_SUCCESS:
            return {
                isGetPurchasedPosition: true, getPurchasedPosition: action.successAction
            }
        case genealogyConstants.GET_POSITION_PURCHASED_FAILURE:
            return {
                isGetPurchasedPosition: false
            }
        default:
            return state
    }
}

export function getReEntryPosition(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.GET_RE_ENTRY_POSITION_REQUEST:
            return {
                isGetReEntryPosition: false
            };
        case genealogyConstants.GET_RE_ENTRY_POSITION_SUCCESS:
            return {
                isGetReEntryPosition: true, getReEntryPosition: action.successAction
            }
        case genealogyConstants.GET_RE_ENTRY_POSITION_FAILURE:
            return {
                isGetReEntryPosition: false
            }
        default:
            return state
    }
}

export function addPosition(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.ADD_POSITION_REQUEST:
            return {
                isPositionAdded: false
            };
        case genealogyConstants.ADD_POSITION_SUCCESS:
            return {
                isPositionAdded: true, positionAdded: true
            }
        case genealogyConstants.ADD_POSITION_FAILURE:
            return {
                isPositionAdded: false, positionAdded: false
            }
        case genealogyConstants.ADD_POSITION_RESTORE_DEFAULT:
            return {
                isPositionAdded: false, positionAdded: false
            }
        default:
            return state
    }
}

export function getEarnedIncome(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.GET_EARNED_INCOME_REQUEST:
            return {
                isgetEarnedIncome: false
            };
        case genealogyConstants.GET_EARNED_INCOME_SUCCESS:
            return {
                isgetEarnedIncome: true, getEarnedIncome: action.successAction
            }
        case genealogyConstants.GET_EARNED_INCOME_FAILURE:
            return {
                isgetEarnedIncome: false, getEarnedIncome: false
            }
        case genealogyConstants.GET_EARNED_INCOME_DEFAULT:
            return {
                isgetEarnedIncome: false, getEarnedIncome: false
            }
        default:
            return state
    }
}

export function positionByNumber(state = {}, action) {
    switch (action.type) {
        case genealogyConstants.POSITION_BY_NUMBER_REQUEST:
            return {
                isPositionByNumber: false
            };
        case genealogyConstants.POSITION_BY_NUMBER_SUCCESS:
            return {
                isPositionByNumber: true, positionByNumber: action.successAction
            }
        case genealogyConstants.POSITION_BY_NUMBER_FAILURE:
            return {
                isPositionByNumber: false
            }
        case genealogyConstants.POSITION_RESTORE_DEFAULT:
                return {
                    isPositionByNumber: false, positionByNumber: false
                }
        default:
            return state
    }
}
