import { planConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";

export const planActions = {
  getPlan
}

function getPlan(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(planConstants.GET_PLAN_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(planConstants.GET_PLAN_SUCCESS, doc));
      },
      error => {
        dispatch(commonAction.failure(planConstants.GET_PLAN_FAILURE, error));
      }
    );
  }
}