import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import { walletAction } from '../../../actions';
import { apiConstants } from '../../../constants';

export default function WalletConditionPopup({ onSelect }) {
    const dispatch = useDispatch();
    const [siteConstants, setSiteConstants] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        onSelect(val)
    }

    const formik = useFormik({
        initialValues: {
            password: ""
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .required("Please Enter Password")
        }),
        onSubmit: values => {
            setLoading(true);
            let obj = {
                password: values.password
            }
            dispatch(walletAction.updateWalletKey(apiConstants.UPDATE_WALLET_KEY, obj));
            handleSubmit('No')
        }
    });

    return (
        <Modal show={true} onHide={() => handleSubmit('No')} size='xxl' className='' dialogClassName="modal-70w" animation={true}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_UPDATE_WALLET} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div>
                        <div className="form-row justify-content-between">
                        
                            <div className="form-group col-lg-12 col-md-12">
                                <label>{siteConstants.LABEL_WALLET + " " + siteConstants.LABEL_PASSWORD}</label>
                                <input type="text" className="form-control" name="password"
                                    value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <label className="text-danger">
                                    {formik.errors.password && formik.touched.password ?
                                        formik.errors.password : ''
                                    }
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='text-center'>
                        {
                            !isLoading ?
                                <button type="submit" className="btn btn-primary">Submit</button> :
                                <button type="button" className="btn btn-new blue-layout" disabled={isLoading}>
                                    <i className="fas fa-spinner fa-spin"></i></button>
                        }
                        <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
                </Modal.Footer>
                    <div className="text-center p-2"><small style={{'textDecoration':'underline'}} className="text-primary f-15 text-decoration-underline" onClick={() => handleSubmit('recover')}>
                        Have mnemonic or 12 word phrase ? Click here to recover and update your wallet</small></div>
            </form>
            <p className='text-secondary f-12 p-2'>Please feel free to reach out to us if need further assistance 
                <b className='text-info'>{" "+process.env.REACT_APP_FRONTEND_SUPPORT_EMAIL}</b></p>
        </Modal>
    )
}
