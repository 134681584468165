import React, { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import { history } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { userActions, menuActions } from '../../../actions';
import { apiConstants } from '../../../constants';
import TermsAndConditionPopup from '../Popup/TermsAndConditionPopup';

export default function Footer() {
  const dispatch = useDispatch();
  const [link, setLink] = useState('');
  const [termsPopup, setTermsPopup] = useState(false);
  const [menuTitle, setMenuTitle] = useState([]);
  const { getSetting, getMenu } = useSelector(state => ({
    getSetting: state.getSetting,
    getMenu: state.getMenu
  }));

  useEffect(() => {
    dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
    dispatch(menuActions.getMenu(apiConstants.GET_MENU));
  }, []);

  useEffect(() => {
    if (Object.keys(getSetting).length > 0) {
      if (getSetting.isGetSetting) {
        if (getSetting.getSetting.data) {

          const { data } = getSetting.getSetting;

          setLink({
            facebookLink: data.data.facebookLink !== null && data.data.facebookLink !== '' ? data.data.facebookLink : '#',
            telegramLink: data.data.telegramLink !== null && data.data.telegramLink !== '' ? data.data.telegramLink : '#',
            instagramLink: data.data.instagramLink !== null && data.data.instagramLink !== '' ? data.data.instagramLink : '#',
            linkedInLink: data.data.linkedInLink !== null && data.data.linkedInLink !== '' ? data.data.linkedInLink : '#',
            twitterLink: data.data.twitterLink !== null && data.data.twitterLink !== '' ? data.data.twitterLink : '#',
            tiktokLink: data.data.tiktokLink !== null && data.data.tiktokLink !== '' ? data.data.tiktokLink : '#',
          })
        }
      }
    }
    if (Object.keys(getMenu).length > 0) {
      if (getMenu.getMenu !== undefined) {
        const { data } = getMenu.getMenu;
        setMenuTitle(data.data)
      }
    }
  }, [getSetting, getMenu])

  const selectedTermsRes = (val) => {

    if (val === 'Yes') {
      setTermsPopup(false);
    }
    else {
      setTermsPopup(false)
    }
  }

  return (
    <>
      <footer className="bg-dark-blue">
        <div className="foter-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="foter-logo">
                  <Nav.Link onClick={() => history.push('/')}>
                    <img src="/assets/images/logo1.png" alt="logo" />
                  </Nav.Link>
                </div>
                <p className="mb-0">
                  Crowdfunding is a practice of funding a project or venture by raising money from several people who each contribute a
                  small amount as gifts or donations. Individuals, charities, or companies can benefit from the platform to receive donations
                  or gifts for their specific needs or causes.
                </p>
              </div>
              <div className="col-lg-7 mt-4 mt-lg-0">
                <div className="footer-menu-sec d-flex flex-wrap justify-content-between">
                  <div className="foter-links">
                    <h4>Quick Links</h4>
                    <Nav as="ul" className="list-unstyled footer-section mb-0">
                      {menuTitle && menuTitle.length > 0 ?
                        menuTitle.map((x, index) => {
                          return (<a key={index} className='text-capitalize text-light font-weight-bold m-2'
                            href={"/" + x.menuName.toLowerCase() + '-page' + '/' + x.menuName.toLowerCase()}>{x.menuName}</a>)
                        })
                        : ''}
                      {/* <Nav.Item as="li"><Nav.Link onClick={() => history.push('/')}>Home</Nav.Link></Nav.Item>
                    <Nav.Item as="li"><Nav.Link onClick={() => history.push('/about-us')}>About us</Nav.Link></Nav.Item>
                    <Nav.Item as="li"><Nav.Link onClick={() => history.push('/contact-us')}>Contact us</Nav.Link></Nav.Item> */}
                    </Nav>
                  </div>
                  <div className="foter-links">
                    <h4>Resources</h4>
                    <Nav as="ul" className="list-unstyled footer-section mb-0">
                      <Nav.Item as="li"><Nav.Link onClick={() => history.push('/faq')}>FAQ</Nav.Link></Nav.Item>
                    </Nav>
                  </div>
                  <div className="foter-links last-box">
                    <h4>Policies</h4>
                    <Nav as="ul" className="list-unstyled footer-section mb-0">
                      <Nav.Item as="li"><Nav.Link onClick={() => { setTermsPopup(true) }}>Terms of use</Nav.Link></Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap text-center">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="copy-right-sec">
                <p className="copy-footer">Copyright © . All Rights Reserved DiamondJ7</p>
              </div>
              <div className="social-icons-sec">
                <div className="d-flex flex-wrap">
                  <Nav as="ul" className="list-unstyled social-media d-flex mb-0">
                    <Nav.Item as="li"><a target='blank' href={link.facebookLink ? link.facebookLink : '#'}><i className="fab fa-facebook-f"></i></a></Nav.Item>
                    <Nav.Item as="li"><a target='blank' href={link.twitterLink ? link.twitterLink : '#'}><i className="fab fa-twitter"></i></a></Nav.Item>
                    <Nav.Item as="li"><a target='blank' href={link.instagramLink ? link.instagramLink : '#'}><i className="fab fa-instagram"></i></a></Nav.Item>
                    <Nav.Item as="li"><a target='blank' href={link.linkedInLink ? link.linkedInLink : '#'}><i className="fab fa-linkedin"></i></a></Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>

          </div>
        </div>
      </footer>
      {termsPopup ? <TermsAndConditionPopup onSelect={selectedTermsRes} /> : ''}
    </>

  )
}
