import { globalPoolUserConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "../actions";

export const globalPoolUserActions = {
  getPoolUser,
  getAvailablePlan,
  activateGlobalUser,
  updatePoolSetting,
  getPoolRequest,
  requestPoolUser,
  forReferalPurchase
}

function getPoolUser(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.GET_POOL_USER_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.GET_POOL_USER_SUCCESS, doc));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.GET_POOL_USER_FAILURE, error));
      }
    );
  }
}

function getAvailablePlan(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.GET_AVAILABLE_PLAN_REQUEST, eventData));
    commonService.getDataWithToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.GET_AVAILABLE_PLAN_SUCCESS, doc));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.GET_AVAILABLE_PLAN_FAILURE, error));
      }
    );
  }
}

function activateGlobalUser(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.ACTIVATE_GLOBAL_USER_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.ACTIVATE_GLOBAL_USER_SUCCESS, doc));
        dispatch(alertActions.success(doc.data && doc.data.msg ? doc.data.msg : 'Position request added '));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.ACTIVATE_GLOBAL_USER_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}

function updatePoolSetting(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.UPDATE_POOL_SETTING_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.UPDATE_POOL_SETTING_SUCCESS, doc));
        dispatch(alertActions.success('Setting updated'));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.UPDATE_POOL_SETTING_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}

function getPoolRequest(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.GET_POOL_REQUEST_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.GET_POOL_REQUEST_SUCCESS, doc));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.GET_POOL_REQUEST_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}

function requestPoolUser(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.SEND_POOL_REQUEST_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.SEND_POOL_REQUEST_SUCCESS, doc));
        dispatch(alertActions.success(`Request ${eventData.requestUser !== "request" ? eventData.requestUser : 'send'} successfully`));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.SEND_POOL_REQUEST_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}

function forReferalPurchase(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(globalPoolUserConstants.REFERAL_PURCHASE_REQUEST, eventData));
    commonService.withToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(globalPoolUserConstants.REFERAL_PURCHASE_SUCCESS, doc));
        dispatch(alertActions.success(`Request added for the referal user ${eventData.referalUser.username}`));
      },
      error => {
        dispatch(commonAction.failure(globalPoolUserConstants.REFERAL_PURCHASE_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}
