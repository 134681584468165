import { advertismentConstants } from '../constants';

export function addAdvertisment(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.ADD_ADVERTISMENT_REQUEST:
            return {
                isAddAdvertisment: false
            };
        case advertismentConstants.ADD_ADVERTISMENT_SUCCESS:
            return {
                isAddAdvertisment: true, addAdvertisment: action.successAction
            }
        case advertismentConstants.ADD_ADVERTISMENT_FAILURE:
            return {
                isAddAdvertisment: false, addAdvertisment: false
            }
        default:
            return state
    }
}

export function getAdSetting(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_ADVERTISMENT_SETTING_REQUEST:
            return {
                isGetAdSetting: false
            };
        case advertismentConstants.GET_ADVERTISMENT_SETTING_SUCCESS:
            return {
                isGetAdSetting: true, getAdSetting: action.successAction
            }
        case advertismentConstants.GET_ADVERTISMENT_SETTING_FAILURE:
            return {
                isGetAdSetting: false
            }
        default:
            return state
    }
}

export function getFooterRandomBanner(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_FOOTER_ADVERTISMENT_REQUEST:
            return {
                isGetFooterRandomBanner: false
            };
        case advertismentConstants.GET_FOOTER_ADVERTISMENT_SUCCESS:
            return {
                isGetFooterRandomBanner: true, getFooterRandomBanner: action.successAction
            }
        case advertismentConstants.GET_FOOTER_ADVERTISMENT_FAILURE:
            return {
                isGetFooterRandomBanner: false
            }
        default:
            return state
    }
}

export function getBannerFooterRandomBanner(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_FOOTER_BANNER_ADVERTISMENT_REQUEST:
            return {
                isGetBannerFooterRandomBanner: false
            };
        case advertismentConstants.GET_FOOTER_BANNER_ADVERTISMENT_SUCCESS:
            return {
                isGetBannerFooterRandomBanner: true, getBannerFooterRandomBanner: action.successAction
            }
        case advertismentConstants.GET_FOOTER_BANNER_ADVERTISMENT_FAILURE:
            return {
                isGetBannerFooterRandomBanner: false
            }
        default:
            return state
    }
}

export function getAdvertismentList(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_ADVERTISMENT_REQUEST:
            return {
                isGetAdvertismentList: false
            };
        case advertismentConstants.GET_ADVERTISMENT_SUCCESS:
            return {
                isGetAdvertismentList: true, getAdvertismentList: action.successAction
            }
        case advertismentConstants.GET_ADVERTISMENT_FAILURE:
            return {
                isGetAdvertismentList: false
            }
        default:
            return state
    }
}


export function getRandomAdvertismentList(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_RANDOM_ADVERTISMENT_REQUEST:
            return {
                isGetRandomAdvertismentList: false
            };
        case advertismentConstants.GET_RANDOM_ADVERTISMENT_SUCCESS:
            return {
                isGetRanomAdvertismentList: true, getRandomAdvertismentList: action.successAction
            }
        case advertismentConstants.GET_RANDOM_ADVERTISMENT_FAILURE:
            return {
                isGetRandomAdvertismentList: false
            }
        default:
            return state
    }
}

// get text ads random for home screen
export function getTextRandomAdvertismentList(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_TEXT_RANDOM_ADVERTISMENT_REQUEST:
            return {
                isGetTextRandomAdvertismentList: false
            };

        case advertismentConstants.GET_TEXT_RANDOM_ADVERTISMENT_SUCCESS:
            return { isGetTextRandomAdvertismentList: true, getTextRandomAdvertismentList: action.successAction };

        case advertismentConstants.GET_TEXT_RANDOM_ADVERTISMENT_FAILURE:
            return {
                isGetTextRandomAdvertismentList: false,
            };

        default:
            return state
    }
}

export function getCreditList(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_CREDIT_REQUEST:
            return {
                isGetCreditList: false
            };
        case advertismentConstants.GET_CREDIT_SUCCESS:
            return {
                isGetCreditList: true, getCreditList: action.successAction
            }
        case advertismentConstants.GET_CREDIT_FAILURE:
            return {
                isGetCreditList: false
            }
        default:
            return state
    }
}

// get Promo banner listing
export function getPromoBannerListing(state = {}, action) {
    switch (action.type) {
        case advertismentConstants.GET_PROMO_BANNER_REQUEST:
            return {
                isGetPromoBannerListing: false
            };

        case advertismentConstants.GET_PROMO_BANNER_SUCCESS:
            return { isGetPromoBannerListing: true, getPromoBannerListing: action.successAction };

        case advertismentConstants.GET_PROMO_BANNER_FAILURE:
            return {
                isGetPromoBannerListing: false,
            };

        default:
            return state
    }
}