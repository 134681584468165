import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, userActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
var speakeasy = require("speakeasy");

const Profile = () => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [country, setCountry] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [codeList, setCodeList] = useState([]);
    const [profileImg, setProfileImg] = useState('');
    const [formData, setFormData] = useState();
    const [Otp, setOtp] = useState('');
    const [show, setShow] = useState(false);
    const [flag, setFlag] = useState(false);
    const [profileUserName, setProfileUserName] = useState('')
    const rejectedDomain = ["mailinator.com"];
    const [isLoading, setLoading] = useState({ 'submitLoader': false, 'verifyLoader': false });

    const handleClose = () => setShow(false);
    const { alert, language, sendTokenEmail, getCountryList, getProfile, userOtp } = useSelector(
        state => ({
            alert: state.alert,
            sendTokenEmail: state.sendTokenEmail,
            language: state.language,
            getCountryList: state.getCountryList,
            getProfile: state.getProfile,
            userOtp: state.userOtp
        })
    );

    const colourStyles = {
        option: ({ isDisabled }) => {
            return { cursor: isDisabled ? 'not-allowed' : 'default' }
        }
    }

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            countryCode: "",
            contact: "",
            country: "",
            email: "",
            otp: false
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string()
                .required("Please Enter first name!"),
            countryCode: Yup.string()
                .required("Please Select Country Code!"),
            contact: Yup.string()
                .required("Please Enter Contact"),
            email: Yup.string()
                .required("Please Enter Email")
                .email('Must be a valid email')
                .test('email', 'Domain not accepted',
                    function (value) {
                        if (value !== undefined && value !== null) {
                            var splitArray = value.split('@');
                            if (rejectedDomain.indexOf(splitArray[1]) >= 0) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    })
        }),

        onSubmit: values => {
            let obj = {
                firstname: values.firstname,
                lastname: values.lastname,
                countryCode: values.countryCode,
                contact: values.contact,
                country: values.country,
                email: values.email,
                otp: values.otp
            }
            setFormData(obj);
            setFlag(true)
            // dispatch(userActions.userProfile(apiConstants.GET_PROFILE, obj))
            let emailObj = { 'email': values.email }
            dispatch(userActions.sendTokenEmail(apiConstants.VERIFY_OTP_EMAIL, emailObj));
        }
    });

    useEffect(() => {
        if (Object.keys(sendTokenEmail).length > 0) {
            if (flag) {
                if (sendTokenEmail.isOtpSend)
                    if (sendTokenEmail.otpSend.data !== undefined) {
                        setShow(true);
                        localStorage.setItem('profileKey', sendTokenEmail.otpSend.data.data.key);
                    }
            }
        }
    }, [sendTokenEmail])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    useEffect(() => {
        if (Object.keys(getCountryList).length > 0) {
            if (getCountryList.getCountryList !== undefined) {
                const { data } = getCountryList.getCountryList;
                const list = data.data
                let arr = [];
                let arr1 = [];
                list.map(item => {
                    return arr.push({ 'value': item.code, 'label': item.code })
                })
                list.map(item => {
                    return arr1.push({ 'value': item.country, 'label': item.country, 'code': item.code })
                })
                setCodeList(arr)
                setCountryList(arr1);
            }
        }
    }, [getCountryList]);

    useEffect(() => {
        if (Object.keys(getProfile).length > 0) {

            if (getProfile.isGetProfile) {
                let arr = [];
                let contactData = '';
                let country_code = '';
                const { data } = getProfile.getProfile;
                if (data.data.contact !== null && data.data.contact !== undefined) {
                    arr = data.data.contact.split(" ");
                    contactData = arr[arr.length - 1]
                    arr.pop();
                    country_code = arr.join(' ')
                }
                formik.values.firstname = data.data.firstname !== null ? data.data.firstname : '';
                formik.values.lastname = data.data.lastname !== null ? data.data.lastname : '';
                formik.values.contact = contactData !== '' && contactData !== null ? contactData : '';
                formik.values.countryCode = country_code !== '' && country_code !== null ? country_code : '';
                formik.values.email = data.data.email !== null ? data.data.email : '';
                formik.values.otp = data.data.otp ? data.data.otp : false;
                formik.values.country = data.data.country !== null ? data.data.country : ''

                if (data.data.profile_image !== undefined && data.data.profile_image !== null) {
                    let val = JSON.parse(sessionStorage.getItem('user'));

                    if (val !== undefined && val !== null) {
                        val.profile = data.data.profile_image;
                        sessionStorage.setItem('user', JSON.stringify(val))
                    }
                    setProfileImg(process.env.REACT_APP_BACKEND_URL + "/" + data.data.profile_image);
                }
                setCountryCode({ 'value': formik.values.countryCode, 'label': formik.values.countryCode });
                setCountry({ 'value': formik.values.country, 'label': formik.values.country })
                setOtp({ 'value': formik.values.otp, 'label': formik.values.otp })
                setProfileUserName(data.data.username)
            }
        }
    }, [getProfile])

    useEffect(() => {
        localStorage.removeItem('profileKey')
        dispatch(alertActions.clear());
        dispatch(userActions.getCountryList(apiConstants.GET_COUNTRY_CODE));
        dispatch(userActions.getProfle(apiConstants.GET_PROFILE))
        return () => {
            setAlertRes('')
            setShow(false)
        }
    }, []);

    useEffect(() => {
        if (Object.keys(alert).length > 0) {

            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                setLoading({ 'submitLoader': false, 'verifyLoader': false })
                if (alert.message.errors !== undefined) {
                    if (typeof alert.message.errors == "string") {
                        tempErr.push({ 'type': alert.type, message: alert.message.errors })
                    }
                    else {
                        let keys = Object.keys(alert.message.errors)
                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                            })
                        }
                        else {
                            tempErr = [{ 'type': alert.type, message: alert.message }]
                        }
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }
            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);
        }
    }, [alert]);

    const selectHandler = (e) => {
        setCountryCode({ 'value': e.code, 'label': e.code });
        setCountry(e)
        formik.values.countryCode = e.code;
        formik.values.country = e.value
        return true;
    }

    const handleProfileChange = () => {
        document.querySelector("input[type='file']").click();
    }

    const handleImageChange = (e) => {

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            // do something with file...
        };

        reader.readAsDataURL(file);

        if (file) {
            dispatch(userActions.uploadProfileImg(apiConstants.UPLOAD_IMAGE, { 'files': file }));
        }
    }

    const onVerify = () => {

        let key = localStorage.getItem('profileKey');

        var tokenValidates = speakeasy.totp.verify({
            secret: key,
            encoding: "ascii",
            token: formData.verificationCode.trim(),
            window: 1,
            step: 300
        });
        // console.log(tokenValidates)
        if (tokenValidates) {
            setShow(false);
            setLoading({ verifyLoader: true })
            if (formData !== undefined && formData !== null) {
                dispatch(userActions.userProfile(apiConstants.GET_PROFILE, formData))
            }
        }
        else {
            setAlertRes([{ 'type': 'alert-error', 'message': 'Code Expires...' }])
        }
    }

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {
                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'} className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}
                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>

                                <Card>
                                    <Card.Body>
                                        <Card.Title bg="light">Profile</Card.Title>
                                        {/* <Card.Text> */}
                                        <section className="User-details-sec">
                                            <div className="top-use-details" style={{ 'padding': '5px' }}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="image-container">
                                                                <img alt='profile' className="header-profile-icon" src={profileImg !== '' ? profileImg : "/assets/images/user.png"} />
                                                                <div className="after"><i className="fas fa-edit" onClick={() => handleProfileChange()}></i></div>
                                                                <input type="file" name="userImage"
                                                                    onChange={(e) => handleImageChange(e)}
                                                                    style={{ display: "none" }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-9">
                                                            <div className="admin-form">
                                                                <form onSubmit={formik.handleSubmit}>
                                                                    <div className="form-row justify-content-between">
                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <h2 className="heading-top mt-4">User Name :  {profileUserName}</h2>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row justify-content-between">
                                                                        <div className="form-group col-lg-5 col-md-6">
                                                                            <label>{siteConstants.LABEL_FIRST_NAME}</label>
                                                                            <input type="text" className="form-control" name="firstname" disabled={true}
                                                                                value={formik.values.firstname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                            <label className="text-danger">
                                                                                {formik.errors.firstname && formik.touched.firstname ?
                                                                                    formik.errors.firstname : ''
                                                                                }
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-5 col-md-6">
                                                                            <label>{siteConstants.LABEL_LAST_NAME}</label>
                                                                            <input type="text" className="form-control" name="lastname" disabled={true}
                                                                                value={formik.values.lastname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row justify-content-between">
                                                                        <div className="form-group col-lg-5 col-md-6">
                                                                            <label>{siteConstants.LABEL_COUNTRY}</label>
                                                                            <Select
                                                                                name="country"
                                                                                placeholder="country"
                                                                                onChange={(e) => selectHandler(e)}
                                                                                options={countryList ? countryList : []}
                                                                                styles={colourStyles}
                                                                                onBlur={formik.handleBlur}
                                                                                classNamePrefix="select-layout"
                                                                                value={country}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-lg-5 col-md-12">
                                                                            <div className="row">
                                                                                <div className="col-lg-5">
                                                                                    <label>{siteConstants.LABEL_COUNTRY_CODE}</label>
                                                                                    <Select
                                                                                        name="countryCode"
                                                                                        placeholder="+1"
                                                                                        onChange={(e) => selectHandler(e)}
                                                                                        options={codeList ? codeList : []}
                                                                                        styles={colourStyles}
                                                                                        onBlur={formik.handleBlur}
                                                                                        classNamePrefix="select-layout"
                                                                                        value={countryCode}
                                                                                        isDisabled={true}
                                                                                    />
                                                                                    <label className="text-danger">
                                                                                        {formik.errors.countryCode && formik.touched.countryCode ?
                                                                                            formik.errors.countryCode : ''
                                                                                        }
                                                                                    </label>
                                                                                </div>

                                                                                <div className="col-lg-7">
                                                                                    <label>{siteConstants.LABEL_CONTACT}</label>
                                                                                    <input type="text" className="form-control"
                                                                                        value={formik.values.contact}
                                                                                        name="contact"
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur} />
                                                                                    <label className="text-danger">
                                                                                        {formik.errors.contact && formik.touched.contact ?
                                                                                            formik.errors.contact : ''
                                                                                        }
                                                                                    </label>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="form-row justify-content-between">
                                                                        <div className="form-group col-lg-5 col-md-6">
                                                                            <label>{siteConstants.LABEL_EMAIL}</label>
                                                                            <input type="text" className="form-control" name="email"
                                                                                value={formik.values.email}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur} />

                                                                            <label className="text-danger" style={{ 'display': 'block' }}>
                                                                                {formik.errors.email && formik.touched.email ?
                                                                                    formik.errors.email : ''
                                                                                }
                                                                            </label>
                                                                            <small className="font-gray">If change then otp send on new e-mail address</small>
                                                                        </div>

                                                                        <div className='form-group col-lg-5 col-md-12'>
                                                                            <div className="">
                                                                                <div className='checkboxx'>
                                                                                    <input type="checkbox"
                                                                                        name="otp"
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        checked={formik.values.otp}
                                                                                    />
                                                                                    <label htmlFor="OTP" className='ml-2'> Disable OTP</label><br />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        !isLoading.submitLoader ?
                                                                            <button type="submit" className="btn btn-primary">Submit</button> :
                                                                            <button type="button" className="btn btn-new blue-layout" disabled={isLoading.submitLoader}>
                                                                                <i className="fas fa-spinner fa-spin"></i></button>
                                                                    }
                                                                </form>
                                                                <p className="mt-5">* A Profile update code will be sent to confirm changes</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {/* </Card.Text> */}

                                    </Card.Body>
                                </Card>
                            </div>
                            {/* Banner Scetion */}
                            <div className='col-md-12'>
                                <BannerRandomFooter />
                            </div>
                        </div>
                        <Footer />
                    </div>

                </div>
                <Modal show={show} onHide={() => handleClose()} size="xxl" animation={false}>
                    <Modal.Header closeButton>
                        <div>
                            <Modal.Title>{siteConstants.PROFILE_UPDATE_CODE}</Modal.Title>
                            <small className="font-gray mt-2">We have sent an OTP to your given email address. It will expire in 5 minutes.</small>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="modal-input form-group">
                            <input type="text" className="form-control" id="verificationCode" onChange={(e) => handleChange(e)}
                                placeholder={siteConstants.ENTER_VERIFICATION_CODE + ":"} name="verificationCode" />
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary btn-gray" onClick={() => handleClose()}>{siteConstants.LABEL_CLOSE}</Button>
                        <Button variant="primary" onClick={() => onVerify()}>{siteConstants.BTN_SAVE}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default Profile;
