export const walletConstants = {

    ADD_WALLET_REQUEST: 'ADD_WALLET_REQUEST',
    ADD_WALLET_SUCCESS: 'ADD_WALLET_SUCCESS',
    ADD_WALLET_FAILURE: 'ADD_WALLET_FAILURE',

    SEND_MONEY_REQUEST: 'SEND_MONEY_REQUEST',
    SEND_MONEY_SUCCESS: 'SEND_MONEY_SUCCESS',
    SEND_MONEY_FAILURE: 'SEND_MONEY_FAILURE',
    SEND_MONEY_RESTORE_DEFAULT: 'SEND_MONEY_RESTORE_DEFAULT',

    GET_TRANSACTION_REQUEST: 'GET_TRANSACTION_REQUEST',
    GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILURE: 'GET_TRANSACTION_FAILURE',

    GET_WALLET_REQUEST: 'GET_WALLET_REQUEST',
    GET_WALLET_SUCCESS: 'GET_WALLET_SUCCESS',
    GET_WALLET_FAILURE: 'GET_WALLET_FAILURE',

    GET_WALLET_BALANCE_REQUEST: 'GET_WALLET_BALANCE_REQUEST',
    GET_WALLET_BALANCE_SUCCESS: 'GET_WALLET_BALANCE_SUCCESS',
    GET_WALLET_BALANCE_FAILURE: 'GET_WALLET_BALANCE_FAILURE',

    GET_WALLET_USER_REQUEST: 'GET_WALLET_USER_REQUEST',
    GET_WALLET_USER_SUCCESS: 'GET_WALLET_USER_SUCCESS',
    GET_WALLET_USER_FAILURE: 'GET_WALLET_USER_FAILURE',

    WITHDRAW_MONEY_REQUEST: 'WITHDRAW_MONEY_REQUEST',
    WITHDRAW_MONEY_SUCCESS: 'WITHDRAW_MONEY_SUCCESS',
    WITHDRAW_MONEY_FAILURE: 'WITHDRAW_MONEY_FAILURE',

    RECOVER_WALLET_REQUEST: 'RECOVER_WALLET_REQUEST',
    RECOVER_WALLET_SUCCESS: 'RECOVER_WALLET_SUCCESS',
    RECOVER_WALLET_FAILURE: 'RECOVER_WALLET_FAILURE',

    GET_WITHDRAW_TRANSACTION_REQUEST: 'GET_WITHDRAW_TRANSACTION_REQUEST',
    GET_WITHDRAW_TRANSACTION_SUCCESS: 'GET_WITHDRAW_TRANSACTION_SUCCESS',
    GET_WITHDRAW_TRANSACTION_FAILURE: 'GET_WITHDRAW_TRANSACTION_FAILURE',

    UPDATE_WALLET_KEY_REQUEST: 'UPDATE_WALLET_KEY_REQUEST',
    UPDATE_WALLET_KEY_SUCCESS: 'UPDATE_WALLET_KEY_SUCCESS',
    UPDATE_WALLET_KEY_FAILURE: 'UPDATE_WALLET_KEY_FAILURE'
}