import React from 'react';

const CookiesNotice = () => {
 
    return (
        <>

            <section>
                <div className="container">
                    <div className="top-about-box-sec d-flex flex-wrap align-items-center">
                        <div>
                            <div className="heading-box text-center f-10 scroll-layout" style={{'height':'70vh'}}>
                                <p className='f-12'>Diamondj7.com uses cookies to provide necessary website functionality, improve your
                                    experience, and analyze our traffic. By continuing to access the site, you agree to our
                                    Privacy Policy and our Cookies usage.
                                   </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    );
}

export default CookiesNotice;