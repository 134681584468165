import { pushNotificationConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService  } from "../services";
import { alertActions } from "./";

export const pushNotificationActions = {
    getPushNotifictaionList,
    getPushNotificationCount,
    readPushNotifictaion,
    hidePushNotifictaion,
    getPushNotificationById,
    archivePushNotifictaion
}

function archivePushNotifictaion(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(pushNotificationConstants.ARCHIVE_PUSH_NOTIFICATION_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(pushNotificationConstants.ARCHIVE_PUSH_NOTIFICATION_SUCCESS, partner));
            },
            error => {
                dispatch(commonAction.failure(pushNotificationConstants.ARCHIVE_PUSH_NOTIFICATION_FAILURE, error));
            }
        );
    }
}

function getPushNotifictaionList(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(pushNotificationConstants.GET_PUSH_NOTIFICATION_LIST_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(pushNotificationConstants.GET_PUSH_NOTIFICATION_LIST_SUCCESS, partner));
            },
            error => {
                dispatch(commonAction.failure(pushNotificationConstants.GET_PUSH_NOTIFICATION_LIST_FAILURE, error));
            }
        );
    }
}

function getPushNotificationCount(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(pushNotificationConstants.GET_PUSH_NOTIFICATION_COUNT_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(pushNotificationConstants.GET_PUSH_NOTIFICATION_COUNT_SUCCESS, partner));
            },
            error => {
                dispatch(commonAction.failure(pushNotificationConstants.GET_PUSH_NOTIFICATION_COUNT_FAILURE, error));
            }
        );
    }
}

function readPushNotifictaion(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(pushNotificationConstants.READ_PUSH_NOTIFICATION_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(pushNotificationConstants.READ_PUSH_NOTIFICATION_SUCCESS, partner));
                setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
            },
            error => {
                dispatch(commonAction.failure(pushNotificationConstants.READ_PUSH_NOTIFICATION_FAILURE, error));
            }
        )
    }
}

function hidePushNotifictaion(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(pushNotificationConstants.HIDE_PUSH_NOTIFICATION_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(pushNotificationConstants.HIDE_PUSH_NOTIFICATION_SUCCESS, partner));
                setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
            },
            error => {
                dispatch(commonAction.failure(pushNotificationConstants.HIDE_PUSH_NOTIFICATION_FAILURE, error));
            }
        )
    }
}

function getPushNotificationById(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(pushNotificationConstants.GET_PUSH_NOTIFICATION_BY_ID_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            doc => {
                dispatch(commonAction.success(pushNotificationConstants.GET_PUSH_NOTIFICATION_BY_ID_SUCCESS, doc));
            },
            error => {
                dispatch(commonAction.failure(pushNotificationConstants.GET_PUSH_NOTIFICATION_BY_ID_FAILURE, error));
            }
        );
    }
}