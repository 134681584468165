import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const MissionVision = () => {

    const [siteConstants, setSiteConstants] = useState([])

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    const vision = [
        { 'img': '/assets/images/branding.png', 'name': 'Branding',href:'#' },
        { 'img': '/assets/images/crowth.png', 'name': 'Growth' ,href:'#' },
        { 'img': '/assets/images/marketing-img.png', 'name': 'Marketing',href:'/marketing' },
        { 'img': '/assets/images/crow.png', 'name': 'Crowdfounding',href:'#' }
    ]

    return (

        <div>

            <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>{siteConstants.LABEL_MISSION_VISION}</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className="our_causes_sec">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="heading-box">
                                <p className="sub-heading">{siteConstants.LABEL_OUR_MISSION}</p>
                                <h2 className='f-17'>{siteConstants.LABEL_OUR_MISSION_SUBHEADING}</h2>
                            </div>
                        </div>
                    </div>

                </div>
            </section>



            <section className="sec-block marketing-sec">
                <div className="d-flex flex-wrap align-items-center">
                    <div className="img-box">
                        <div><img src="/assets/images/our-mission-bg.jpg" alt='' /></div>
                    </div>
                    <div className="text-box">
                        <div className="inner-text-box">
                            <div className="heading-box heading-dark">
                                <p className="sub-heading">{siteConstants.LABEL_OUR_MISSION}</p>
                                <h2 className='f-17'>{siteConstants.LABEL_RAISE_FUND}</h2>
                            </div>
                            <p className='f-15'>
                                Our Mission or Vision is to help individuals, 
                                charities or companies raise enough funds through our Crowdfunding platform for their specific needs or goals.
                                </p>
                                <p className='f-15'>
                                We also aim to help different charities around the world, by supporting their
                                 specific charitable programs in those parts of the world as our membership grows in those countries
                                </p>
                                <p className='f-15'>
                                Diamond Jubilee 7 (DJ7) is a Closed Matrix Crowdfunding platform, 
                                where members who join can receive gift or donations based on their position and size of the global matrix.
                                </p>
                                <p className='f-15'>
                                With an average struggling person in Africa, said to live on about $2 a day, DJ7’s goal is to change that narrative.
                                 We aim to help at least 10million people kickout of poverty in Africa and other parts of the world. We hope DJ7 
                                is a perfect vehicle to achieve that. We have built a platform that is affordable for any individual to get started with.
                                </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="our_vision_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="vision_box_left">
                                <div className="heading-box">
                                    <p className="sub-heading">{siteConstants.LABEL_OUR_VISION}</p>
                                </div>

                                <p className='f-15'>
                                We also aim to empower the community with the knowledge and understanding of how to embrace Cryptocurrency.
                                 We plan to roll out an App in 2022 
                                for easy access of the system and a Visa card that can will be used for transacting Cryptocurrency into fiat currency.
                                </p>
                                <p className='f-15'>
                                The platform is fair for everyone even those that join years down the line, as they will benefit equally
                                 just like those who came in earlier. Unlike some platforms that benefits only the early comers.
                                </p>
                                <p className='f-15'>
                                DJ7 also has a provision of supporting charitable programs and organisations in different 
                                countries where the founders see the need.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="vision_box_right">
                                <ul className="d-flex flex-wrap align-items-center text-center">
                                    {vision !== undefined && vision.length !== 0 ?
                                        vision.map((data, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href={data.href}>
                                                        <img src={data.img} alt='' />
                                                        <p><strong>{data.name}</strong></p>
                                                    </a>
                                                </li>
                                            )
                                        })
                                        : ''}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <NewsLetter />

            <RaiseFund /> */}

        </div>

    )
}

export default MissionVision;