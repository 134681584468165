import React from 'react';

const CookiesPolicy = () => {
 
    return (
        <>

            <section>
                <div className="container">
                    <div className="top-about-box-sec d-flex flex-wrap align-items-center">
                        <div>
                            <div className="heading-box text-center f-10 scroll-layout" style={{'height':'70vh'}}>
                                <p className='f-12'>

                                    Diamondj7.com’s website and online services (sites) may use “cookies.” Cookies enable you to personalize your experience on our sites, tell us which parts of our sites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behaviour so we can improve our communications and services.
                                    If you want to disable cookies go to your browser provider to find out how to disable cookies.
                                    Because cookies are used throughout our sites, disabling them may prevent you from using certain parts of the sites.
                                    <b>What is a cookie?</b>
                                    Cookies are small pieces of data that are downloaded to your computer or mobile device when you visit a website or application.
                                    What we use cookies for
                                    Cookies can be used to recognise you when you visit Diamondj7.com; to remember your preferences, and to give you a personalised experience that’s in line with your settings. Cookies also make your interactions with Diamondj7.com faster and more secure. Additionally, cookies allow us to bring you advertising both on and off the Diamondj7.com site, and bring customised features to you through Diamondj7.com plug-ins such as our ‘share’ button.
                                    The cookies used on our sites have been categorized based on the guidelines found in the ICC UK Cookie guide. We use the following categories on our websites and other online services:
                                    <b>Category 1 — Strictly Necessary Cookies</b>
                                    These cookies are essential to enable you to browse around our websites and use their features. Without these cookies, services like Login cannot be provided.
                                    <b>Category 2 — Performance Cookies</b>
                                    These cookies collect information about how you use our sites - for instance, which pages you go to most. This data may be used to help optimize our website and services and make them easier for you to navigate. These cookies are also used to let affiliates know if you came to one of our websites from an affiliate and if your visit resulted in the use of a service from us, including details of the service. These cookies don’t collect information that identifies you. All information these cookies collect is aggregated and therefore anonymous.
                                    <b>Category 3 — Functionality Cookies</b>
                                    These cookies allow our sites to remember choices you make while browsing. For instance, we may store your geographic location in a cookie to ensure that we show you our website features localized for your area. These cookies also help us to remember preferences such as text size, fonts, and other customizable site elements. They may also be used to keep track of what featured videos have been viewed to avoid repetition. The information these cookies collect will not personally identify you, and they cannot track your browsing activity on non-Diamondj7.com websites.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default CookiesPolicy;