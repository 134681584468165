export const genealogyConstants = {

    GET_NEW_MEMBER_REQUEST: 'GET_NEW_MEMBER_REQUEST',
    GET_NEW_MEMBER_SUCCESS: 'GET_NEW_MEMBER_SUCCESS',
    GET_NEW_MEMBER_FAILURE: 'GET_NEW_MEMBER_FAILURE',

    GET_POSITION_PURCHASED_REQUEST: 'GET_POSITION_PURCHASED_REQUEST',
    GET_POSITION_PURCHASED_SUCCESS: 'GET_POSITION_PURCHASED_SUCCESS',
    GET_POSITION_PURCHASED_FAILURE: 'GET_POSITION_PURCHASED_FAILURE',

    GET_RE_ENTRY_POSITION_REQUEST: 'GET_RE_ENTRY_POSITION_REQUEST',
    GET_RE_ENTRY_POSITION_SUCCESS: 'GET_RE_ENTRY_POSITION_SUCCESS',
    GET_RE_ENTRY_POSITION_FAILURE: 'GET_RE_ENTRY_POSITION_FAILURE',

    ADD_POSITION_REQUEST: 'ADD_POSITION_REQUEST',
    ADD_POSITION_SUCCESS: 'ADD_POSITION_SUCCESS',
    ADD_POSITION_FAILURE: 'ADD_POSITION_FAILURE',
    ADD_POSITION_RESTORE_DEFAULT: 'ADD_POSITION_RESTORE_DEFAULT',

    GET_POSITION_VIEW_REQUEST: 'GET_POSITION_VIEW_REQUEST',
    GET_POSITION_VIEW_SUCCESS: 'GET_POSITION_VIEW_SUCCESS',
    GET_POSITION_VIEW_FAILURE: 'GET_POSITION_VIEW_FAILURE',

    POSITION_BY_NUMBER_REQUEST: 'POSITION_BY_NUMBER_REQUEST',
    POSITION_BY_NUMBER_SUCCESS: 'POSITION_BY_NUMBER_SUCCESS',
    POSITION_BY_NUMBER_FAILURE: 'POSITION_BY_NUMBER_FAILURE',

    GET_EARNED_INCOME_REQUEST: 'GET_EARNED_INCOME_REQUEST',
    GET_EARNED_INCOME_SUCCESS: 'GET_EARNED_INCOME_SUCCESS',
    GET_EARNED_INCOME_FAILURE: 'GET_EARNED_INCOME_FAILURE',
    GET_EARNED_INCOME_DEFAULT: 'GET_EARNED_INCOME_DEFAULT',

    POSITION_RESTORE_DEFAULT: 'POSITION_RESTORE_DEFAULT',

    GET_PENDING_POSITION_REQUEST: 'GET_PENDING_POSITION_REQUEST',
    GET_PENDING_POSITION_SUCCESS: 'GET_PENDING_POSITION_SUCCESS',
    GET_PENDING_POSITION_FAILURE: 'GET_PENDING_POSITION_FAILURE'

}