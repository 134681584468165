import { userConstants } from '../constants';

export function getUsersList(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_USERS_REQUEST:
            return {
                getUsersList: false
            };

        case userConstants.GET_USERS_SUCCESS:
            return { isgetUserList: true, getUsersList: action.successAction };

        case userConstants.GET_USERS_FAILURE:
            return {
                getUsersList: false,
            };
        default:
            return state
    }
}


export function userSignup(state = { isSignup: false, isLoading: false, isError: false, signup: null }, action) {
    switch (action.type) {
        case userConstants.USER_SIGNUP_REQUEST:
            return {
                isSignup: false,
                isLoading: true,
                isError: false,
                signup: null
            };

        case userConstants.USER_SIGNUP_SUCCESS:
            return {
                isSignup: true,
                signup: action.successAction,
                isLoading: false,
                isError: false
            };

        case userConstants.USER_SIGNUP_FAILURE:
            return {
                isSignup: false,
                isLoading: false,
                isError: true,
                signup: null
            };
        case userConstants.USER_SIGNUP_RESTORE_DEFAULT:
            return {
                isSignup: false,
                isLoading: false,
                isError: false,
                signup: null
            };
        default:
            return state
    }
}

export function userLogin(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_LOGIN_REQUEST:
            return {
                isLogin: false
            };

        case userConstants.USER_LOGIN_SUCCESS:
            return { isLogin: true, userLogin: action.successAction };

        case userConstants.USER_LOGIN_FAILURE:
            return {
                isLogin: false,
            };
        default:
            return state
    }
}

export function userOtp(state = {}, action) {
    switch (action.type) {
        case userConstants.VERIFY_OTP_REQUEST:
            return {
                isOtp: false
            };

        case userConstants.VERIFY_OTP_SUCCESS:
            return { success: true, userOtp: action.successAction };

        case userConstants.VERIFY_OTP_FAILURE:
            return {
                isOtp: false,
            };
        default:
            return state
    }
}

export function verifyUser(state = {}, action) {
    switch (action.type) {
        case userConstants.VERIFY_USER_REQUEST:
            return {
                isVerified: false
            };

        case userConstants.VERIFY_USER_SUCCESS:
            return { isVerified: true, verified: action.successAction };

        case userConstants.VERIFY_USER_FAILURE:
            return {
                isVerified: false,
            };
        case userConstants.RESTORE_VERIFY_USER:
                return {
                    isVerified: false,
            };
        default:
            return state
    }
}

// email verification link 

export function userEmailVerify(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_EMAIL_VERIFY_REQUEST:
            return {
                is_Verified: false
            };

        case userConstants.USER_EMAIL_VERIFY_SUCCESS:
            return { is_Verified: true, verified: action.successAction };

        case userConstants.USER_EMAIL_VERIFY_FAILURE:
            return {
                is_Verified: false,
            };
        default:
            return state
    }
}

export function nextInLineUsers(state = {}, action) {
    switch (action.type) {
        case userConstants.NEXT_IN_LINE_REQUEST:
            return {
               updated: false 
            };
        case userConstants.NEXT_IN_LINE_SUCCESS:
            return { updated: false , data: action.successAction  };

        case userConstants.NEXT_IN_LINE_FAILURE:
            return {
                updated: false 
            };
        default:
            return state
    }
}

export function nextInLineClassicUsers(state = {}, action) {
    switch (action.type) {
        case userConstants.NEXT_IN_LINE_CLASSIC_REQUEST:
            return {
               updated: false 
            };
        case userConstants.NEXT_IN_LINE_CLASSIC_SUCCESS:
            return { updated: false , data: action.successAction  };

        case userConstants.NEXT_IN_LINE_CLASSIC_FAILURE:
            return {
                updated: false 
            };
        default:
            return state
    }
}

export function accountActivation(state = {}, action) {
    switch (action.type) {
        case userConstants.ACCOUNT_ACTIVE_REQUEST:
            return { updated: false };
        case userConstants.ACCOUNT_ACTIVE_SUCCESS:
            return { updated: true, data: action.successAction };
        case userConstants.ACCOUNT_ACTIVE_ERROR:
            return { updated: false };
        default:
            return state
    }
}

export function resendEmail(state = { isMailSend: false, isLoading: false, isError: false, mailSend: null }, action) {
    switch (action.type) {
        case userConstants.SEND_EMAIL_REQUEST:
            return {
                isMailSend: false,
                mailSend: null,
                isLoading: true,
                isError: false
            };

        case userConstants.SEND_EMAIL_SUCCESS:
            return {
                isMailSend: true,
                mailSend: action.successAction,
                isLoading: false,
                isError: false
            };

        case userConstants.SEND_EMAIL_FAILURE:
            return {
                isMailSend: false,
                mailSend: null,
                isLoading: false,
                isError: true
            };

        case userConstants.SEND_EMAIL_RESTORE_DEFAULT:
            return {
                isMailSend: false,
                mailSend: null,
                isLoading: false,
                isError: false
            };
        default:
            return state
    }
}

export function addMembers(state = {}, action) {
    switch (action.type) {
        case userConstants.ADD_MEMBERS_REQUEST:
            return {
                isAdded: false
            };

        case userConstants.ADD_MEMBERS_SUCCESS:
            return { isAdded: true, memberAdded: action.successAction };

        case userConstants.ADD_MEMBERS_FAILURE:
            return {
                isAdded: false,
            };
        default:
            return state
    }
}

export function checkReferLink(state = {}, action) {
    switch (action.type) {
        case userConstants.CHECK_REFER_LINK_REQUEST:
            return {
                isChecked: false
            };

        case userConstants.CHECK_REFER_LINK_SUCCESS:
            return { isChecked: true, checkedUser: action.successAction };

        case userConstants.CHECK_REFER_LINK_FAILURE:
            return {
                isChecked: false,
            };
        default:
            return state
    }
}

export function getAdminReferal(state = {}, action) {
    switch (action.type) {
        case userConstants.ADMIN_REFERAL_REQUEST:
            return {
                isgetAdminReferal: false
            };

        case userConstants.ADMIN_REFERAL_SUCCESS:
            return { isgetAdminReferal: true, getAdminReferal: action.successAction };

        case userConstants.ADMIN_REFERAL_FAILURE:
            return {
                isgetAdminReferal: false,
            };
        default:
            return state
    }
}

export function getCountryList(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_COUNTRY_LIST_REQUEST:
            return {
                isGetCountryList: false
            };

        case userConstants.GET_COUNTRY_LIST_SUCCESS:
            return { isGetCountryList: true, getCountryList: action.successAction };

        case userConstants.GET_COUNTRY_LIST_FAILURE:
            return {
                isGetCountryList: false,
            };
        default:
            return state
    }
}

export function userProfile(state = {}, action) {
    switch (action.type) {
        case userConstants.UPDATE_PROFILE_REQUEST:
            return {
                isProfileUpdated: false
            };

        case userConstants.UPDATE_PROFILE_SUCCESS:
            return { isProfileUpdated: true, profileUpdated: action.successAction };

        case userConstants.UPDATE_PROFILE_FAILURE:
            return {
                isProfileUpdated: false,
            };
        default:
            return state
    }
}

export function uploadProfileImg(state = {}, action) {
    switch (action.type) {
        case userConstants.UPLOAD_IMAGE_REQUEST:
            return {
                isProfileImg: false
            };

        case userConstants.UPLOAD_IMAGE_SUCCESS:
            return { isProfileImg: true, profileImg: action.successAction };

        case userConstants.UPLOAD_IMAGE_FAILURE:
            return {
                isProfileImg: false,
            };
        default:
            return state
    }
}

export function getProfile(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_PROFILE_REQUEST:
            return {
                isGetProfile: false
            };

        case userConstants.GET_PROFILE_SUCCESS:
            return { isGetProfile: true, getProfile: action.successAction };

        case userConstants.GET_PROFILE_FAILURE:
            return {
                isGetProfile: false,
            };
        default:
            return state
    }
}


export function generateReferalLink(state = {}, action) {
    switch (action.type) {
        case userConstants.GENERATE_LINK_REQUEST:
            return {
                isGenerateLink: false
            };

        case userConstants.GENERATE_LINK_SUCCESS:
            return { isGenerateLink: true, generateLink: action.successAction };

        case userConstants.GENERATE_LINK_FAILURE:
            return {
                isGenerateLink: false,
            };
        default:
            return state
    }
}

export function getSetting(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_SETTING_REQUEST:
            return {
                isGetSetting: false
            };

        case userConstants.GET_SETTING_SUCCESS:
            return { isGetSetting: true, getSetting: action.successAction };

        case userConstants.GET_SETTING_FAILURE:
            return {
                isGetSetting: false,
            };
        default:
            return state
    }
}

export function getFounderPosition(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_FOUNDER_POSITION_REQUEST:
            return {
                isGetFounderPosition: false
            };

        case userConstants.GET_FOUNDER_POSITION_SUCCESS:
            return { isGetFounderPosition: true, getFounderPosition: action.successAction };

        case userConstants.GET_FOUNDER_POSITION_FAILURE:
            return {
                isGetFounderPosition: false,
            };
        default:
            return state
    }
}

export function getCount(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_COUNT_REQUEST:
            return {
                isGetCount: false
            };

        case userConstants.GET_COUNT_SUCCESS:
            return { isGetCount: true, getCount: action.successAction };

        case userConstants.GET_COUNT_FAILURE:
            return {
                isGetCount: false,
            };
        default:
            return state
    }
}

export function sendTokenEmail(state = {}, action) {
    switch (action.type) {
        case userConstants.SEND_TOKEN_EMAIL_REQUEST:
            return {
                isOtpSend: false
            };
        case userConstants.SEND_TOKEN_EMAIL_SUCCESS:
            return { isOtpSend: true, otpSend: action.successAction };

        case userConstants.SEND_TOKEN_EMAIL_FAILURE:
            return {
                isOtpSend: false,
            };
        default:
            return state
    }
}