import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, messageAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Card from 'react-bootstrap/Card'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

const InternalMessaging = (props) => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [hasMore, setHasMore] = useState(false)
    const [query, setQuery] = useState('')
    const [listing, setListing] = useState([])
    const [totalPages, setTotalpages] = useState(1);
    const [existingPage, setExistingPage] = useState(1);
    const [chatUser, setChatUser] = useState('')
    const [msgList, setMsgList] = useState([]);
    const [alreadyMessagedUser, setAlreadyMessagedUser] = useState([])

    const { alert, getSenderUser, getChatByUserId, getAllMessagedUser } = useSelector(
        state => ({
            alert: state.alert,
            getSenderUser: state.getSenderUser,
            getChatByUserId: state.getChatByUserId,
            getAllMessagedUser: state.getAllMessagedUser
        })
    );

    const getUserMessgae = (userId) => {
        // console.log("func call with id-----", userId)
        dispatch(messageAction.getChatByUserId(apiConstants.GET_CHAT_BY_USER_ID, { chatUser: userId }));
    }

    const commonSearchFun = (e, pageNumber, event) => {

        if (e.key === 'Enter' || e === "bySearch") {
            let obj = { page: pageNumber ? pageNumber : 1, perPage: 10 };
            obj.page = event && event === "prev" ? obj.page - 1 : event === "next" ? obj.page + 1 : obj.page;
            if (e && e.target) {
                setQuery(e.target.value)
                obj.query = e.target.value;
            }

            if (query) {
                obj.query = query
            }

            if (parseInt(pageNumber) + 1 < totalPages) {
                setHasMore(true)
            }

            dispatch(messageAction.getSenderUser(apiConstants.GET_SENDER_USER, obj));
        }
    }

    const searchMore = (event) => {
        // if (existingPage < totalPages) {
        //     setHasMore(true)
        // }
        setTimeout(() => {
            commonSearchFun('bySearch', existingPage, event)
        }, 500);
    }

    const formik = useFormik({
        initialValues: {
            message: "",
            chatUser: chatUser
        },
        validationSchema: Yup.object().shape({
            message: Yup.string()
                .required("Please Enter your message!"),
            chatUser: Yup.object()
                .required("User not selected ...")
        }),

        onSubmit: values => {
            let obj = {
                message: values.message, chatUser: values.chatUser._id
            }
            dispatch(messageAction.sendMessage(apiConstants.SEND_MESSAGE, obj));
            formik.values.message = ""
            getUserMessgae(formik.values.chatUser._id);
            if (!alreadyMessagedUser.some((x) => x.id == formik.values.chatUser._id)) {
                getMessagedUser()
            }
        }
    });

    const initailizeList = () => {
        setMsgList([]);
        formik.values.message = '';
        formik.values.chatuser = '';
        setChatUser('');
    }

    // get previously chat user list
    const getMessagedUser = () => {
        dispatch(messageAction.getAllMessagedUser(apiConstants.GET_MESSAGED_USER_LIST));
    }

    useEffect(() => {
        dispatch(alertActions.clear());
        setMsgList([]);
        if (props?.location?.state?.userInfo) {
            userHandler(props?.location?.state?.userInfo)
        }
        getMessagedUser();

        return () => {
            setAlertRes('')
            dispatch({ type: 'SEARCH_SENDER_DEFAULT' });
        }
    }, []);

    useEffect(() => {
        if (getAllMessagedUser && Object.keys(getAllMessagedUser).length > 0) {
            if (getAllMessagedUser.isGetAllMessagedUser) {
                const { data } = getAllMessagedUser.getAllMessagedUser.data.data;
                if (data && data.length > 0) {
                    let currentUserName = JSON.parse(sessionStorage.getItem('user'))?.username;
                    let tempArr = [];
                    data.forEach((x) => {
                        if (x.senderDetails[0].username == currentUserName) {
                            tempArr.push(x.receiverDetails[0])
                        } else if (x.receiverDetails[0].username == currentUserName) {
                            tempArr.push(x.senderDetails[0])
                        }
                    });

                    setAlreadyMessagedUser(tempArr)

                    if (!props?.location?.state?.userInfo) {
                        setMsgList(data[0].content);
                        if (!chatUser && currentUserName && data[0].senderDetails[0].username == currentUserName) {
                            setChatUser(data[0].receiverDetails[0])
                        } else if (!chatUser && currentUserName && data[0].receiverDetails[0].username == currentUserName) {
                            setChatUser(data[0].senderDetails[0])
                        }
                    }
                }
            }
        }
    }, [getAllMessagedUser])

    useEffect(() => {
        if (Object.keys(alert).length > 0) {

            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                if (alert.message.errors !== undefined) {

                    if (typeof alert.message.errors == "string") {
                        tempErr.push({ 'type': alert.type, message: alert.message.errors })
                    }
                    else {
                        let keys = Object.keys(alert.message.errors)
                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                            })
                        }
                        else {
                            tempErr = [{ 'type': alert.type, message: alert.message }]
                        }
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);
        }
    }, [alert]);

    useEffect(() => {
        if (getSenderUser && Object.keys(getSenderUser).length > 0) {
            if (getSenderUser.isGetSenderUser) {
                const { totalPages, currentPage, data } = getSenderUser.getSenderUser.data.data;
                setListing(data);
                setTotalpages(totalPages);
                setExistingPage(currentPage)
            }
        }
    }, [getSenderUser])

    useEffect(() => {
        if (getChatByUserId && Object.keys(getChatByUserId).length > 0) {
            if (getChatByUserId.isGetChatByUserId) {
                const { data } = getChatByUserId.getChatByUserId.data.data;
                if (data && data.length > 0) {
                    setMsgList(data[0].content);
                }
            }
        }
    }, [getChatByUserId])

    // handle user who is picked for chat
    const userHandler = (x) => {
        if (x) {
            initailizeList()
            setChatUser(x)
            formik.values.chatUser = x
            getUserMessgae(x._id);
            setListing([])
        }
    }

    const scrollHandler = (e) => {
        let myDiv = e.target
        if (Math.floor(myDiv.scrollTop) === 0) {
            searchMore("prev")
            setTimeout(() => {
                myDiv.scrollTo(0, 20);
            }, 1000);
        } else if (Math.floor(myDiv.scrollTop) === (myDiv.scrollHeight - myDiv.offsetHeight) + 15) {
            searchMore("next")
            setTimeout(() => {
                myDiv.scrollTo(0, 10);
            }, 1000);
        }
    }

    let poolStatus = chatUser.pool_status ? chatUser.pool_status : '';
    let poolDescription = poolStatus &&
        poolStatus === "pending" ? "InActive User (in global pool)" :
        poolStatus === "await" ? "Status not updated yet..." :
            poolStatus === "completed" ? "Activate User" :
                "No Info found..."

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">

                        {alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>
                                <Card>
                                    <Card.Body>

                                        <div className="row ">

                                            <div className='col-md-3 border-right'>

                                                <input
                                                    type="search"
                                                    placeholder="search"
                                                    className="form-control form-control-sm"
                                                    aria-controls="dataTable"
                                                    onKeyDown={(e) => commonSearchFun(e)} />
                                                <small className='text-secondary f-10 mb-5'>Press Enter to search...</small>

                                                {listing && listing.length > 0 ?
                                                    <div id="scrollableDiv" className='my-3 shadow mb-5 bg-white rounded scroll-layout' style={{ height: '30vh', overflow: 'scroll' }}>

                                                        <InfiniteScroll
                                                            dataLength={listing}
                                                            scrollableTarget="scrollableDiv"
                                                            // next={() => searchMore("next")}
                                                            hasMore={hasMore}
                                                            loader={<h5 className='text-center text-secondary m-4'>
                                                                <i className="mr-2 fas fa-spinner fa-pulse"></i>Loading...</h5>}
                                                            endMessage={
                                                                <p className="mt-5" style={{ textAlign: "center" }}>
                                                                    <b>Yay! You have seen it all</b>
                                                                </p>
                                                            }
                                                            onScroll={(e) => scrollHandler(e)}
                                                        >

                                                            <ul className="list-group">
                                                                {listing.map((x, index) => {
                                                                    return (
                                                                        <li key={index} className={'list-group-item notify-cursor ' +
                                                                            (chatUser._id === x._id ? 'bg-info text-light' : 'text-secondary')} onClick={() => userHandler(x)}>
                                                                            <h6>{x.firstname ? x.firstname + " " : ''}  {x.lastName ? x.lastName : ''}</h6>
                                                                            <small>{x.username ? x.username + " " : ''}</small>

                                                                        </li>
                                                                    )

                                                                })}
                                                            </ul>

                                                        </InfiniteScroll>
                                                    </div> : ''}

                                                <ul className="list-group">
                                                    {alreadyMessagedUser.map((x, index) => {

                                                        return (
                                                            <li key={index} className={'list-group-item notify-cursor ' +
                                                                (chatUser._id === x._id ? 'bg-primary text-light' : 'text-secondary')} onClick={() => userHandler(x)}>
                                                                <h6>{x.firstname ? x.firstname + " " : ''}  {x.lastName ? x.lastName : ''}</h6>
                                                                <small>{x.username ? x.username + " " : ''}</small>

                                                            </li>
                                                        )

                                                    })}
                                                </ul>

                                            </div>
                                            <div className="col-md-9">
                                                <div className='msg-box border box-sahdow'>
                                                    <div className="user-chat-details d-flex rounded-bottom p-3 border border-bottom">
                                                        <div>
                                                            <img className='rounded-circle' src={chatUser && chatUser.profile_image ? process.env.REACT_APP_BACKEND_URL + '/' + chatUser.profile_image : '/assets/images/user.png'}></img>
                                                        </div>
                                                        <div className="ml-3">
                                                            <h6 className='text-uppercase font-weight-bold'>{chatUser.firstname ? chatUser.firstname + " " : ''}  {chatUser.lastName ? chatUser.lastName : ''}</h6>

                                                            <small className={"text-danger font-weight-bold" +
                                                                (poolStatus === "pending" ? "text-danger" :
                                                                    poolStatus === "await" ? "text-warning" :
                                                                        poolStatus === "completed" ? "text-success" : "")}>
                                                                {poolDescription ? poolDescription : ''}
                                                            </small>
                                                        </div>
                                                    </div>

                                                    <div className="admin-form w-100">

                                                        <form onSubmit={formik.handleSubmit} className="p-2">

                                                            <div className='scroll-layout d-flex flex-column p-2 mb-2' id="commentDiv" style={{ 'height': '55vh', 'overflow': 'auto' }}>
                                                                {msgList && msgList.length > 0 ?
                                                                    msgList.map((x, index) => {
                                                                        return (
                                                                            x.type !== chatUser._id ?
                                                                                <div>
                                                                                    <div key={index} className="reply-box system-color text-white reply-bg border p-2 bg-gradient-warning mb-2 shadow-sm bg-white rounded">
                                                                                        <p className="mb-1 mt-1 text-light">{x.message}</p>
                                                                                        <small className='text-light'>{moment(x.createdAt).fromNow()}</small>
                                                                                    </div></div> :
                                                                                <div><div key={index} className="send-box comment-bg border p-2 m-1 shadow-sm bg-white rounded">
                                                                                    <p className="mb-1 mt-1">{x.message}</p>
                                                                                    <small>{moment(x.createdAt).fromNow()}</small>
                                                                                </div></div>)
                                                                    })
                                                                    : ''}
                                                            </div>
                                                            <input type="text" className="form-control" name="message"
                                                                placeholder='Type your message...'
                                                                onFocus={() => { setListing([]) }} value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                            <button type="submit" className="errspan">
                                                                <span className="fa fa-paper-plane"></span>
                                                            </button>

                                                            <label className="text-danger f-12 d-block">
                                                                <span className='d-block'>{formik.errors.message && formik.touched.message ?
                                                                    formik.errors.message : ''
                                                                }</span>
                                                                <span className='d-block'>
                                                                    {formik.errors.chatUser && formik.touched.chatUser ?
                                                                        formik.errors.chatUser : ''
                                                                    }</span>
                                                            </label>
                                                            {/* </div>
                                                            </div> */}

                                                        </form>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>

                                {/* Banner Scetion */}
                                <div className='col-md-12 mt-3'>
                                    <BannerRandomFooter />
                                </div>
                            </div>

                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InternalMessaging;
