import React, { useState, useEffect } from 'react';
import { feedbackAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function Competition() {

  const dispatch = useDispatch();
  const [siteConstants, setSiteConstants] = useState([]);
  const [winnersList, setWinnersList] = useState([]);
  const [commission, setCommission] = useState();

  const { language, getCompetition } = useSelector(
    state => ({
      language: state.language,
      getCompetition: state.getCompetition
    })
  );

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }

    if (getCompetition !== undefined && getCompetition !== null) {
      if (Object.keys(getCompetition).length > 0) {
        if (getCompetition.getCompetition !== undefined) {
          if (getCompetition.getCompetition.data !== undefined) {

            const items = getCompetition.getCompetition.data.data;
            const winnerCommission = getCompetition.getCompetition.data.winnerCommission;
            if (items.length > 0) {
              setWinnersList(items);
              setCommission(winnerCommission);
            }
          }
        }
      }
    }
  }, [language, getCompetition])

  useEffect(() => {
    dispatch(feedbackAction.getCompetition(apiConstants.GET_COMPETITION_LIST));
  }, [])

  const calculateCommission = (y,index) => {
    if (y.startPosition <= index && y.endPosition >= index) {
      // 4>= 5 && 10 < =4
      return parseFloat(y.amount).toFixed(2)
    }
  }

  return (
    <>

      <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{siteConstants.LABEL_COMPETITION}</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="m-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="d-flex justify-content-around flex-wrap">
                {winnersList && winnersList.length > 0 ?
                  winnersList.map((wl, i) => {
                    let index = i + 1;
                    let x = wl;
                    let firstname = x.userDetail && x.userDetail.firstname ? x.userDetail.firstname : '';
                    let lastname = x.userDetail && x.userDetail.lastname ? x.userDetail.lastname : ''

                    return (
                      <div className="card text-center m-3" key={index} style={{ width: "18rem" }}>
                        <img className="card-img-top text-center p-2" style={{ 'width': '150px', 'height': '150px', 'margin': '0 auto' }}
                          src={x.userDetail && x.userDetail.profile_image ? process.env.REACT_APP_BACKEND_URL + "/" + x.userDetail.profile_image : '../assets/images/user.png'}
                          alt="user img" />
                        <div className="card-body">
                          <h5 className="card-title">{firstname + " " + lastname}</h5>
                          <p className="card-text">{
                            commission.map((y) => {
                              return calculateCommission(y ,index)
                            })} </p>
                        </div>
                      </div>
                    )
                  })
                  :
                  <p>No record found</p>
                }
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  );
}