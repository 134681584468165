export const menuConstants = {

  GET_MENU_REQUEST: 'GET_MENU_REQUEST',
  GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
  GET_MENU_FAILURE: 'GET_MENU_FAILURE',

  GET_MENU_BY_ID_REQUEST: 'GET_MENU_BY_ID_REQUEST',
  GET_MENU_BY_ID_SUCCESS: 'GET_MENU_BY_ID_SUCCESS',
  GET_MENU_BY_ID_FAILURE: 'GET_MENU_BY_ID_FAILURE'

}