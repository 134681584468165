import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
export default function SharePopup({ onSelect, referalLink }) {

    // const [deleteRes, setDeleteRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        onSelect(val)
    }

    const ShareTo = (name) => {
        if (referalLink) {
            let url = referalLink;
            var params = "menubar=no,toolbar=no,status=no,width=570,height=570";

            if (name === 'facebook') {
                let shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${url}`;
                window.open(shareUrl, "NewWindow", params);
            }
            else if (name === "whatsapp") {
                let shareUrl = 'https://api.whatsapp.com/send?text="My Referal Link"%20' + url;
                window.open(shareUrl, "NewWindow", params);
            }
        }
    }

    return (
        <Modal show={true} size="xxxxl" className="delete-popup" animation={true}>
            <Modal.Header>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_SHARE_OPTION} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-around'>
                    <i className="fab fa-facebook-f icon-social-share icon-facebook" onClick={() => ShareTo('facebook')}></i>
                    <i className="fab fa-whatsapp icon-social-share icon-whatsapp" onClick={() => ShareTo('whatsapp')}></i>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
            </Modal.Footer>

        </Modal>
    )
}
