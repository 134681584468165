import { messageConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";

export const messageAction = {
    getSenderUser,
    sendMessage,
    getChatByUserId,
    getAllMessagedUser
}

function getSenderUser(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(messageConstants.SEARCH_SENDER_REQUEST, eventData));
        commonService.withToken(apiName, eventData).then(
            doc => {
                dispatch(commonAction.success(messageConstants.SEARCH_SENDER_SUCCESS, doc));
            },
            error => {
                dispatch(commonAction.failure(messageConstants.SEARCH_SENDER_FAILURE, error));
            }
        );
    }
}

function sendMessage(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(messageConstants.SEND_MESSAGE_REQUEST, eventData));
        commonService.withToken(apiName, eventData).then(
            doc => {
                dispatch(commonAction.success(messageConstants.SEND_MESSAGE_SUCCESS, doc));
            },
            error => {
                dispatch(commonAction.failure(messageConstants.SEND_MESSAGE_FAILURE, error));
            }
        );
    }
}

function getChatByUserId(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(messageConstants.GET_CHAT_BY_USER_REQUEST, eventData));
        commonService.withToken(apiName, eventData).then(
            doc => {
                dispatch(commonAction.success(messageConstants.GET_CHAT_BY_USER_SUCCESS, doc));
            },
            error => {
                dispatch(commonAction.failure(messageConstants.GET_CHAT_BY_USER_FAILURE, error));
            }
        );
    }
}

function getAllMessagedUser(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(messageConstants.GET_ALL_MESSAGED_USER_REQUEST, eventData));
        commonService.withToken(apiName, eventData).then(
            doc => {
                dispatch(commonAction.success(messageConstants.GET_ALL_MESSAGED_USER_SUCCESS, doc));
            },
            error => {
                dispatch(commonAction.failure(messageConstants.GET_ALL_MESSAGED_USER_FAILURE, error));
            }
        );
    }
}


