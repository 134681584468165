import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap';
import { history } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { userActions, planActions, pushNotificationActions } from '../../../actions';
import { apiConstants } from '../../../constants';
import { CopyToClipboard } from "react-copy-to-clipboard";
import $ from 'jquery';
import SharePopup from '../Popup/SharePopup'
import Select from 'react-select';

const Header = ({ changePlan, changeCount }) => {

   const dispatch = useDispatch();
   const token = sessionStorage.getItem('token');
   const userName = JSON.parse(sessionStorage.getItem('user'));
   const [siteConstants, setSiteConstants] = useState({})
   const [referalLink, setReferalLink] = useState('');
   const [isCopied, setIsCopied] = useState(false);
   const [sharePopupRes, setSharePopup] = useState(false)
   const [planList, setPlanList] = useState([]);
   const [planType, setPlanType] = useState('');
   const [currentPlanName, setCurrentPlanName] = useState('')
   const [notificationCount, setNotificationCount] = useState([]);

   const handleJs = () => {
      $(function () {
         $('body').toggleClass('menu-open');
      });
   }

   const logout = () => {
      if (token !== undefined && userName !== undefined) {
         sessionStorage.removeItem('token');
         sessionStorage.removeItem('user');
         sessionStorage.removeItem('planType');
         history.push('/login');
      }
   }

   const onCopyText = () => {
      setIsCopied(true);
      setTimeout(() => {
         setIsCopied(false);
      }, 3000);
   };

   const customStyles = {
      control: base => ({
         ...base,
         height: 30,
         minHeight: 30,
         fontSize: 12
      })
   };

   const { language, generateReferalLink, getPlan, getPushNotifictaionCount } = useSelector(state => ({
      language: state.language,
      generateReferalLink: state.generateReferalLink,
      getPlan: state.getPlan,
      getPushNotifictaionCount: state.getPushNotifictaionCount
   }));
   useEffect(() => {
      let sessionPlan = sessionStorage.getItem('planType');
      if (sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).name) {
         setCurrentPlanName(JSON.parse(sessionPlan).name)
      }
      dispatch(userActions.generateReferalLink(apiConstants.GET_LINK));
      dispatch(pushNotificationActions.getPushNotificationCount(apiConstants.UNREAD_NOTIFICATION));
      dispatch(planActions.getPlan(apiConstants.GET_PLAN, { limitQuery: true }));
   }, [])

   useEffect(() => {
      if (token !== undefined && token !== null) {
      }
      if (Object.keys(language).length > 0) {
         setSiteConstants(language.siteConstants);
      }

      if (Object.keys(generateReferalLink).length > 0) {
         if (generateReferalLink.isGenerateLink) {
            let code = generateReferalLink.generateLink.data.data
            if (code) {
               setReferalLink(process.env.REACT_APP_FRONTEND_INFO_URL + '/?refer=' + code.username)
            }

         }
      }

      if (getPushNotifictaionCount !== undefined && getPushNotifictaionCount !== null) {
         if (Object.keys(getPushNotifictaionCount).length > 0) {
            if (getPushNotifictaionCount.getPushNotifictaionCount !== undefined) {
               if (getPushNotifictaionCount.getPushNotifictaionCount.data !== undefined) {

                  if (getPushNotifictaionCount.getPushNotifictaionCount.data.data) {
                     setNotificationCount(getPushNotifictaionCount.getPushNotifictaionCount.data.data);
                  }
               }
            }
         }
      }
   }, [language, generateReferalLink, getPushNotifictaionCount])

   const selectedRes = (val) => {

      if (val === 'Yes') {
         setSharePopup(false);
      }
      else {
         setSharePopup(false)
      }
   }

   useEffect(() => {
      if (getPlan) {
         if (Object.keys(getPlan).length > 0) {
            if (getPlan.isGetPlan) {
               let item = getPlan.getPlan.data.data

               if (item !== undefined && item !== null) {
                  let obj = []
                  item.data.map((x) => {
                     return obj.push({ 'label': x.name, 'value': x.name, details: x })
                  })

                  setPlanList(obj)
               }
            }
         }
      }
   }, [getPlan])

   useEffect(() => {

      if (changeCount) {
         dispatch(pushNotificationActions.getPushNotificationCount(apiConstants.UNREAD_NOTIFICATION));
      }
   }, [changeCount])

   const planHandler = (doc) => {
      setPlanType(doc);
      sessionStorage.setItem('planType', JSON.stringify(doc.details))

      if (doc && doc.details.name) {
         let temp = doc.details.name;
         setCurrentPlanName(temp.toUpperCase())
      }

      if (changePlan) {
         changePlan(doc)
      }
   }

   return (
      <>
         <header className="navbar theme-header">
            <div className="head-logo">
               <a href="/dashboard" className="logo"><img src="assets/images/logo1.png" alt="LOGO" /></a>
               <button className="closeMenu_btn"><span className="toggle_icon"></span></button>
            </div>

            <div className="top-navs">
               <div className='row d-flex align-items-center justify-content-between'>

                  <div className='d-flex w-100 ml-2 mr-2'>
                     <div className='w-50 d-flex ' style={{ alignItems: "center" }}>
                        <button className="btn_toggle" onClick={() => handleJs()}>
                           <span className="toggle_icon"></span></button>
                        <div className=''>

                           <div className="" style={{ minWidth: '120px' }}>
                              <Select
                                 className='form-select plan-dropdown select-layout'
                                 menuPlacement="auto"
                                 autoCompelete="off"
                                 value={planType}
                                 name="planType"
                                 placeholder="Plan"
                                 onChange={(e) => { planHandler(e) }}
                                 options={planList}
                                 styles={customStyles}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="w-50" style={{ 'display': 'flex', 'justifyContent': 'flex-end' }}>
                        <div className='d-flex align-items-center notify-cursor'>
                           <i className="fas fa-bell badge-wrapper mr-3" onClick={() => { history.push('/notification') }}> <span className='badge badge-secondary'>{notificationCount ? notificationCount : 0}</span></i>
                           <div className="user-profile dropdown curo">
                              <span className="dropdown-toggle text-right" id="userMenuprofile" data-toggle="dropdown" aria-expanded="false">
                                 <span className="user_icon"><img className="header-profile-icon"
                                    src={userName && userName.profile ? process.env.REACT_APP_BACKEND_URL + "/" + userName.profile : "assets/images/user.png"} alt="profile" /></span>
                                 <span className="name-dropdown">{userName && userName?.firstname ? userName.firstname : '' + " " + userName && userName?.lastname ? userName.lastname : ''}
                                 </span>
                              </span>
                              <ul className="user-prof-menu dropdown-menu">
                                 <li><Nav.Link href="/profile"><i className="fa fa-user" aria-hidden="true"></i> My Profile</Nav.Link></li>
                                 <li> <Nav.Link onClick={() => logout()}><i className="fas fa-sign-out-alt" aria-hidden="true"></i> {siteConstants.MENU_LOGOUT}</Nav.Link></li>
                              </ul>
                           </div>
                        </div>

                     </div>
                  </div>

                  <div className='w-100 row d-flex align-items-center justify-content-between mt-1 ml-2 mr-2'>

                     <div className="col-lg-6 col-md-7 col-sm-6  input-group rounded d-flex align-items-center">
                        <h6 className='text-primary'><b>{currentPlanName}</b></h6>
                        <CopyToClipboard text={referalLink !== undefined && referalLink !== null ? referalLink : ''} onCopy={onCopyText}>
                           <div className="copy-area mb-2">
                              <button className="btn btn-new mr-3" data-toggle="tooltip" data-placement="bottom"
                                 title={referalLink !== undefined && referalLink !== null ? referalLink : ''}>
                                 <i className="fas fa-copy"></i> <span className=''>Referral Link</span> </button>
                              {isCopied ?
                                 <span className={isCopied ? "text-primary" : ""}>
                                    Copied!
                                 </span> : ''}
                           </div>
                        </CopyToClipboard>
                        <i className="fas fa-share text-secondary f-15" style={{ 'display': 'contents' }} onClick={() => setSharePopup(true)}></i>

                     </div>
                     <div id="google_translate_element" className='col-lg-6 col-md-5 col-sm-6 h-20 mb-2 header-translate'></div>
                  </div>


               </div>
            </div>

         </header>

         {sharePopupRes ? <SharePopup onSelect={selectedRes} referalLink={referalLink} /> : ''}

      </>
   )
}

export default Header;