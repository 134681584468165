import { feedbackConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "./";

export const feedbackAction = {
  addContactDetails,
  addNewsLetter,
  addTestimonials,
  getFaqList,
  getCompetition,
  getMarketing,
  getTestimonials
};

//-- Add contact details function
function addContactDetails(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.ADD_CONTACT_DETAILS_REQUEST));

    commonService.withOutToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.ADD_CONTACT_DETAILS_SUCCESS, user));
        dispatch(alertActions.success("Send Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.ADD_CONTACT_DETAILS_FAILURE, error));
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

//-- Add newsletter function
function addNewsLetter(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.ADD_NEWSLETTER_REQUEST, obj));

    commonService.withOutToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.ADD_NEWSLETTER_SUCCESS, user));
        dispatch(alertActions.success("Subscribed For Newsletter Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.ADD_NEWSLETTER_FAILURE, error));
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

//--get Faq function
function getFaqList(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.GET_FAQ_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.GET_FAQ_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.GET_FAQ_FAILURE, error));
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

//-- Add testimonials function
function addTestimonials(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.ADD_TESTIMONIALS_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.ADD_TESTIMONIALS_SUCCESS, user));
        dispatch(alertActions.success("Testimonials Added Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.ADD_TESTIMONIALS_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

//-- get competition  function
function getCompetition(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.GET_COMPETITION_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.GET_COMPETITION_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.GET_COMPETITION_FAILURE, error));
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

//-- get marketing  function
function getMarketing(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.GET_MARKETING_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.GET_MARKETING_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.GET_MARKETING_FAILURE, error));
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

//-- get Testimonials function
function getTestimonials(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.GET_TESTIMONIALS_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.GET_TESTIMONIALS_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.GET_TESTIMONIALS_FAILURE, error));
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}