import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, genealogyActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import { history } from '../../helpers';
import { useLocation } from 'react-router-dom';
var momentTimeZone = require('moment-timezone');
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const ReEntryListing = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpages] = useState(1);
    const [reEntriesPosition, setReEntriesPosition] = useState([])
    const [query, setQuery] = useState('');
    const [planType, setPlanType] = useState('');
    const [currentPlanName, setCurrentPlanName] = useState('')

    const { alert, language, getReEntryPosition } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language,
            getReEntryPosition: state.getReEntryPosition
        })
    );


    const initialApiCalls = () => {
        let sessionPlan = sessionStorage.getItem('planType');
        let obj = { page: 1, perPage: 10, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' }, orderByAscDesc: -1, limitQuery: false };
        let reEntryApiName = apiConstants.GET_RE_ENTRY_POSITION;
        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
            reEntryApiName = apiConstants.RE_ENTRY_PLAN_POSITION;
        }
        else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
            reEntryApiName = apiConstants.MATRIX_RE_ENTRY_PLAN_POSITION;
        }
        else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
            reEntryApiName = apiConstants.SUPER_MATRIX_RE_ENTRY_PLAN_POSITION;
        }

        if (sessionPlan && Object.keys(sessionPlan).length > 0) {
            if (JSON.parse(sessionPlan).defaultName) {
                let temp = JSON.parse(sessionPlan).defaultName.split('-');
                setCurrentPlanName(temp[0].toLowerCase())
            }
        }
        dispatch(alertActions.clear());
        dispatch(genealogyActions.getReEntryPosition(reEntryApiName, obj))
    }

    useEffect(() => {
        initialApiCalls()
    }, [planType])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
        if (getReEntryPosition !== undefined && getReEntryPosition !== null) {
            if (Object.keys(getReEntryPosition).length > 0) {
                if (getReEntryPosition.isGetReEntryPosition) {
                    setReEntriesPosition(getReEntryPosition.getReEntryPosition.data.data);
                    setTotalpages(getReEntryPosition.getReEntryPosition.data.data.totalPages);
                }
            }
        }
    }, [language, getReEntryPosition]);

    useEffect(() => {
        initialApiCalls()
        return () => {
            setAlertRes('')
        }
    }, []);

    useEffect(() => {
        if (alert !== undefined && alert !== null) {
            if (Object.keys(alert).length > 0) {

                let tempErr;
                if (alert.type === 'alert-success') {
                    tempErr = [{ 'type': alert.type, 'message': alert.message }]
                }
                else {
                    if (alert.message.errors !== undefined && alert.message.errors !== null) {
                        let keys = Object.keys(alert.message.errors)
                        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
                    }
                }

                setAlertRes(tempErr);
                setTimeout(() => {
                    setAlertRes('')
                }, 3000);
            }
        }
    }, [alert]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        let sessionPlan = sessionStorage.getItem('planType');
        let reEntryApiName = apiConstants.GET_RE_ENTRY_POSITION;

        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
            reEntryApiName = apiConstants.RE_ENTRY_PLAN_POSITION;
        } else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
            reEntryApiName = apiConstants.MATRIX_RE_ENTRY_PLAN_POSITION;
        }
        else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
            reEntryApiName = apiConstants.SUPER_MATRIX_RE_ENTRY_PLAN_POSITION;
        }

        let obj = {
            page: pageNumber,
            perPage: 10,
            orderByAscDesc: -1,
            limitQuery: false,
            plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' }
        };
        if (query !== '') {
            obj.query = query;
        }
        dispatch(genealogyActions.getReEntryPosition(reEntryApiName, obj))
    }

    const searchPosition = (e) => {
        setQuery(e.target.value)

        let sessionPlan = sessionStorage.getItem('planType');
        let obj = { page: 1, perPage: 10, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } };
        let reEntryApiName = apiConstants.GET_RE_ENTRY_POSITION;

        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && sessionPlan ? JSON.parse(sessionPlan).status === 1 : '')) {
            reEntryApiName = apiConstants.RE_ENTRY_PLAN_POSITION;
        }
        else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
            reEntryApiName = apiConstants.MATRIX_RE_ENTRY_PLAN_POSITION;
        }
        else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
            reEntryApiName = apiConstants.SUPER_MATRIX_RE_ENTRY_PLAN_POSITION;
        }
        if (e.target.value !== '') {
            obj.query = e.target.value;
            dispatch(genealogyActions.getReEntryPosition(reEntryApiName, obj))
        } else {
            dispatch(genealogyActions.getReEntryPosition(reEntryApiName, obj))
        }
    }

    const viewAll = (positionNo, actualPositionNumber) => {
        let sessionPlan = sessionStorage.getItem('planType');

        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
            history.push('/matrix-genealogy', { state: actualPositionNumber });
            return true;
        } else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
            history.push('/matrix-genealogy', { state: actualPositionNumber });
            return true;
        }
        if (location.pathname === '/genealogy') {
            dispatch(genealogyActions.positionByNumber(apiConstants.POSITION_BY_NUMBER, { positionNumber: positionNo }));
        }
        else {
            history.push('/genealogy', { state: positionNo });
        }
    }

    const planHandler = (e) => {

        setPlanType(e.details)
    }

    let totalPage = typeof reEntriesPosition !== 'undefined' && Object.keys(reEntriesPosition).length > 0 ? totalPages : 0;
    let sessionPlan = sessionStorage.getItem('planType');

    return (
        <>
            <div className="main-wrap">
                <Header changePlan={(e) => { planHandler(e) }} />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== undefined && alertRes !== null && alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className={"card dash-card tag-block p-3 box_shadow " +
                                                        (currentPlanName === "dj7lite" ? 'dj-lite-bg' : currentPlanName === "dj7premier" ? 'dj-premier-bg' : '')}>
                                                        <div className="row">
                                                            <div className="col-lg-9">  <h2 className="heading-top">{siteConstants.RE_ENTRY_LIST}</h2>  </div>
                                                        </div>

                                                        <div className="row m-2">
                                                            <div className="col-sm-12 col-md-12 d-flex justify-content-end">
                                                                <div id="dataTable_filter" className="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" placeholder="search (by position number)"
                                                                            className="form-control form-control-sm"
                                                                            aria-controls="dataTable"
                                                                            onChange={(e) => searchPosition(e)} />

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="owned-sec scroll-layout">

                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Board Number</th>
                                                                        <th>Parent</th>
                                                                        <th>Starting Date</th>
                                                                        <th>Position</th>
                                                                        <th>Round</th>
                                                                        <th>Re-Gift Balance</th>
                                                                        <th>Re-Entry Balance</th>
                                                                        <th>Profit Balance</th>
                                                                        {
                                                                            sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2 ?
                                                                                <div>
                                                                                    {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2) ?
                                                                                        <th>Sponsor Balance</th>
                                                                                        : ''}
                                                                                </div> :
                                                                                <div>
                                                                                    {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3) ?
                                                                                        <th>Sponsor Balance</th>
                                                                                        : ''}
                                                                                </div>
                                                                        }

                                                                        {/* <th>Total</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {reEntriesPosition !== undefined && reEntriesPosition !== null ? reEntriesPosition.items !== undefined &&
                                                                        reEntriesPosition.items.length > 0 ? reEntriesPosition.items.map((data, index) => {
                                                                            let d = new Date(data.createdAt);
                                                                            let total_regift = parseFloat(data.regift_balance1) + parseFloat(data.regift_balance2) +
                                                                                parseFloat(data.regift_balance3) + parseFloat(data.regift_balance4)

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{data.boardNumber !== null && data.boardNumber !== undefined ?
                                                                                        data.boardNumber.positionNumber : ''}</td>
                                                                                    <td>{data.re_entry_parent.positionNumber}</td>
                                                                                    {/* <td>{d.toLocaleString("en-US", options)}</td> */}
                                                                                    <td>{momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                                                                        momentTimeZone(d).tz(momentTimeZone.tz.guess()).format('DD MMMM YYYY')
                                                                                        :
                                                                                        momentTimeZone(d).tz(timeZone).format('DD MMMM YYYY')}</td>
                                                                                    <td> <p className="text-primary notify-cursor" onClick={() => viewAll(data.boardNumber.positionNumber, data?.positionNumber)}>
                                                                                        {data.positionNumber}</p></td>
                                                                                    <td>{data.userCurrentRound}</td>
                                                                                    <td>{total_regift !== null && total_regift !== undefined ? total_regift : '-'}</td>
                                                                                    <td>{data.re_entry_balance !== null && data.re_entry_balance !== undefined ? data.re_entry_balance : '-'}</td>
                                                                                    <td>{data.profit_balance !== null && data.profit_balance !== undefined ? data.profit_balance : '-'}</td>
                                                                                    {
                                                                                        sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2 ?
                                                                                            <div>
                                                                                                {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2) ?
                                                                                                    <td>{data.sponsor_balance !== null && data.sponsor_balance !== undefined ? data.sponsor_balance : '-'}</td>
                                                                                                    : ''}
                                                                                            </div> :
                                                                                            <div>
                                                                                                {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3) ?
                                                                                                    <td>{data.sponsor_balance !== null && data.sponsor_balance !== undefined ? data.sponsor_balance : '-'}</td>
                                                                                                    : ''}
                                                                                            </div>
                                                                                    }
                                                                                    {/* {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2) ?
                                                                                        <td>{data.sponsor_balance !== null && data.sponsor_balance !== undefined ? data.sponsor_balance : '-'}</td>
                                                                                        : ''} */}
                                                                                </tr>
                                                                            )
                                                                        })

                                                                        : <tr><td colSpan="8" className="ml-2">No Record Found</td></tr>
                                                                        : ''}
                                                                </tbody>

                                                            </table>

                                                            {typeof reEntriesPosition !== 'undefined' ?
                                                                <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                                                                : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Banner Scetion */}
                            <div className='col-md-12 mt-3'>
                                <BannerRandomFooter />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>

            </div >

        </>
    );
}

export default ReEntryListing;
