import { menuConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "./";

export const menuActions = {
  getMenu,
  getMenuById
}

function getMenu(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(menuConstants.GET_MENU_REQUEST, eventData));
    commonService.withOutToken(apiName, eventData).then(
      doc => {
        dispatch(commonAction.success(menuConstants.GET_MENU_SUCCESS, doc));
      },
      error => {
        dispatch(commonAction.failure(menuConstants.GET_MENU_FAILURE, error));
      }
    );
  }
}

function getMenuById(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(menuConstants.GET_MENU_BY_ID_REQUEST, eventData));
    commonService.withOutToken(apiName, eventData).then(
      doc => {

        dispatch(commonAction.success(menuConstants.GET_MENU_BY_ID_SUCCESS, doc));
      },
      error => {
        dispatch(commonAction.failure(menuConstants.GET_MENU_BY_ID_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}
