import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { alertActions, advertismentAction } from "../../actions";
import { apiConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Common/Pagination/Pagination";
import Header from "../Common/Dashboard/Header";
import Footer from "../Common/Dashboard/Footer";
import Sidebar from "../Common/Dashboard/Sidebar";
import Modal from 'react-bootstrap/Modal';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { bannerTypeConstants } from '../../constants';
import Button from 'react-bootstrap/Button';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';

const BannerList = () => {
  const { BANNER_FIXED_WIDTH, BANNER_FIXED_HEIGHT, LOGIN_ADS, BANNER_ADS, TEXT_TEMPLATE1, TEXT_TEMPLATE2 } = bannerTypeConstants;
  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState("");

  const [advertismentList, setAdvertismentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalpages] = useState(1);
  const [viewBanner, setViewBanner] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [editCredit, setEditCredit] = useState('')

  const { alert, getAdvertismentList } = useSelector((state) => ({
    alert: state.alert,

    getAdvertismentList: state.getAdvertismentList,
  }));

  useEffect(() => {
    if (getAdvertismentList) {
      if (Object.keys(getAdvertismentList).length > 0) {
        if (getAdvertismentList.getAdvertismentList !== undefined) {
          if (getAdvertismentList.getAdvertismentList.data !== undefined) {
            const { totalPages, data } = getAdvertismentList.getAdvertismentList.data.data;
            if (data) {
              data.filter((x, index) => { return x['switch' + index] = x.status })
            }

            if (data.length > 0) {
              setAdvertismentList(data);
              setTotalpages(totalPages);
            }
          }
        }
      }
    }
  }, [getAdvertismentList]);

  useEffect(() => {
    dispatch(alertActions.clear());
    getAdvertisment(1);

    return () => {
      setAlertRes("");
    };
  }, []);

  // alert handler useeffect
  useEffect(() => {
    if (Object.keys(alert).length > 0) {
      let tempErr = [];

      if (alert.type === "alert-success") {
        tempErr = [{ type: alert.type, message: alert.message }];
      } else if (alert.type === "alert-danger") {
        if (alert.message.errors) {

          if (typeof alert.message.errors === "object" && Object.keys(alert.message.errors).length > 0) {
            let keys = Object.keys(alert.message.errors);
            if (keys.length !== 0) {
              keys.map((data) => {
                tempErr.push({
                  type: alert.type,
                  message: alert.message.errors[data],
                });
              });
            }
          } else {
            tempErr = [{ type: alert.type, message: alert.message.errors }];
          }

        } else {
          tempErr = [{ type: alert.type, message: alert.message }];
        }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes("");
      }, 3000);
    }
  }, [alert]);

  const getAdvertisment = (pageNumber) => {
    let obj = {
      page: pageNumber,
      perPage: 10,
      orderByAscDesc: -1,
      limitQuery: false,
    };
    dispatch(
      advertismentAction.getAdvertismentList(apiConstants.GET_ADVERTISMENT, obj)
    );
  };

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber);

    getAdvertisment(pageNumber);
  };

  const deleteBanner = (_id) => {
    dispatch(advertismentAction.deleteBannner(apiConstants.DELETE_BANNER, { _id: _id }));
    setTimeout(() => {
      getAdvertisment(currentPage)
    }, 3000);
  }

  const bannerStatus = (_id, targetValue, index) => {
    let arrTemp = advertismentList
    arrTemp[index]['switch' + index] = targetValue
    setAdvertismentList(arrTemp)
    dispatch(advertismentAction.bannerStatus(apiConstants.BANNER_STATUS, { _id: _id, status: targetValue }));
  }

  const onSubmitHandler = (bannerId) => {
    // edit advertisment
    dispatch(advertismentAction.editAdvertisment(apiConstants.EDIT_ADVERTISMENT, { _id: bannerId, credit: editCredit }));
    setShowEditPopup(false)
    setEditCredit('');
    setEditBanner('');
    setTimeout(() => {
      getAdvertisment(currentPage)
    }, 600);
  }

  let totalPage =
    typeof advertismentList !== "undefined" &&
      Object.keys(advertismentList).length > 0
      ? totalPages
      : 0;

  return (
    <>
      <div className="main-wrap">
        <Header />
        <div className="dashboard-wraper">
          <Sidebar />
          <div className="content-wrap">
            {alertRes
              ? alertRes.length > 0
                ? alertRes.map((data, index) => {
                  return (
                    <Alert
                      key={index}
                      variant={
                        data.type === "alert-danger" ? "danger" : "success"
                      }
                      className="alertBox"
                    >
                      {data.message}
                    </Alert>
                  );
                })
                : ""
              : ""}

            <div className="dashboard-content p">
              <div className="dash-body-blk">
                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row progress-bar-sec">
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                          <div className="card dash-card tag-block p-3 box_shadow">
                            <div className="row">
                              <div className="col-lg-9">
                                {" "}
                                <h2 className="heading-top">
                                  Advertisment List
                                </h2>{" "}
                              </div>
                            </div>

                            <div className="owned-sec scroll-layout transaction-layout">
                              <table className="m-2">
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Banner title</th>
                                    <th>Banner description</th>
                                    <th>Banner Credit</th>
                                    <th>Advertisment type</th>
                                    <th>Ads View Count</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {advertismentList !== undefined ? (
                                    advertismentList.length > 0 ? (
                                      advertismentList.map((data, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              {data === 1
                                                ? index + 1
                                                : index + 1}
                                            </td>
                                            <td>{data.bannerTitle}</td>
                                            <td>{data.bannerDescription && Object.keys(data.bannerDescription).length > 0 ?
                                              <span className="d-flex flex-column">
                                                <b>{data.bannerDescription.bannerdescriptionLine1 ? data.bannerDescription.bannerdescriptionLine1 : ''}</b>
                                                <b>{data.bannerDescription.bannerdescriptionLine2 ? data.bannerDescription.bannerdescriptionLine2 : ''}</b>
                                                <b>{data.bannerDescription.bannerdescriptionLine3 ? data.bannerDescription.bannerdescriptionLine3 : ''}</b>
                                              </span>

                                              : ''}</td>
                                            <td>{data.credit}</td>
                                            <td>{data.type}</td>
                                            <td>{data.userIp ? data.userIp : 0}</td>
                                            <td>
                                              <BootstrapSwitchButton
                                                checked={data['switch' + index]}
                                                onlabel='Active'
                                                offlabel='Inactive'
                                                width={110}
                                                onstyle="primary"
                                                onChange={(e) => bannerStatus(data._id, e, index)}
                                              />
                                            </td>

                                            <td className="tdTextCenter notify-cursor">
                                              <i className='fa fa-eye mr-2' onClick={() => { setViewBanner(data); setShowPopUp(true) }}></i>
                                              <i className='fa fa-edit mr-2' onClick={() => { setEditBanner(data); setShowEditPopup(true) }}></i>
                                              <i className="fas fa-trash-alt mr-2" onClick={() => deleteBanner(data._id)}></i>
                                            </td>

                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td className="mt-2" colSpan="8">
                                          No Record Found{" "}
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </table>
                              {typeof advertismentList !== "undefined" ? (
                                <Pagination
                                  totalPage={totalPage}
                                  currentPage={currentPage}
                                  funPagination={makeHttpRequestWithPage}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Banner Scetion */}
              <div className='col-md-12 mt-3'>
                <BannerRandomFooter />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {/* View Banner by advertisment ID */}
      <Modal show={showPopUp} className="delete-popup text-secondary" animation={true}>
        <Modal.Header style={{ display: "block" }}>
          <div className="d-flex justify-content-between">
            <div>
              <Modal.Title className="text-uppercase">
                {viewBanner.bannerTitle}
              </Modal.Title>
            </div>
            <div>
              <i className='fa fa-times' onClick={() => { setShowPopUp(false) }}></i>
            </div>
          </div>
        </Modal.Header>
        {
          <Modal.Body>
            <h5><b>Banner Type : </b>{viewBanner.type}</h5>
            <h6><b>Banner Status : </b>{viewBanner.adminApproved ? <span className="text-success">Approved</span> : <span className="text-danger">Pending</span>}</h6>
            <small><b>Banner Link : </b>{viewBanner.link}</small>
            {viewBanner.type === LOGIN_ADS ? '' :

              viewBanner.type === TEXT_TEMPLATE1 || viewBanner.type === TEXT_TEMPLATE2 ?
                <div className={`text-ellipsis card m-1  mb-3 ${viewBanner.type === TEXT_TEMPLATE1 ? 'text-white bg-danger' : 'text-dark bg-info'}`}
                  style={{ 'height': BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                  <div className={`text-uppercase p-2 text-primary fw-600 border-light-secondary text-center ${viewBanner.type === TEXT_TEMPLATE1 ? 'border-bottom border-secondary' : 'border-light-secondary'}`}>{viewBanner.bannerTitle ? viewBanner.bannerTitle : 'Header'}</div>
                  <div className="template-card-layout text-white text-center register-banner-layout m-2 d-flex justify-content-center flex-column">

                    <small className="card-text">
                      {viewBanner.bannerDescription?.bannerdescriptionLine1 ? viewBanner.bannerDescription.bannerdescriptionLine1 :
                        'Some quick example'}</small>
                    <small className="card-text">
                      {viewBanner.bannerDescription?.bannerdescriptionLine2 ? viewBanner.bannerDescription.bannerdescriptionLine2 :
                        'Some quick example text '}</small>
                    <small className="card-text">
                      {viewBanner.bannerDescription?.bannerdescriptionLine3 ? viewBanner.bannerDescription.bannerdescriptionLine3 :
                        'Some example'}</small>
                  </div>
                  <a className={`text-center g-13 mx-1 ${viewBanner.type === TEXT_TEMPLATE1 ? 'text-secondary' : 'text-white'}`} 
                  style={{ textDecoration: 'none' }} data-title={viewBanner.link} href={viewBanner.link} target="blank">
                    <small className='text-primary fw-600'>Click Here Now </small>
                  </a>
                </div>
                : ''
            }

            {viewBanner.type === LOGIN_ADS ?
              viewBanner.files ?
                <div className='mt-3 mb-3 text-dark'>
                  <a href={viewBanner.link} target='blank'>
                    <img className="ad-banner" src={viewBanner.files} alt="bannerImg" />
                  </a>
                </div>
                :
                <div className='mt-3 card mb-3 text-dark' style={{ 'height': BANNER_FIXED_HEIGHT + 'px' }}>
                  <iframe className='w-100 h-90vh' src={viewBanner.link} title={viewBanner.bannertitle ? viewBanner.bannertitle : 'Header'} />
                </div>
              :
              viewBanner.type === BANNER_ADS ?
                <div className='mt-3 mb-3 text-dark'>
                  <a href={viewBanner.link} target='blank'>
                    <img className="ad-banner" src={viewBanner.files} alt="bannerImg" />
                  </a>
                </div>
                : ''
            }
          </Modal.Body>
        }
      </Modal>

      {/* Edit Banners Details  */}
      <Modal show={showEditPopup} className="delete-popup text-secondary" animation={true}>
        <Modal.Header style={{ display: "block" }}>
          <div className="d-flex justify-content-between">
            <div>
              <Modal.Title className="text-uppercase">
                {editBanner.bannerTitle}
              </Modal.Title>
            </div>
            <div>
              <i className='fa fa-times' onClick={() => { setShowEditPopup(false) }}></i>
            </div>
          </div>
        </Modal.Header>
        {
          <Modal.Body>
            <label className="d-block">Credit</label>
            <input className="form-control" type="number" onChange={(e) => { setEditCredit(e.target.value) }} name="credit" value={editCredit}></input>
          </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="primary" onClick={() => onSubmitHandler(editBanner._id)}> Save </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BannerList;