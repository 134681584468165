import { supportConstants, apiConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "../actions";

export const supportActions = {
    getSupportTicket,
    addSupportTicket,
    editSupportTicket,
    deleteSupportTicket,
    commentSupportTicket,
    getSupportTicketById
}

function getSupportTicketById(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(supportConstants.GET_SUPPORT_TICKET_BY_ID_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(supportConstants.GET_SUPPORT_TICKET_BY_ID_SUCCESS, partner));
            },
            error => {
                dispatch(commonAction.failure(supportConstants.GET_SUPPORT_TICKET_BY_ID_FAILURE, error));
            }
        );
    }
}

function getSupportTicket(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(supportConstants.GET_SUPPORT_TICKET_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(supportConstants.GET_SUPPORT_TICKET_SUCCESS, partner));
            },
            error => {
                dispatch(commonAction.failure(supportConstants.GET_SUPPORT_TICKET_FAILURE, error));
            }
        );
    }
}

function addSupportTicket(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(supportConstants.ADD_SUPPORT_TICKET_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(supportConstants.ADD_SUPPORT_TICKET_SUCCESS, partner));
                dispatch(alertActions.success("Ticket Added Successfully ..."));
            },
            error => {
                dispatch(commonAction.failure(supportConstants.ADD_SUPPORT_TICKET_FAILURE, error));
                if (error.response !== undefined) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    }
}

function editSupportTicket(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(supportConstants.EDIT_SUPPORT_TICKET_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(supportConstants.EDIT_SUPPORT_TICKET_SUCCESS, partner));
                dispatch(alertActions.success("Ticket Updated Successfully ..."));
                setTimeout(() => {
                    dispatch(getSupportTicket(apiConstants.GET_SUPPORT_TICKET));
                }, 2000);
            },
            error => {
                dispatch(commonAction.failure(supportConstants.EDIT_SUPPORT_TICKET_FAILURE, error));
               
                if (error.response !== undefined) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    }
}

function deleteSupportTicket(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(supportConstants.DELETE_SUPPORT_TICKET_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(supportConstants.DELETE_SUPPORT_TICKET_SUCCESS, partner));
                dispatch(alertActions.success("Ticket deleted Successfully ..."));
                setTimeout(() => {
                    dispatch(getSupportTicket(apiConstants.GET_SUPPORT_TICKET));
                }, 2000);
            },
            error => {
                dispatch(commonAction.failure(supportConstants.DELETE_SUPPORT_TICKET_FAILURE, error));
                if (error.response !== undefined) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    }
}

function commentSupportTicket(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(supportConstants.COMMENT_SUPPORT_TICKET_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(supportConstants.COMMENT_SUPPORT_TICKET_SUCCESS, partner));
            },
            error => {
                dispatch(commonAction.failure(supportConstants.COMMENT_SUPPORT_TICKET_FAILURE, error));
            }
        );
    }
}
