import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { walletAction } from '../../../actions';
import { apiConstants } from '../../../constants';

export default function WalletConditionPopup({ onSelect }) {
    const dispatch = useDispatch();
    const [siteConstants, setSiteConstants] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        onSelect(val)
    }

    const addWallet = () => {
        setLoading(true);
        dispatch(walletAction.addWallet(apiConstants.EXTERNAL_WALLET_KEY))
        handleSubmit('No')
    }

    return (
        <Modal show={true} onHide={() => handleSubmit('No')} size='xxl' className='f-10' dialogClassName="modal-70w" animation={true}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_CREATE_WALLET} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
                <Modal.Body>
                   <h4>Are you sure you want to create wallet ? </h4>
                </Modal.Body>
                <Modal.Footer>
                    {
                        !isLoading ?
                            <button type="buttton" onClick={()=>addWallet()} className="btn btn-primary">Submit</button> :
                            <button type="button" className="btn btn-new blue-layout" disabled={isLoading}>
                                <i className="fas fa-spinner fa-spin"></i></button>
                    }
                    <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
                </Modal.Footer>
                <p className='text-secondary f-12 p-2'>Please feel free to reach out to us if need further assistance 
                <b className='text-info'>{" "+process.env.REACT_APP_FRONTEND_SUPPORT_EMAIL}</b></p>
        </Modal>
    )
}
