export const nomineeConstants = {

  GET_NOMINEE_REQUEST: 'GET_NOMINEE_REQUEST',
  GET_NOMINEE_SUCCESS: 'GET_NOMINEE_SUCCESS',
  GET_NOMINEE_FAILURE: 'GET_NOMINEE_FAILURE',

  UPDATE_NOMINEE_REQUEST: 'UPDATE_NOMINEE_REQUEST',
  UPDATE_NOMINEE_SUCCESS: 'UPDATE_NOMINEE_SUCCESS',
  UPDATE_NOMINEE_FAILURE: 'UPDATE_NOMINEE_FAILURE',

  CREATE_NOMINEE_REQUEST: 'CREATE_NOMINEE_REQUEST',
  CREATE_NOMINEE_SUCCESS: 'CREATE_NOMINEE_SUCCESS',
  CREATE_NOMINEE_FAILURE: 'CREATE_NOMINEE_FAILURE',

}