// import { authHeader,history } from '../helpers';
import axios from 'axios';

export const userService = {
    getUsersList,
    userSignup,
    userLogin,
    activateAccount,
    resendEmail,
    uploadProfileImg,
    changePassword,
    userEmailVerify
};


//-- getUsersList
function getUsersList(apiName) {
    const users=["Amit","Rohit","Nilesh"];
    let response={};
    response.status=200;
    response.data=users;
    return new Promise((resolve, reject) => {
        resolve (response);
    });

}

function userSignup(apiName,obj) {
        return axios({
        method: 'POST',
        data: JSON.stringify(obj),
        url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
        headers: { 'Content-Type': 'application/json'},
    }).then((response)=>{
        if (response.status === 200||response.status === 201) {
            return response;
        } else {
            const error = response;
            return Promise.reject(error);
        }
    }).catch(err=>{
        const error = err;
        return Promise.reject(error);
    });
}

function userLogin(apiName,obj) {
    return axios({
    method: 'POST',
    data: JSON.stringify(obj),
    url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
    headers: { 'Content-Type': 'application/json'},
}).then(handleResponse)
}   

function activateAccount(apiName, obj) {    
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(obj)
    }).then(handleResponse);
}

function resendEmail(apiName, obj) {    
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(obj)
    }).then(handleResponse);
}

function userEmailVerify(apiName, obj) {    
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(obj)
    }).then(handleResponse);
}

function uploadProfileImg(apiName,userData) {
    var getFile=userData.files;
    var files = new FormData();
    files.append("profile_image", getFile);
    let token = sessionStorage.getItem('token');    
    return axios({  
        method: 'PUT',
        url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
        // headers: { 'Content-Type': 'multipart/form-data', 'Authorization':`Bearer ${token}`,"type": "formData"},
        headers: { 'Authorization':`Bearer ${token}`},
        data: files
    }).then(handleResponse);
}

//-- Change Password
function changePassword(apiName, userData) {
    let token = sessionStorage.getItem('token');
    return axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_BACKEND_URL+apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        data: userData
    }).then(handleResponse);
}

function handleResponse(response) {

    if (response.status === 200||response.status === 201) {
        return response;
    } else {
        const error = response;
        return Promise.reject(error);
    }
}