export const globalPoolUserConstants = {

    GET_POOL_USER_REQUEST: 'GET_POOL_USER_REQUEST',
    GET_POOL_USER_SUCCESS: 'GET_POOL_USER_SUCCESS',
    GET_POOL_USER_FAILURE: 'GET_POOL_USER_FAILURE',

    PURCHASE_FOR_POOL_USER_REQUEST: 'PURCHASE_FOR_POOL_USER_REQUEST',
    PURCHASE_FOR_POOL_USER_SUCCESS: 'PURCHASE_FOR_POOL_USER_SUCCESS',
    PURCHASE_FOR_POOL_USER_FAILURE: 'PURCHASE_FOR_POOL_USER_FAILURE',

    GET_AVAILABLE_PLAN_REQUEST: 'GET_AVAILABLE_PLAN_REQUEST',
    GET_AVAILABLE_PLAN_SUCCESS: 'GET_AVAILABLE_PLAN_SUCCESS',
    GET_AVAILABLE_PLAN_FAILURE: 'GET_AVAILABLE_PLAN_FAILURE',

    ACTIVATE_GLOBAL_USER_REQUEST: 'ACTIVATE_GLOBAL_USER_REQUEST',
    ACTIVATE_GLOBAL_USER_SUCCESS: 'ACTIVATE_GLOBAL_USER_SUCCESS',
    ACTIVATE_GLOBAL_USER_FAILURE: 'ACTIVATE_GLOBAL_USER_FAILURE',

    UPDATE_POOL_SETTING_REQUEST: 'UPDATE_POOL_SETTING_REQUEST',
    UPDATE_POOL_SETTING_SUCCESS: 'UPDATE_POOL_SETTING_SUCCESS',
    UPDATE_POOL_SETTING_FAILURE: 'UPDATE_POOL_SETTING_FAILURE',

    GET_POOL_REQUEST_REQUEST: 'GET_POOL_REQUEST_REQUEST',
    GET_POOL_REQUEST_SUCCESS: 'GET_POOL_REQUEST_SUCCESS',
    GET_POOL_REQUEST_FAILURE: 'GET_POOL_REQUEST_FAILURE',

    SEND_POOL_REQUEST_REQUEST: 'SEND_POOL_REQUEST_REQUEST',
    SEND_POOL_REQUEST_SUCCESS: 'SEND_POOL_REQUEST_SUCCESS',
    SEND_POOL_REQUEST_FAILURE: 'SEND_POOL_REQUEST_FAILURE',

    REFERAL_PURCHASE_REQUEST: 'REFERAL_PURCHASE_REQUEST',
    REFERAL_PURCHASE_SUCCESS: 'REFERAL_PURCHASE_SUCCESS',
    REFERAL_PURCHASE_FAILURE: 'REFERAL_PURCHASE_FAILURE'
}