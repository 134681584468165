import React, { useState,useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
export default function DeletePopup({onSelect}) {

    // const [deleteRes, setDeleteRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        onSelect(val)  
    }

    return (
        <Modal show={true} size="xxxxl" className="delete-popup" animation={true}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title>{siteConstants.GEN_ARE_YOU_SURE}</Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_NO}</Button>
                <Button variant="primary" onClick={() => handleSubmit('Yes')} type="button">{siteConstants.LABEL_YES}</Button>

            </Modal.Footer>

        </Modal>
    )
}
