import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { userActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function VideoFiles() {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [link, setLink] = useState();

    const { language, alert, getSetting } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language,
            getSetting: state.getSetting
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

        if (Object.keys(getSetting).length > 0) {
            if (getSetting.isGetSetting) {
                if (getSetting.getSetting.data) {
                    const { data } = getSetting.getSetting;
                 
                    setLink([
                        { registerLink: data.data.registerLink !== null && data.data.registerLink !== '' ? data.data.registerLink : '' },
                        { fundLink: data.data.fundLink !== null && data.data.fundLink !== '' ? data.data.fundLink : '' }
                    ])
                }
            }
        }
    }, [language, getSetting])

    useEffect(() => {
        dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
    }, [])

    useEffect(() => {
        if (Object.keys(alert).length > 0) {
    
          let tempErr = [];
          if (alert.type === 'alert-success') {
            tempErr = [{ 'type': alert.type, 'message': alert.message }]
          }
          else if (alert.type === 'alert-danger') {
            if (alert.message.errors !== undefined) {
    
              if (typeof alert.message.errors == 'object') {
    
                let keys = Object.keys(alert.message.errors)
    
                if (keys.length !== 0) {
                  keys.map(data => {
                    return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                  })
                }
              }
              else {
                tempErr = [{ 'type': alert.type, message: alert.message.errors }]
              }
            }
            else {
              tempErr = [{ 'type': alert.type, message: alert.message }]
            }
    
          }
    
          setAlertRes(tempErr);
          setTimeout(() => {
            setAlertRes('')
          }, 3000);
        }
    
    
      }, [alert]);

    return (
        <>
            {alertRes !== '' ? <Alert variant={alertRes.type === 'alert-danger' ? 'danger' : 'success'}
                className="alertBox">{alertRes.message} </Alert> : ''}
            <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>{siteConstants.LABEL_VIDEO_FILES}</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-page-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className="d-flex justify-content-around flex-wrap m-2 register-frame">
                                {link
                                    && link.length > 0 ?
                                    link.map((x, i) => {
                                        let tempArr = Object.keys(link[i])

                                        return (
                                            <div className="text-center m-2" key={i}>
                                                {/* <div className="card-body"> */}
                                                <h5 className="card-title">{i===0 ? 'How To Register ?' : 'How to Fund And buy position ?'}</h5>

                                                {x !== undefined && x !== '' ?
                                                    <iframe title = {i} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                                                  gyroscope; picture-in-picture" allowFullScreen src={x[tempArr[0]]}>
                                                    </iframe> : <img src="/assets/images/poster-video.png" alt="img"/>}
                                                {/* </div> */}
                                            </div>
                                        )
                                    })
                                    :
                                    <p>No record found</p>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    );
}