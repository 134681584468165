import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ContentAboutUs = () => {
    const [siteConstants, setSiteConstants] = useState([])

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language])

    return (
        <>
            <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>{siteConstants.LABEL_ABOUT_US}</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="top-about-sec">
                <div className="container f-16">
                    <div className="top-about-box-sec d-flex flex-wrap align-items-center">
                        <div>
                            <div className="p-5 text-center">
                                <div className="heading-box">
                                    <h2 className="sub-heading">Diamond Jubilee 7 is a Crowdfunding platform. </h2>
                                </div>
                                <p>Crowdfunding is a practice of funding a project or venture by raising money from several people who each contribute a small amount as gifts or donations. Individuals, charities, or companies can benefit from the platform to receive donations or gifts for their specific needs or causes.</p>
                                <p>Diamond Jubilee7 has adopted the proven principles of Susu, Merry Go Round and various community funding initiatives used around the world. We have created one of a kind Closed Matrix Platform, that removes all the drawback of the old systems, while taking advantage of security, transparency, and growing popularity of
                                    cryptocurrencies.</p>
                                <p>Diamond Jubilee 7 is a crowdfunding platform where you receive 50% of the donations from Round 1-3 and the other 50% is used for gifting in the next round. In round 4 you receive all donations due to you as the gifting process complete. The users will receive donations based on their qualifications from Round1-4.  At the end
                                    of the donations each qualified position will have received 77 430 Trons (TRX).

                                    You get started with just 150 Trons (TRX) to purchase one active position. You can purchase as many positions as you want within one account.
                                    You can receive donations from Round 1 without referrals, but:
                                </p>
                                <ul className='ul-layout'>
                                    <li>To receive from Round 2, you need to refer 1 active User.</li>
                                    <li>To receive from Round 3, you will need to refer a second active user</li>
                                    <li>To receive from Round 4, you will need to refer a third active user.</li>

                                </ul>
                                <p>In simple terms you just need 3 active users to full benefit from Round 1-4, otherwise without any
                                    referrals you will only receive from Round 1 only, for all purchased positions or Re-entries created by
                                    the system.</p>
                                <p>On completion:</p>
                                <ul className='ul-layout'>
                                    <li>Round 1: the user receives 120 trons in donations.</li>
                                    <li>Round 2: the user receives 1230 trons in donations.</li>
                                    <li>Round 3: the user receives 5520 trons in donations.</li>
                                    <li>Round 4: the user receives 70 560 trons in donations</li>
                                </ul>
                                <p>Total donations received from one purchased position of 150 Trons (TRX) is 77 430 Trons(TRX).

                                    The goal of Diamond Jubilee 7 is to make sure that everyone who joins has a chance to receive donations on the platform, hence.
                                    The system creates 50 Re-entries positions automatically for each user from round 1-4. </p>
                                <ul className='ul-layout'>
                                    <li>Round 1: 3 positions. </li>
                                    <li>Round 2: 7 positions.</li>
                                    <li>Round 3: 24 positions.</li>
                                    <li>Round 4: 16 positions.</li>
                                </ul>
                                <p>These re-entries are funded from each member donations and each qualified member still receives 77 430 Trons (TRX).

                                    These create a global spill over on the platform, which is unique to the Diamond Jubilee 7 Crowdfunding Platform.

                                    These Re-entries serve 3 important purposes:</p>
                                <ul className='ul-layout'>
                                    <li>Help others receive donations from Round 1-4</li>
                                    <li>They ensure the long sustainability of Diamond Jubilee 7.</li>
                                    <li>To maximise donations received by its members.</li>
                                </ul>
                                <p>The beauty of the platform is that these 50 re-entry positions will also create another 50 positions each, as they complete Round 1-4, thereby creating an additional 2500 positions.

                                    The total number of re-entry positions created will be 2550 from just one 150 TRX position, plus the initial position gives a total of 2551 positions, a user can receive donations from.</p>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>


        </>
    );
}

export default ContentAboutUs;