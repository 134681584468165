import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";

import { apiConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "../Common/Dashboard/Footer";
import Card from "react-bootstrap/Card";
import { alertActions, advertismentAction } from "../../actions";
import { useParams } from "react-router-dom";

const AdvertismentReport = () => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isAlreadySubmit, setIsAlreadySubmit] = useState(false)
    let { id } = useParams();

    // selector value store here 
    const { alert } = useSelector((state) => ({
        alert: state.alert,
    }));

    const formik = useFormik({
        initialValues: {
            name: "",
            subject: "",
            comment: "",
            adId: id
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Please Enter Name !!"),
            subject: Yup.string().required(
                "Please Enter Subject !!"
            ),
            comment: Yup.string().required(
                "Please Enter Comment !!"
            ),
            email: Yup.string().required(
                "Please Enter email !!"
            ),
            adId: Yup.string().required("Advertisment Id Required !!")
        }),

        onSubmit: (values) => {
            let obj = {
                adId: id,
                name: values.name,
                subject: values.subject,
                comment: values.comment,
                email: values.email
            };

            setIsLoading(true);
            setIsSubmitted(true);
            let adObj = JSON.stringify({ id: id })
            sessionStorage.setItem('advertismentRes', adObj)
            dispatch(advertismentAction.addAdvertismentReport(apiConstants.ADD_ADVERTISMENT_REPORT, obj));

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        },
    });

    // initial call 
    useEffect(() => {
        dispatch(alertActions.clear());
        return () => {
            setAlertRes("");
        };
    }, []);

    useEffect(() => {
        if (id) {
            let sessionTemp = sessionStorage.getItem('advertismentRes')

            if (sessionTemp && (id === JSON.parse(sessionTemp).id)) {
                setIsSubmitted(true)
                setIsAlreadySubmit(true)
            }
        }
    }, [id])

    // alert handler useeffect
    useEffect(() => {
        if (Object.keys(alert).length > 0) {
            let tempErr = [];

            if (alert.type === "alert-success") {
                tempErr = [{ type: alert.type, message: alert.message }];
            } else if (alert.type === "alert-danger") {
                if (alert.message.errors) {

                    if (typeof alert.message.errors === "object" && Object.keys(alert.message.errors).length > 0) {
                        let keys = Object.keys(alert.message.errors);
                        if (keys.length !== 0) {
                            keys.map((data) => {
                                tempErr.push({
                                    type: alert.type,
                                    message: alert.message.errors[data],
                                });
                            });
                        }
                    } else {
                        tempErr = [{ type: alert.type, message: alert.message.errors }];
                    }

                } else {
                    tempErr = [{ type: alert.type, message: alert.message }];
                }
            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes("");
            }, 3000);
        }
    }, [alert]);

    return (
        <>
            <section className="pre-registration-page d-flex justify-content-center " style={{ backgroundImage: `url('/assets/images/BGR.png')` }}>
                <div className="">
                    {alertRes !== undefined && alertRes !== null && alertRes !== ""
                        ? alertRes.length > 0
                            ? alertRes.map((data, index) => {
                                return (
                                    <Alert
                                        key={index}
                                        variant={
                                            data.type === "alert-danger" ? "danger" : "success"
                                        }
                                        className="alertBox"
                                    >
                                        {data.message}
                                    </Alert>
                                );
                            })
                            : ""
                        : ""}
                    <div className="dashboard-content p">
                        <div className="dash-body-blk">
                            <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>
                            {isSubmitted ?
                                <Card className="submit-card-layout div-center-align">
                                    {isAlreadySubmit ?
                                        <h3 className="text-secondary">Thank's your response is already submitted</h3>
                                        : <h3 className="text-secondary">Thank's your response is submitted</h3>}
                                </Card>
                                :
                                <Card>
                                    <Card.Body>
                                        <Card.Title bg="light">
                                            Advertisment Report
                                        </Card.Title>

                                        <section className="User-details-sec">
                                            <div className="top-use-details">
                                                <div className="container">
                                                    <div className="row d-flex justify-content-center">
                                                        <div className="col-md-9">
                                                            <div className="admin-form">

                                                                <form onSubmit={formik.handleSubmit}>
                                                                    <div className="form-row justify-content-between">

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>
                                                                                Name
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="name"
                                                                                value={formik.values.name}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            <label className="text-danger">
                                                                                {formik.errors.name &&
                                                                                    formik.touched.name
                                                                                    ? formik.errors.name
                                                                                    : ""}
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>
                                                                                Subject
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="subject"
                                                                                value={formik.values.subject}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            <label className="text-danger">
                                                                                {formik.errors.subject &&
                                                                                    formik.touched.subject
                                                                                    ? formik.errors.subject
                                                                                    : ""}
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>
                                                                                Email
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="email"
                                                                                value={formik.values.email}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            <label className="text-danger">
                                                                                {formik.errors.email &&
                                                                                    formik.touched.email
                                                                                    ? formik.errors.email
                                                                                    : ""}
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>
                                                                                Comment
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="comment"
                                                                                value={formik.values.comment}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            <label className="text-danger">
                                                                                {formik.errors.comment &&
                                                                                    formik.touched.comment
                                                                                    ? formik.errors.comment
                                                                                    : ""}
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    {/* Submit button */}
                                                                    {!isLoading ? (
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary"
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-new blue-layout"
                                                                            disabled={isLoading}
                                                                        >
                                                                            <i className="fas fa-spinner fa-spin"></i>
                                                                        </button>
                                                                    )}

                                                                </form>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </Card.Body>
                                </Card>
                            }
                        </div>

                        <Footer />
                    </div>

                </div>
            </section>

        </>
    );
};

export default AdvertismentReport;
