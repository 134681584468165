import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomerReview from '../AboutUs/CustomerReview';
import { menuActions } from '../../actions';
import { apiConstants } from '../../constants';

const Menu = (props) => {
    const [siteConstants, setSiteConstants] = useState([])
    const [details, setDetails] = useState('');
    const [links, setLink] = useState('')
    const dispatch = useDispatch();

    const vision = [
        { 'img': '/assets/images/branding.png', 'name': 'Branding', href: '#' },
        { 'img': '/assets/images/crowth.png', 'name': 'Growth', href: '#' },
        { 'img': '/assets/images/marketing-img.png', 'name': 'Marketing', href: '/marketing' },
        { 'img': '/assets/images/crow.png', 'name': 'Crowdfounding', href: '#' }
    ]

    const { language, getMenuById, getSetting } = useSelector(
        state => ({
            language: state.language,
            getMenuById: state.getMenuById,
            getSetting: state.getSetting
        })
    );

    useEffect(() => {
        const { match } = props;
        let param = match.params.menu;
        if (param) {
            dispatch(menuActions.getMenuById(apiConstants.GET_MENU_BY_ID, { 'id': param }))
        }
    }, [])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language])

    useEffect(() => {
        if (Object.keys(getMenuById).length > 0) {
            if (getMenuById.isGetMenuById) {
                if (getMenuById.getMenuById.data) {
                    const { data } = getMenuById.getMenuById;
                    setDetails(data.data)
                }
            }
        }
    }, [getMenuById])

    useEffect(() => {
        if (Object.keys(getSetting).length > 0) {
            if (getSetting.isGetSetting) {
                if (getSetting.getSetting.data) {

                    const { data } = getSetting.getSetting;

                    setLink({
                        facebookLink: data.data.facebookLink !== null && data.data.facebookLink !== '' ? data.data.facebookLink : '#',
                        telegramLink: data.data.telegramLink !== null && data.data.telegramLink !== '' ? data.data.telegramLink : '#',
                        instagramLink: data.data.instagramLink !== null && data.data.instagramLink !== '' ? data.data.instagramLink : '#',
                        linkedInLink: data.data.linkedInLink !== null && data.data.linkedInLink !== '' ? data.data.linkedInLink : '#',
                        twitterLink: data.data.twitterLink !== null && data.data.twitterLink !== '' ? data.data.twitterLink : '#',
                        tiktokLink: data.data.tiktokLink !== null && data.data.tiktokLink !== '' ? data.data.tiktokLink : '#',
                    })
                }
            }
        }
    }, [getSetting])

    return (
        <>
            <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>{details && details.menuName ? details.menuName : ''}</h1>
                        </div>
                    </div>
                </div>
            </section>

            {
                details && details.menuName.toLowerCase() === "about-us" ?
                    <section className="team-sec">
                        <div className="container">
                            <div className="row">

                                <div className="top-about-box-sec d-flex flex-wrap align-items-center row">
                                    <div className="text-box col-lg-6">
                                        <div className="inner-text-box">
                                            <div className="heading-box">

                                                <h2 className="sub-heading">Maxsam Madume</h2>
                                                <small className='text-info f-16'><b>The CEO, Creator and Co-Founder</b></small>
                                            </div>
                                            <p className='f-16'>
                                                Maxsam has been in the Networking Marketing industry space for almost 20 years and is a retired Biomedical Scientist.
                                                Born in Zimbabwe and now based in United Kingdom,He believes in God and his purpose is to help or assist others.
                                                He has used his vast experience and knowledge, especially from the Crowdfunding space to bring a unique, transparent,
                                                long term and sustainable program.
                                                He has built tons of teams is various previous Multi-Level Marketing businesses.  From his knowledge, experience, and a
                                                history of being let down by a lot of companies, his goal is to build a sustainable program for the average person.
                                                It is tailored for individuals to raise funds through crowdfunding.
                                                Be it for: Children’s College fees, Retirement, New Home, Mortgage, Holiday, Tuition, Charity etc.
                                                The ultimate goal of the company  is to help millions of people in Africa and other parts of the world get-out of poverty through the efforts of the Diamond Jubilee 7 programme.

                                            </p>
                                        </div>
                                    </div>
                                    <div className="img-box col-lg-6">
                                        {/* <div><img src="/assets/images/about-sec-01.jpg" alt="" /></div> */}
                                        <div className="team-box">
                                            <div className="team-member ">
                                                <img src="/assets/images/owner.jpg" alt="" />
                                                <div className="social-icons">
                                                    <ul>
                                                        <li><a href={links.facebookLink ? links.facebookLink : '#'}><i className="fab fa-facebook-f"></i></a></li>
                                                        <li><a href={links.twitterLink ? links.twitterLink : '#'}><i className="fab fa-twitter"></i></a></li>
                                                        <li><a href={links.linkedInLink ? links.linkedInLink : '#'}><i className="fab fa-linkedin-in"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    : ''
            }

            <div className={details && details.menuName.toLowerCase() === "about-us" ? 'div-reverse' : ''}>
                {details && details.content && details.content.length > 0 ?
                    details.content.map((x, index) => {
                        let bgColor = "white";
                        if (details && details.menuName.toLowerCase() === "about-us") {
                            bgColor = "none"
                        }
                        if (index % 2 === 0) {
                            return (
                                <section className="top-about-sec" key={index}>
                                    <div className="container">

                                        <div className="top-about-box-sec d-flex flex-wrap align-items-center justify-content-start">
                                            <div className="text-box p-2">
                                                <div className="inner-text-box">
                                                    <div className="heading-box">
                                                        <p className="sub-heading">{x.heading ? x.heading : ''}</p>
                                                        <h2 className='f-17'>{x.subHeading ? x.subHeading : ''}</h2>
                                                    </div>
                                                    <p className='f-16'>{x.description ? x.description : ''}</p>
                                                    {details.menuName.toLowerCase() === "about-us" ?
                                                        <a href="/content-about-us" className="btn theme-btn btn-new mt-3">{siteConstants.LABEL_READ_MORE}</a>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="img-box">
                                                <div><img style={{ 'maxHeight': '500px' }}
                                                    src={x.image ? x.image : details.menuName.toLowerCase() === "mission-vision" ? '../assets/images/our-mission-bg.jpg' : '../assets/images/about-sec-01.jpg'}
                                                    alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )


                        }
                        else {

                            return (
                                <section className="sec-block marketing-sec">
                                    <div className="d-flex flex-wrap align-items-center">
                                        {details.menuName.toLowerCase() === "mission-vision" ?
                                            <div className="vision_box_right" style={{
                                                backgroundColor: bgColor,
                                                zIndex: 1
                                            }}>
                                                <ul className="d-flex flex-wrap align-items-center text-center">
                                                    {vision !== undefined && vision.length !== 0 ?
                                                        vision.map((data, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <a href={data.href}>
                                                                        <img src={data.img} alt='' />
                                                                        <p><strong>{data.name}</strong></p>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                        : ''}
                                                </ul>
                                            </div>
                                            :
                                            <div className="img-box">
                                                <div><img style={{ 'maxHeight': '500px' }} src={x.image ? x.image : '../assets/images/about-sec-02.jpg'} alt="" /></div>
                                            </div>
                                        }
                                        <div className="text-box p-2 text-light">
                                            <div className="inner-text-box">
                                                <div className="heading-box heading-dark">
                                                    <p className="sub-heading">{x.heading ? x.heading : ''}</p>
                                                    <h2 className='f-17'>{x.subHeading ? x.subHeading : ''}</h2>
                                                </div>
                                                <p className='f-16'>{x.description ? x.description : ''}</p>
                                                {details.menuName.toLowerCase() === "about-us" ?
                                                    <a href="/content-about-us" className="btn theme-btn btn-new mt-3">{siteConstants.LABEL_READ_MORE}</a>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                </section>)

                        }
                    })
                    : ''}
            </div>

            <CustomerReview />

            {/* <NewsLetter/> */}

            {/* <RaiseFund/> */}

        </>
    );
}

export default Menu;