export const messageConstants = {

  SEARCH_SENDER_REQUEST: 'SEARCH_SENDER_REQUEST',
  SEARCH_SENDER_SUCCESS: 'SEARCH_SENDER_SUCCESS',
  SEARCH_SENDER_FAILURE: 'SEARCH_SENDER_FAILURE',
  SEARCH_SENDER_DEFAULT: 'SEARCH_SENDER_DEFAULT',

  SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',

  GET_CHAT_BY_USER_REQUEST: 'GET_CHAT_BY_USER_REQUEST',
  GET_CHAT_BY_USER_SUCCESS: 'GET_CHAT_BY_USER_SUCCESS',
  GET_CHAT_BY_USER_FAILURE: 'GET_CHAT_BY_USER_FAILURE',

  GET_ALL_MESSAGED_USER_REQUEST: 'GET_ALL_MESSAGED_USER_REQUEST',
  GET_ALL_MESSAGED_USER_SUCCESS: 'GET_ALL_MESSAGED_USER_SUCCESS',
  GET_ALL_MESSAGED_USER_FAILURE: 'GET_ALL_MESSAGED_USER_FAILURE'

}