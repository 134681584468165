export const pushNotificationConstants = {

    GET_PUSH_NOTIFICATION_LIST_REQUEST: 'GET_PUSH_NOTIFICATION_LIST_REQUEST',
    GET_PUSH_NOTIFICATION_LIST_SUCCESS: 'GET_PUSH_NOTIFICATION_LIST_SUCCESS',
    GET_PUSH_NOTIFICATION_LIST_FAILURE: 'GET_PUSH_NOTIFICATION_LIST_FAILURE',

    GET_PUSH_NOTIFICATION_COUNT_REQUEST: 'GET_PUSH_NOTIFICATION_COUNT_REQUEST',
    GET_PUSH_NOTIFICATION_COUNT_SUCCESS: 'GET_PUSH_NOTIFICATION_COUNT_SUCCESS',
    GET_PUSH_NOTIFICATION_COUNT_FAILURE: 'GET_PUSH_NOTIFICATION_COUNT_FAILURE',

    GET_PUSH_NOTIFICATION_BY_ID_REQUEST: 'GET_PUSH_NOTIFICATION_BY_ID_REQUEST',
    GET_PUSH_NOTIFICATION_BY_ID_SUCCESS: 'GET_PUSH_NOTIFICATION_BY_ID_SUCCESS',
    GET_PUSH_NOTIFICATION_BY_ID_FAILURE: 'GET_PUSH_NOTIFICATION_BY_ID_FAILURE',

    READ_PUSH_NOTIFICATION_REQUEST: 'READ_PUSH_NOTIFICATION_REQUEST',
    READ_PUSH_NOTIFICATION_SUCCESS: 'READ_PUSH_NOTIFICATION_SUCCESS',
    READ_PUSH_NOTIFICATION_FAILURE: 'READ_PUSH_NOTIFICATION_FAILURE',

    HIDE_PUSH_NOTIFICATION_REQUEST: 'HIDE_PUSH_NOTIFICATION_REQUEST',
    HIDE_PUSH_NOTIFICATION_SUCCESS: 'HIDE_PUSH_NOTIFICATION_SUCCESS',
    HIDE_PUSH_NOTIFICATION_FAILURE: 'HIDE_PUSH_NOTIFICATION_FAILURE',

    ARCHIVE_PUSH_NOTIFICATION_REQUEST: 'ARCHIVE_PUSH_NOTIFICATION_REQUEST',
    ARCHIVE_PUSH_NOTIFICATION_SUCCESS: 'ARCHIVE_PUSH_NOTIFICATION_SUCCESS',
    ARCHIVE_PUSH_NOTIFICATION_FAILURE: 'ARCHIVE_PUSH_NOTIFICATION_FAILURE'
}