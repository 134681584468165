import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, pushNotificationActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
var momentTimeZone = require('moment-timezone');
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const Notification = ({ history }) => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [notificationList, setNotificationList] = useState([]);
    const [notificationDetails, setNotificationDetails] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpages] = useState(1);
    const [count, setCount] = useState(false)

    const { alert,  getPushNotifictaionList, getPushNotifictaionCount } = useSelector(
        state => ({
            alert: state.alert,
            getPushNotifictaionList: state.getPushNotifictaionList,
            getPushNotifictaionCount: state.getPushNotifictaionCount
        })
    );

    useEffect(() => {
       
        if (getPushNotifictaionList !== undefined && getPushNotifictaionList !== null) {
            if (Object.keys(getPushNotifictaionList).length > 0) {
                if (getPushNotifictaionList.getPushNotifictaionList !== undefined) {
                    if (getPushNotifictaionList.getPushNotifictaionList.data !== undefined) {

                        if (getPushNotifictaionList.getPushNotifictaionList.data.data.data.length > 0) {
                            setNotificationList(getPushNotifictaionList.getPushNotifictaionList.data.data.data);
                            setTotalpages(getPushNotifictaionList.getPushNotifictaionList.data.totalPages);
                        }
                    }
                }
            }
        }

        if (getPushNotifictaionCount !== undefined && getPushNotifictaionCount !== null) {
            if (Object.keys(getPushNotifictaionCount).length > 0) {
                if (getPushNotifictaionCount.getPushNotifictaionCount !== undefined) {
                    if (getPushNotifictaionCount.getPushNotifictaionCount.data !== undefined) {
                        if (getPushNotifictaionCount.getPushNotifictaionCount.data.readDetails) {
                           
                            setNotificationDetails(getPushNotifictaionCount.getPushNotifictaionCount.data.readDetails);
                        }
                    }
                }
            }
        }
    }, [ getPushNotifictaionList, getPushNotifictaionCount]);

    useEffect(() => {
        let obj = {
            page: 1,
            perPage: 10,
            orderByAscDesc: -1,
            limitQuery: false
        };
        getNotificationList(obj)
        setCount(true)
        dispatch(alertActions.clear());
        return () => {
            setAlertRes('')
        }
    }, []);

    const getNotificationList = (obj) => {
        dispatch(pushNotificationActions.getPushNotificationCount(apiConstants.UNREAD_NOTIFICATION));
        dispatch(pushNotificationActions.getPushNotifictaionList(apiConstants.GET_USER_NOTIFICATION, obj));
    }

    useEffect(() => {
        if (alert !== undefined && alert !== null) {
            if (Object.keys(alert).length > 0) {

                let tempErr;
                if (alert.type === 'alert-success') {
                    tempErr = [{ 'type': alert.type, 'message': alert.message }]
                }
                else {
                    if (alert.message.errors !== undefined && alert.message.errors !== null) {
                        let keys = Object.keys(alert.message.errors)
                        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
                    }
                }

                setAlertRes(tempErr);
                setTimeout(() => {
                    setAlertRes('')
                }, 3000);
            }
        }
    }, [alert]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        let obj = {
            page: pageNumber,
            perPage: 10,
            orderByAscDesc: -1,
            limitQuery: false
        };
        getNotificationList(obj)
    }

    const viewHandler = (id) => {
        dispatch(pushNotificationActions.readPushNotifictaion(apiConstants.READ_USER_NOTIFICATION, { _id: id }));
        history.push({ pathname: '/notification-details', state: { detail: id } })
    }

    const hideHandler = (id) => {
        let obj = {
            page: 1, perPage: 10, orderByAscDesc: -1, limitQuery: false
        };
        dispatch(pushNotificationActions.hidePushNotifictaion(apiConstants.HIDE_USER_NOTIFICATION, { _id: id }));
        setTimeout(() => {
            getNotificationList(obj)
        }, 500);
    }

    const archivehandler = () => {
        let obj = {
            page: 1, perPage: 10, orderByAscDesc: -1, limitQuery: false
        };
        dispatch(pushNotificationActions.archivePushNotifictaion( apiConstants.ARCHIVE_USER_NOTIFICATION ));
        setTimeout(() => {
            getNotificationList(obj)
        }, 500);
    }

    let totalPage = typeof notificationList !== 'undefined' && Object.keys(notificationList).length > 0 ? totalPages : 0;

    return (
        <>
            <div className="main-wrap">
                <Header changeCount={count} />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== undefined && alertRes !== null && alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className="card dash-card tag-block p-3 box_shadow">
                                                        <div className="row">
                                                            <div className="col-lg-9">  <h2 className="heading-top">Notification Listing</h2>  </div>
                                                        </div>

                                                        <div className="owned-sec scroll-layout">
                                                            <div className='p-2 text-right'>
                                                               <small className='text-primary' onClick={()=> archivehandler()}>Undo archive message</small>
                                                            </div>
                                                            {notificationList !== undefined ?
                                                                notificationList.length > 0 ? notificationList.map((data, index) => {
                                                                    let colorNotification = notificationDetails && notificationDetails.read_notification.includes(data._id) ? ' bg-unread-msg' : '';
                                                                    let blockNotification = notificationDetails && notificationDetails.hide_notification.includes(data._id) ? ' unread-msg-display' : '';
                                                                  
                                                                    return (
                                                                        <div key={index} className={"notify-cursor shadow p-3 mb-2 bg-body rounded d-flex align-items-center justify-content-between " +
                                                                         (colorNotification + blockNotification)}>
                                                                            <span className='d-flex flex-column' onClick={() => viewHandler(data._id)}><h6 className='mr-5 f-18'>{data.notification_subject}</h6>
                                                                                <small className='text-secondary'>Click here to view message ...</small></span>
                                                                            <p className='m-1'>{momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                                                                momentTimeZone(data.created_at).tz(momentTimeZone.tz.guess()).format('DD MMMM YYYY')
                                                                                :
                                                                                momentTimeZone(data.created_at).tz(timeZone).format('DD MMMM YYYY')}
                                                                                <span className='ml-5'>
                                                                                    <small className='text-primary' onClick={() => hideHandler(data._id)}>Hide</small>
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })
                                                                    : <p>No Record Found </p> : ''}


                                                            {typeof notificationList !== 'undefined' ?
                                                                <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                                                                : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>

            </div >

        </>
    );
}

export default Notification;
