import React from 'react';
import { Route } from 'react-router-dom';
import Header from "../../Common/Header/Header";
import Footer from "../../Common/Footer/Footer";

const MainLayout = ({ component: Component, ...rest }) => (

    <Route {...rest} render={props => (
        <><Header /><Component {...props} /> <Footer /> </>
    )} />
    // <Route {...rest} render={props => (
    //    props ? 
    //    <><Header /><Component {...props} /> <Footer /> </> 
    //    : <Redirect to={{ pathname: '*', state: { from: props.location } }} />
    // )} /> 
)
export default MainLayout;