import React, { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import { userActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState([])

  const { alert, language } = useSelector(
    state => ({
      alert: state.alert,
      language: state.language
    })
  );

  const onChangeHandler = (e) => {
    setEmail(e.target.value)
  }

  const handleErr = (msg) => {
    setEmailErr(msg);
    setTimeout(() => {
      setEmailErr('');
    }, 2000);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === '') {
      handleErr('ERR_BLANK_EMAIL')
    }
    else {
      let obj = { "email": email }
      dispatch(userActions.sendEmail(apiConstants.FORGOT_PASSWORD, obj));
    }
  }

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr = [];
      if (alert.type === 'alert-success' && alert.message !== undefined) {
        tempErr = [{ 'type': alert.type, 'message': alert.message }]
      }
      else if (alert.type === 'alert-danger' && alert.message !== undefined) {
     
        if (alert.message.errors !== undefined && alert.message.errors !== null) {

          if (typeof alert.message.errors == 'object') {

            let keys = Object.keys(alert.message.errors)

            if (keys.length !== 0) {
              keys.map(data => {
                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
              })
            }
          }
          else {

            tempErr = [{ 'type': alert.type, message: alert.message.errors }]
          }
        }
        else {
        
          if (alert.message !== undefined && alert.message !== null) {
            if (alert.message.msg !== undefined && alert.message.msg !== null) {
              tempErr = [{ 'type': alert.type, message: alert.message.msg }]
            } else {
              tempErr = [{ 'type': alert.type, message: alert.message }]
            }
          }
          else {

            // tempErr = [{ 'type': alert.type, message: alert }]
          }
        }

      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 4000);
    }

    // if (Object.keys(alert).length > 0) {
    //   let tempErr;
    //   if (alert.type === 'alert-success') {
    //     tempErr = { 'type': alert.type, 'message': alert.message }
    //   }
    //   else {
    //     if (alert.message.errors !== null && alert.message.errors !== undefined) {
    //       let keys = Object.keys(alert.message.errors)
    //       tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
    //     }
    //     else if (alert.message.msg !== undefined) {
    //       tempErr = { 'type': alert.type, message: alert.message.msg }
    //     }
    //   }

    //   setAlertRes(tempErr);
    //   setTimeout(() => {
    //     setAlertRes('')
    //   }, 3000);
    // }
  }, [alert]);

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language])

  return (
    <>
      {/* {alertRes !== '' ? <Alert style={{'marginLeft':'50%'}} variant={alertRes.type === 'alert-danger' ? 'danger' : 'success'} 
        className="alertBox">{alertRes.message}
        </Alert> : ''} */}
      {alertRes !== '' ?
        alertRes.length > 0 ? alertRes.map((data, index) => {
          return (
            <Alert style={{ 'marginLeft': '50%' }} key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
              className="alertBox">
              {data.message}
            </Alert>)
        }) : '' : ''}
      <section className="account-sec">
        <div className="inner-account d-flex flex-wrap align-items-center">
          <div className="account-box" style={{ "backgroundImage": "url(/assets/images/account-side-bg.jpg)" }}>
            <div className="logo-side">
              <Nav.Link>
                <img src="/assets/images/logo1.png" alt="logo" />
              </Nav.Link>
            </div>
          </div>
          <div className="account-box-right">
            <div className="account-left-box">
              <h1>{siteConstants.LABEL_FORGET_PASSWORD}</h1>
              <p className="text-left">{siteConstants.LABEL_FORGET_PASSWORD_MESSAGE}</p>
              <form>
                <div className="input-group mb-3">
                  <div className="input-group-img">
                    <i className="far fa-envelope"></i>
                  </div>
                  <input type="email" className="form-control" placeholder={siteConstants.LABEL_EMAIL} name="email" onChange={(e) => onChangeHandler(e)} />
                </div>
                <span className="text-danger error-text">{siteConstants[emailErr]}</span>

                <button type="submit" onClick={(e) => onSubmit(e)} className="btn btn-primary mt-3 d-block">{siteConstants.LABEL_RESET}</button>
              </form>

            </div>

            <div className="account-sec">
              <p>{siteConstants.LABEL_BACK_TO}<a href="/login" className="ml-2">{siteConstants.LABEL_LOGIN}</a></p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ForgotPassword;