import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
var moment = require('moment'); // require

export default function CommentPopup({ onSelect, editData ,sendComment }) {

    const [values, setValues] = useState('');
    const [message, setMessage] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        setValues({ ...values,comment: editData.comment, ticketId: editData._id, 'priority': editData.priority, 'description': editData.description })
        setTimeout(() => {
            let elem = document.getElementById('commentDiv');
            elem.scrollTop =  elem.scrollHeight + elem.clientHeight;
        }, 500); 
    }, [editData])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        if (val === "Yes") {
            let obj = { 'priority': values.priority, 'description': values.description }
            onSelect({ res: true, data: obj, ticketId: values.ticketId })
        }
        else {
            onSelect({ res: false, obj: val })
        }
    }

    const sendMessage = () => {
        sendComment({'_id': values.ticketId , 'message' : message});
        setTimeout(() => {
            setMessage('');
        }, 1000);

    }

    return (
        <Modal show={true} size="lg" animation={true}>
            <Modal.Header>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_COMMENT_ON_TICKET} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='scroll-layout' id="commentDiv" style={{ 'height': '300px', 'overflow': 'auto' }}>
                    {values.comment && values.comment.length > 0 ?
                        values.comment.map((x,index) => {
                            return (
                                x.type === "reply" ?
                                    <div key={index} className="reply-bg border p-2 bg-gradient-warning mb-2 shadow-sm bg-white rounded">
                                        <p className="mb-1 mt-1 text-primary">{x.message}</p>
                                        <small className='text-primary'>{moment(x.createdAt).fromNow()}</small>
                                    </div> :
                                    <div key={index} className="comment-bg border p-2 m-1 shadow-sm bg-white rounded">
                                        <p className="mb-1 mt-1">{x.message}</p>
                                        <small>{moment(x.createdAt).fromNow()}</small>
                                    </div>)
                        })
                        : ''}
                </div>
                <span className='d-flex align-item-center mt-4'>
                    <input className='form-control w-95' placeholder='Type your message...' type="text" name="message" value={message} onChange={(e) => setMessage( e.target.value)}
                    /><i className="far fa-paper-plane w-5 p-2 border" onClick={() => sendMessage()}></i>
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
            </Modal.Footer>

        </Modal>
    )
}
