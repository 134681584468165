import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { advertismentAction, alertActions } from "../../actions";
import { apiConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Common/Dashboard/Header";
import Footer from "../Common/Dashboard/Footer";
import Sidebar from "../Common/Dashboard/Sidebar";
import { bannerTypeConstants } from '../../constants';
import Pagination from '../Common/Pagination/Pagination';

const BannerList = () => {
    const { BANNER_FIXED_HEIGHT } = bannerTypeConstants;
    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState("");
    const [promoList, setPromoList] = useState('');
    const [referalLink, setReferalLink] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpages] = useState(1);

    const { alert, getPromoBannerListing, generateReferalLink } = useSelector((state) => ({
        alert: state.alert,
        getPromoBannerListing: state.getPromoBannerListing,
        generateReferalLink: state.generateReferalLink
    }));

    const fetchPromoBanner = (pageNumber) => {
        let obj = {
            page: pageNumber,
            perPage: 10
        };
        dispatch(advertismentAction.getPromoBannerListing(apiConstants.GET_PROMO_BANNER_LIST, obj));

    }

    useEffect(() => {
        dispatch(alertActions.clear());
        fetchPromoBanner(1)
        return () => {
            setAlertRes("");
        };
    }, []);

    useEffect(() => {
        if (Object.keys(alert).length > 0) {
            let tempErr = [];
            if (alert.type === "alert-success") {
                tempErr = [{ type: alert.type, message: alert.message }];
            }
            else if (alert.type === "alert-danger") {
                if (alert.message.errors) {
                    let keys = Object.keys(alert.message.errors);
                    if (keys.length !== 0) {
                        keys.map((data) => {
                            tempErr.push({
                                type: alert.type,
                                message: alert.message.errors[data],
                            });
                        });
                    }
                } else {
                    tempErr = [{ type: alert.type, message: alert.message }];
                }
            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes("");
            }, 8000);
        }
    }, [alert]);

    useEffect(() => {
        if (Object.keys(generateReferalLink).length > 0) {
            if (generateReferalLink.isGenerateLink) {
                let code = generateReferalLink.generateLink.data.data
                if (code) {
                    setReferalLink(process.env.REACT_APP_FRONTEND_INFO_URL + '/?refer=' + code.username)
                }

            }
        }
    }, [generateReferalLink])

    useEffect(() => {

        if (Object.keys(getPromoBannerListing).length > 0) {
            if (getPromoBannerListing.isGetPromoBannerListing) {
                if (getPromoBannerListing.getPromoBannerListing.data) {
                    const { data } = getPromoBannerListing.getPromoBannerListing;
                    setPromoList(data.data.data)
                    setTotalpages(data.data.totalPages);
                }
            }
        }

    }, [getPromoBannerListing]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchPromoBanner(pageNumber)
    }

    let totalPage = typeof promoList && Object.keys(promoList).length > 0 ? totalPages : 0;

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== undefined && alertRes !== null && alertRes !== ""
                            ? alertRes.length > 0
                                ? alertRes.map((data, index) => {
                                    return (
                                        <Alert
                                            key={index}
                                            variant={
                                                data.type === "alert-danger" ? "danger" : "success"
                                            }
                                            className="alertBox"
                                        >
                                            {data.message}
                                        </Alert>
                                    );
                                })
                                : ""
                            : ""}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className="card dash-card tag-block p-3 box_shadow">
                                                        <div className="row">
                                                            <div className="col-lg-9">
                                                                <h2 className="heading-top">
                                                                    Promotional Tools
                                                                </h2>
                                                            </div>
                                                        </div>

                                                        {/* Banners Link */}
                                                        <div className="owned-sec d-flex flex-column text-secondary f-18">
                                                            <h4 className="mb-2 mt-2">Banner Links</h4>


                                                            <div className="flex flex-wrap p-2">
                                                                {
                                                                    promoList && promoList.length > 0 ?
                                                                        promoList.map((x) => {

                                                                            return (
                                                                                <>
                                                                                <a href={referalLink ? referalLink : ''} target='blank'>
                                                                                    <img className="mb-3 promo-border-img p-1" src={process.env.REACT_APP_BACKEND_URL + '/' + x.fileLink} alt="logo" />
                                                                                </a>
                                                                                    {/* input link of image and url */}
                                                                                    <textarea id="promo-textarea" className="form-control"
                                                                                        readOnly="true"
                                                                                        value={`<a href="${referalLink ? referalLink : ''}"><img src="${process.env.REACT_APP_BACKEND_URL + '/' + x.fileLink}" border="0"></a>`} />
                                                                                        </>
                                                                            )
                                                                        })
                                                                        : ''
                                                                }
                                                                {typeof promoList !== 'undefined' ?
                                                                    <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                                                                    : ''}

                                                            </div>
                                                        </div>

                                                        {/* Text Links */}
                                                        {/* <div className="owned-sec text-secondary f-18">
                                                            <h4 className="mb-2 mt-2">Text Link</h4>

                                                            <div className="d-flex flex-wrap promo-border p-2">
                                                                <div className='text-ellipsis card mb-3 text-dark bg-info mx-2' style={{ height: BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                                                                    <div className="card-body text-white text-center">
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Turn 300 Trons (TRX) into over One million Trons.
                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Join Diamond Jubilee 7  Now
                                                                        </small>
                                                                    </div>
                                                                </div>

                                                                <div className='text-ellipsis card mb-3 text-dark bg-info mx-2' style={{ height: BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                                                                    <div className="card-body text-white text-center">
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Turn 300 Trons (TRX) into over One million Trons (TRX) with Diamond Jubilee 7 Crowdfunding.

                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            The World Most Unique Crowdfunding Platform
                                                                        </small>
                                                                    </div>
                                                                </div>

                                                                <div className='text-ellipsis card mb-3 text-dark bg-info mx-2' style={{ height: BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                                                                    <div className="card-body text-white text-center">
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Register  For free now with DJ7.
                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            One of our community members will activate  your account for Free!!!
                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Get started Now!!!
                                                                        </small>
                                                                    </div>
                                                                </div>

                                                                <div className='text-ellipsis card mb-3 text-dark bg-info mx-2' style={{ height: BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                                                                    <div className="card-body text-white text-center">
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Turn less than $30 into over $9000, over and over again.
                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Join DJ7 Now.
                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            The World Best Crowdfunding Platform.
                                                                        </small>
                                                                    </div>
                                                                </div>

                                                                <div className='text-ellipsis card mb-3 text-dark bg-info mx-2' style={{ height: BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                                                                    <div className="card-body text-white text-center">
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            You can receive over  $1000 without recruiting anyone, with DJ7.
                                                                        </small>
                                                                        <small className="card-text text-white banners-text-overflow">
                                                                            Get started now  from just less than $30 (300 Trons)
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                  
                                                        </div> */}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        </>
    );
};

export default BannerList;
