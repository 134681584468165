import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { language } from './language.reducer';
import {
  accountActivation, resendEmail,userEmailVerify, checkReferLink, getAdminReferal, getCountryList, getProfile, userLogin, getCount, sendTokenEmail,
  uploadProfileImg, userProfile, generateReferalLink, getUsersList, verifyUser, getSetting, getFounderPosition, userSignup, userOtp, nextInLineUsers, nextInLineClassicUsers
} from './users.reducer';
import { getNewMember, getPurchasedPosition, getPositionView, positionByNumber, getReEntryPosition, addPosition, getEarnedIncome, getPendingPositionCount } from './genealogy.reducer';
import { getWallet, getTransaction, addWallet, getBalance, getWalletUser, sendMoney, withdrawMoney, recoverWallet, getWithdrawTransaction, updateWalletKey } from './wallet.reducer';
import { getNominee, updateNominee, createNominee } from './nominee.reducer';
import { getFaqList, getCompetition, getMarketing, getTestimonials } from './feedback.reducer';
import { getSupportTicket, getSupportTicketById } from './support.reducer';
import { getMenu, getMenuById } from './menu.reducer';
import { getPlan } from './plan.reducer';
import { getPushNotifictaionCount, getPushNotifictaionList, getPushNotificationById } from './pushNotification.reducer';
import { getPoolUser, getAvailablePlan, getPoolRequest } from './globalPoolUser.reducer';
import { getSenderUser, getChatByUserId, getAllMessagedUser } from './message.reducer';
import {
  getAdvertismentList, getRandomAdvertismentList, getCreditList, getAdSetting, getFooterRandomBanner, getBannerFooterRandomBanner, getPromoBannerListing,
  getTextRandomAdvertismentList,addAdvertisment
} from './advertisment.reducer';

const rootReducer = combineReducers({
  alert,
  recoverWallet,
  getWithdrawTransaction,
  accountActivation,
  resendEmail,
  userEmailVerify,
  nextInLineUsers,
  nextInLineClassicUsers,
  sendTokenEmail,
  checkReferLink,
  language,
  getAdminReferal,
  getCount,
  getCountryList,
  userProfile,
  uploadProfileImg,
  getProfile,
  generateReferalLink,
  getUsersList,
  verifyUser,
  getSetting,
  getFounderPosition,
  userLogin,
  userSignup,
  userOtp,
  getNewMember,
  getPurchasedPosition,
  getPositionView,
  positionByNumber,
  getReEntryPosition,
  addPosition,
  getEarnedIncome,
  getPendingPositionCount,

  // wallet reducer
  getWallet,
  getTransaction,
  addWallet,
  getBalance,
  withdrawMoney,
  updateWalletKey,

  //nominee reducer
  getNominee, updateNominee, createNominee,
  getWalletUser,
  sendMoney,

  // faq reducer
  getFaqList,
  getCompetition,
  getMarketing,
  getTestimonials,

  // support reducer
  getSupportTicket,
  getSupportTicketById,

  // menu reducer
  getMenu, getMenuById,

  // plan reducer 
  getPlan,

  // push notification reducer
  getPushNotifictaionCount, getPushNotifictaionList, getPushNotificationById,

  // pool user 
  getPoolUser,
  getAvailablePlan,
  getPoolRequest,

  // internal messaging system 
  getSenderUser,
  getChatByUserId,
  getAllMessagedUser,

  //advertisment reducer
  getAdvertismentList,
  getRandomAdvertismentList,
  getCreditList,
  getAdSetting,
  getFooterRandomBanner,
  getBannerFooterRandomBanner,
  getPromoBannerListing,
  getTextRandomAdvertismentList,
  addAdvertisment
});

export default rootReducer;
