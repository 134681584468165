import React, { Component } from 'react';

export class NotFound extends Component {

    render() {
        return (
            <div className="container">
                <div className="row d-flex justify-content-center text-center align-item-center">
                    <div className="col-md-4 col-md-offset-4">
                        <div className="dashboard-wraper">
                            <div className="m-2 text-info">
                                <h6>Oops...</h6>
                                <h1>404</h1>
                                <h3 className="panel-title" >Page Not Found</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}


export default NotFound;