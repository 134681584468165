import { planConstants } from '../constants';

//-- get the list of plan
export function getPlan(state = {}, action) {
  switch (action.type) {
    case planConstants.GET_PLAN_REQUEST:
      return {
        isGetPlan: false
      };

    case planConstants.GET_PLAN_SUCCESS:
      return { isGetPlan:true , getPlan:action.successAction};

    case planConstants.GET_PLAN_FAILURE:
      return {
        isGetPlan: false,
      };

    default:
      return state
  }
}
