import { nomineeConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "./";

export const nomineeActions = {
  getNominee,
  updateNominee,
  createNomenee
}

function getNominee(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(nomineeConstants.GET_NOMINEE_REQUEST, eventData));
    commonService.getDataWithToken(apiName, eventData).then(
      nominee => {
        dispatch(commonAction.success(nomineeConstants.GET_NOMINEE_SUCCESS, nominee));
      },
      error => {
        dispatch(commonAction.failure(nomineeConstants.GET_NOMINEE_FAILURE, error));
      }
    );
  }
}

function updateNominee(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(nomineeConstants.UPDATE_NOMINEE_REQUEST, eventData));
    commonService.withTokenPut(apiName, eventData).then(
      nominee => {

        dispatch(commonAction.success(nomineeConstants.UPDATE_NOMINEE_SUCCESS, nominee));
        dispatch(alertActions.success("Nominee Updated Successfully ..."));
      },
      error => {
        dispatch(commonAction.failure(nomineeConstants.UPDATE_NOMINEE_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  }
}

function createNomenee(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(nomineeConstants.CREATE_NOMINEE_REQUEST, eventData));
    commonService.withOutToken(apiName, eventData).then(
      nominee => {
        dispatch(commonAction.success(nomineeConstants.CREATE_NOMINEE_SUCCESS, nominee));
        dispatch(alertActions.success("Nominee Added Successfully ..."));
      },
      error => {
        dispatch(commonAction.failure(nomineeConstants.CREATE_NOMINEE_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    )
  }
}