import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, feedbackAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';

import Card from 'react-bootstrap/Card'

const Testimonials = () => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fileData, setFileData] = useState('');
    const { alert, language } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language
        })
    );

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // reader.result
            setFileData(reader.result);
        };
        reader.onerror = (error) => { console.log("err", error) };

    }

    const formik = useFormik({
        initialValues: {
            message: ""
        },
        validationSchema: Yup.object().shape({
            message: Yup.string()
                .required("Please Enter your message!")
        }),

        onSubmit: values => {
            let obj = {
                message: values.message,
                files: fileData
            }
            setIsLoading(true)
            dispatch(feedbackAction.addTestimonials(apiConstants.ADD_TESTIMONIALS, obj));
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        }
    });

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    useEffect(() => {
        dispatch(alertActions.clear());
        return () => {
            setAlertRes('')
        }
    }, []);

    useEffect(() => {

        if (Object.keys(alert).length > 0) {

            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                setIsLoading(false)
                if (alert.message.errors !== undefined) {

                    if (typeof alert.message.errors == "string") {
                        tempErr.push({ 'type': alert.type, message: alert.message.errors })
                    }
                    else {
                        let keys = Object.keys(alert.message.errors)
                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                            })
                        }
                        else {
                            tempErr = [{ 'type': alert.type, message: alert.message }]
                        }
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);
        }
    }, [alert]);

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}
                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>
                                <Card>
                                    <Card.Body>
                                        <Card.Title bg="light">{siteConstants.LABEL_ADD_TESTIMONIALS}</Card.Title>

                                        <section className="User-details-sec">
                                            <div className="top-use-details">
                                                <div className="container">
                                                    <div className="row d-flex justify-content-center">

                                                        <div className="col-md-9">
                                                            <div className="admin-form">
                                                                <form onSubmit={formik.handleSubmit}>

                                                                    <div className="form-row justify-content-between">
                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_MESSAGE}</label>
                                                                            <input type="text" className="form-control" name="message"
                                                                                value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                            <label className="text-danger">
                                                                                {formik.errors.message && formik.touched.message ?
                                                                                    formik.errors.message : ''
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group col-lg-12 col-md-12">
                                                                            <label>{siteConstants.LABEL_FILE}</label>
                                                                            <input type="file" accept="image/*" className="btn form-control mt-2 mb-2" id="fileData"
                                                                                onChange={(e) => handleFileChange(e)} name="fileData" />

                                                                        </div>
                                                                    </div>
                                                                    {!isLoading ?
                                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                                        :
                                                                        <button type="button" className="btn btn-new blue-layout" disabled={isLoading}>
                                                                            <i className="fas fa-spinner fa-spin"></i></button>
                                                                    }
                                                                </form>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                    </Card.Body>
                                </Card>

                                {/* Banner Scetion */}
                                <div className='col-md-12 mt-3'>
                                    <BannerRandomFooter />
                                </div>
                            </div>

                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Testimonials;
