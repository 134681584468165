import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { advertismentAction } from '../../../actions';
import { apiConstants } from '../../../constants';

export default function CreditPurchasePopup({ onClose, show }) {
    const dispatch = useDispatch()
    const [deductMoney, setDeductMoney] = useState('');
    const [adSettingList, setAdSettingList] = useState([])
    const [formErr, setFormErr] = useState('')

    const { getAdSetting } = useSelector(
        state => ({
            getAdSetting: state.getAdSetting
        })
    );

    useEffect(() => {
        if (getAdSetting && getAdSetting.isGetAdSetting) {
            if (getAdSetting.getAdSetting) {
                let index = getAdSetting.getAdSetting.findIndex((x) => { return x.name === "byTron" })
                setAdSettingList(getAdSetting.getAdSetting[index])
            }
        }
    }, [getAdSetting])

    const handleSubmit = (val) => {
        if (val) {
            if (deductMoney && deductMoney >= adSettingList.minTron) {
                dispatch(advertismentAction.purhcaseCredit(apiConstants.PURCHASE_CREDIT, { deductMoney: deductMoney }));
                onClose(false)
            } else {
                setFormErr(`Minimum ${adSettingList.minTron} tron required.`)
            }
        } else {
            onClose(false)
        }
    }

    useEffect(() => {
        dispatch(advertismentAction.advertismentSetting(apiConstants.GET_AD_SETTING));
    }, [show])

    return (
        <Modal show={show} size="xxxxl" animation={true}>
            <Modal.Header>
                <div>
                    <Modal.Title>
                        <h6 className="heading-top text-primary"> Purchase Advertisment Credit </h6>
                        <small className='f-12 text-secondary'>Minimum {adSettingList.minTron} tron required.
                            You will get {adSettingList.loginAd} login Ads , {adSettingList.bannerAd} banner Ads, {adSettingList.textAd} text Ads</small>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>

                <div className='mb-2'>
                    <label>Amount</label>
                    <input type="number" name="deductMoney" className="form-control mt-2 mb-2" value={deductMoney}
                        onChange={(e) => setDeductMoney(e.target.value)} />
                    {formErr ? <small className='text-danger'>{formErr}</small> : ''}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit(false)}>Close</Button>
                <Button variant="secondary modal-btn" onClick={() => handleSubmit(true)}>Submit</Button>
            </Modal.Footer>

        </Modal>
    )
}
