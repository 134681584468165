import { advertismentConstants, apiConstants } from '../constants';
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "./";

export const advertismentAction = {
    advertismentSetting,
    purhcaseCredit,
    addAdvertisment,
    getAdvertismentList,
    getCreditList,
    deleteBannner,
    bannerStatus,
    getRandomAdvertismentList,
    editAdvertisment,
    addAdvertismentReport,
    getFooterRandomBanner,
    getBannerFooterRandomBanner,
    getPromoBannerListing,
    getTextRandomAdvertismentList
};

function getBannerFooterRandomBanner(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_FOOTER_BANNER_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_FOOTER_BANNER_ADVERTISMENT_SUCCESS, user));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_FOOTER_BANNER_ADVERTISMENT_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

function getFooterRandomBanner(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_FOOTER_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_FOOTER_ADVERTISMENT_SUCCESS, user));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_FOOTER_ADVERTISMENT_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

function advertismentSetting(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_ADVERTISMENT_SETTING_REQUEST, obj));

        commonService.getDataWithToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_ADVERTISMENT_SETTING_SUCCESS, user.data.data));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_ADVERTISMENT_SETTING_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

function purhcaseCredit(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.PURCHASE_CREDIT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.PURCHASE_CREDIT_SUCCESS, user));
                dispatch(alertActions.success("Advertisment credit purchased successfully..."));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.PURCHASE_CREDIT_FAILURE, error));
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    };
}

function getAdvertismentList(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_ADVERTISMENT_SUCCESS, user));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_ADVERTISMENT_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

//-- Add testimonials function
function addAdvertisment(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.ADD_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.ADD_ADVERTISMENT_SUCCESS, user));
                dispatch(alertActions.success("ADVERTISMENT Added Successfully..."));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.ADD_ADVERTISMENT_FAILURE, error));
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    };
}

//-- edit user advertisment function
function editAdvertisment(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.EDIT_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.EDIT_ADVERTISMENT_SUCCESS, user));
                dispatch(alertActions.success("Advertisment updated Successfully..."));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.EDIT_ADVERTISMENT_FAILURE, error));
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    };
}

//get credit List///////////////
function getCreditList(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_CREDIT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_CREDIT_SUCCESS, user));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_CREDIT_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

function deleteBannner(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.DELETE_BANNER_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(advertismentConstants.DELETE_BANNER_SUCCESS, partner));
                dispatch(alertActions.success("Banner deleted Successfully ..."));
                setTimeout(() => {
                    dispatch(getAdvertismentList(apiConstants.GET_ADVERTISMENT));
                }, 2000);
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.DELETE_BANNER_FAILURE, error));
                if (error.response !== undefined) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    }
}

function bannerStatus(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.BANNER_STATUS_REQUEST, obj));
        commonService.withToken(apiName, obj).then(
            partner => {
                dispatch(commonAction.success(advertismentConstants.BANNER_STATUS_SUCCESS, partner));
                dispatch(alertActions.success("Status Update Successfully ..."));
                setTimeout(() => {
                    dispatch(getAdvertismentList(apiConstants.GET_ADVERTISMENT));
                }, 2000);
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.BANNER_STATUS_FAILURE, error));
                if (error.response !== undefined) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    }
}

function getRandomAdvertismentList(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_RANDOM_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_RANDOM_ADVERTISMENT_SUCCESS, user));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_RANDOM_ADVERTISMENT_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

function getTextRandomAdvertismentList(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_TEXT_RANDOM_ADVERTISMENT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.GET_TEXT_RANDOM_ADVERTISMENT_SUCCESS, user));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_TEXT_RANDOM_ADVERTISMENT_FAILURE, error));
                // dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

// add Advertisment report
function addAdvertismentReport(apiName, obj) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.ADD_ADVERTISMENT_REPORT_REQUEST, obj));

        commonService.withToken(apiName, obj).then(
            user => {
                dispatch(commonAction.success(advertismentConstants.ADD_ADVERTISMENT_REPORT_SUCCESS, user));
                dispatch(alertActions.success("Advertsment Report Added Successfully..."));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.ADD_ADVERTISMENT_REPORT_FAILURE, error));
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.error));
                }
                else {
                    dispatch(alertActions.error('Network Error'));
                }
            }
        );
    };
}

// get listing of all promo banner
function getPromoBannerListing(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(advertismentConstants.GET_PROMO_BANNER_REQUEST, eventData));
        commonService.withToken(apiName, eventData).then(
            doc => {
                dispatch(commonAction.success(advertismentConstants.GET_PROMO_BANNER_SUCCESS, doc));
            },
            error => {
                dispatch(commonAction.failure(advertismentConstants.GET_PROMO_BANNER_FAILURE, error));
            }
        );
    }
}