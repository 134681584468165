import axios from 'axios';

export const commonService = {
    withToken,
    withOutToken,
    withTokenPut,
    handleResponse,
    withTokenAndFormData,
    withTokenAndFormDataDD,
    getDataWithToken,
    getDataWithoutToken
};

//-- It's common function for using the token
function withToken(apiName, data) {
    let tokenObj = sessionStorage.getItem("token");
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL + apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- It's common function for using the token with Put method
function withTokenPut(apiName, data) {
    let tokenObj = sessionStorage.getItem("token");
    return axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_BACKEND_URL + apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {

        return handleResponse;
    });
};

//-- It's common function for using without token
function withOutToken(apiName, data) {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL + apiName}`,
        data: data
    }).then(handleResponse);
};

//-- It's common function for using with form data
function withTokenAndFormData(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL + apiName}`,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- It's common function for using with form data
function withTokenAndFormDataDD(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${apiName}`,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- get data
function getDataWithToken(apiName, userData) {
  
    let token = sessionStorage.getItem('token');
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL + apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        data: userData
    }).then((handleResponse) => {
        return handleResponse;
    });
}

//-- get data
function getDataWithoutToken(apiName, userData) {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL + apiName}`,
        headers: { 'Content-Type': 'application/json' },
        data: userData
    }).then((handleResponse) => {
        return handleResponse;
    });
}


function handleResponse(response) {

    if (response.status === 200 || response.status === 201) {
        return response;
    } 
    else {
        const error = response;
        return Promise.reject(error);
    }
    
}