import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { feedbackAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
// import 'owl.carousel/dist/assets/owl.carousel.css';  
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const CustomerReview = () => {

    const dispatch = useDispatch();
    const [testimonialsList, setTestimonials] = useState()
    const { getTestimonials } = useSelector(
        state => ({
            getTestimonials: state.getTestimonials
        })
    );

    useEffect(() => {
        if (getTestimonials !== undefined && getTestimonials !== null) {

            if (Object.keys(getTestimonials).length > 0) {
                if (getTestimonials.getTestimonials !== undefined) {
                    if (getTestimonials.getTestimonials.data !== undefined) {
                        const items = getTestimonials.getTestimonials.data.data;
                        if (items.length > 0) {
                            setTimeout(() => {
                                setTestimonials(items);
                            }, 1000);
                           
                        }
                    }
                }
            }
        }
    }, [getTestimonials])

    useEffect(() => {
        dispatch(feedbackAction.getTestimonials(apiConstants.GET_TESTIMONIALS_LIST));
    }, [])

    return (

        <section className="slider-testo">
            {testimonialsList !== undefined && testimonialsList.length > 0 ?
                <div className="slide-sec d-flex flex-wrap align-items-center">
                    <div className="slider-img" style={{ "backgroundImage": "url(/assets/images/slider-testo.png)" }}>

                    </div>
                    <div className="slide-inner">
                        <div className="slide-text-sec">
                            {testimonialsList !== undefined && testimonialsList.length > 0 ?

                                <OwlCarousel 
                                    items={1}
                                    className="owl-theme owl-carousel text-center"
                                    margin={10}
                                    autoplay={true}
                                    autoplayTimeout={10000}
                                    autoplayHoverPause={true}
                                >
                                    {testimonialsList.map((x, index) => {
                                        let firstname = x.userName && x.userName[0].firstname ? x.userName[0].firstname : '';
                                        let lastname = x.userName && x.userName[0].lastname ? x.userName[0].lastname : ''
                                    
                                        return (
                                            <div className="item" key={index}>
                                                <div className="slide-text text-center m-auto">
                                                    <i className="fas fa-quote-left"></i>
                                                    <div className='row'>
                                                        {x.files ?
                                                            <div className='col-lg-4'>
                                                                <img src={x.files} style={{ 'width': '100px', height: '100px' }} alt="fileImg"/>
                                                            </div> : ''}
                                                        <div className={x.files ? 'col-lg-8 text-left' : 'col-lg-12'}>
                                                            <p>{x.message}</p>
                                                            <p><strong>{firstname + " " + lastname}</strong></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>
                                : ''}
                        </div>
                    </div>

                </div>
                : ''}
        </section>
    )
}

export default CustomerReview;