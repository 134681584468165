import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import { walletAction } from '../../../actions';
import { apiConstants } from '../../../constants';

export default function WalletConditionPopup({ onSelect }) {
    const dispatch = useDispatch();
    const [siteConstants, setSiteConstants] = useState([]);
    const isLoading = false;
    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        onSelect(val)
    }

    const formik = useFormik({
        initialValues: {
            mnemonic: ""
        },
        validationSchema: Yup.object().shape({
            mnemonic: Yup.string()
                .required("Please Enter Mnemonic")
        }),
        onSubmit: values => {
            // setLoading(true)
            let obj = {
                mnemonic: values.mnemonic
            }
         
            dispatch(walletAction.recoverWallet(apiConstants.RECOVER_WALLET, obj))
            handleSubmit('No')
        }
    });

    return (
        <Modal show={true} onHide={() => handleSubmit('No')} size='xxl' className='text-center marginTop-15' dialogClassName="modal-70w" animation={true}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.LABEL_RECOVER_WALLET} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div>
                        <div className="form-row justify-content-between">                      
                            <div className="form-group col-lg-12 col-md-12">
                                <label>{siteConstants.LABEL_MNEMONIC}</label>
                                <input type="text" className="form-control" name="mnemonic"
                                    value={formik.values.mnemonic} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <label className="text-danger">
                                    {formik.errors.mnemonic && formik.touched.mnemonic ?
                                        formik.errors.mnemonic : ''
                                    }
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        !isLoading ?
                            <button type="submit" className="btn btn-primary">Submit</button> :
                            <button type="button" className="btn btn-new blue-layout" disabled={isLoading}>
                                <i className="fas fa-spinner fa-spin"></i></button>
                    }
                    <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
                </Modal.Footer>
            </form>
            <p className='text-secondary f-12 p-2'>Please feel free to reach out to us if need further assistance 
                <b className='text-info'>{" "+process.env.REACT_APP_FRONTEND_SUPPORT_EMAIL}</b></p>
        </Modal>
    )
}
