import { supportConstants } from '../constants';

//-- get support user reducers here....

export function getSupportTicket(state = {}, action) {
    switch (action.type) {
        case supportConstants.GET_SUPPORT_TICKET_REQUEST:
            return {
                isGetSupportTicket: false
            };

        case supportConstants.GET_SUPPORT_TICKET_SUCCESS:
            return { isGetSupportTicket: true, getSupportTicket: action.successAction };

        case supportConstants.GET_SUPPORT_TICKET_FAILURE:
            return {
                isGetSupportTicket: false,
            };

        default:
            return state
    }
}

//-- get support ticket status by id reducers here....
export function getSupportTicketById(state = {}, action) {
    switch (action.type) {
        case supportConstants.GET_SUPPORT_TICKET_BY_ID_REQUEST:
            return {
                isGetSupportTicketById: false
            };

        case supportConstants.GET_SUPPORT_TICKET_BY_ID_SUCCESS:
            return { isGetSupportTicketById: true, getSupportTicketById: action.successAction };

        case supportConstants.GET_SUPPORT_TICKET_BY_ID_FAILURE:
            return {
                isGetSupportTicketById: false,
            };

        default:
            return state
    }
}