import { menuConstants } from '../constants';

//-- get menu reducers here...
export function getMenu(state = {}, action) {
    switch (action.type) {
        case menuConstants.GET_MENU_REQUEST:
            return {
                isGetMenu: false
            };

        case menuConstants.GET_MENU_SUCCESS:
            return { isGetMenu: true, getMenu: action.successAction };

        case menuConstants.GET_MENU_FAILURE:
            return {
                isGetMenu: false,
            };

        default:
            return state
    }
}

//-- get menu by id reducers here...
export function getMenuById(state = {}, action) {
    switch (action.type) {
        case menuConstants.GET_MENU_BY_ID_REQUEST:
            return {
                isGetMenuById: false
            };

        case menuConstants.GET_MENU_BY_ID_SUCCESS:
            return { isGetMenuById: true, getMenuById: action.successAction };

        case menuConstants.GET_MENU_BY_ID_FAILURE:
            return {
                isGetMenuById: false,
            };

        default:
            return state
    }
}