import { messageConstants } from '../constants';

//-- get sender user reducers here...
export function getSenderUser(state = {}, action) {
    switch (action.type) {
        case messageConstants.SEARCH_SENDER_REQUEST:
            return {
                isGetSenderUser: false
            };

        case messageConstants.SEARCH_SENDER_SUCCESS:
            return { isGetSenderUser: true, getSenderUser: action.successAction };

        case messageConstants.SEARCH_SENDER_FAILURE:
            return {
                isGetSenderUser: false,
            };
        case messageConstants.SEARCH_SENDER_DEFAULT:
            return {
                isGetSenderUser: false
            };

        default:
            return state
    }
}

//-- get chat by user reducers here...
export function getChatByUserId(state = {}, action) {
    switch (action.type) {
        case messageConstants.GET_CHAT_BY_USER_REQUEST:
            return {
                isGetChatByUserId: false
            };

        case messageConstants.GET_CHAT_BY_USER_SUCCESS:
            return { isGetChatByUserId: true, getChatByUserId: action.successAction };

        case messageConstants.GET_CHAT_BY_USER_FAILURE:
            return {
                isGetChatByUserId: false,
            };

        default:
            return state
    }
}

//-- get all messaged user list reducers here...
export function getAllMessagedUser(state = {}, action) {
    switch (action.type) {
        case messageConstants.GET_ALL_MESSAGED_USER_REQUEST:
            return {
                isGetAllMessagedUser: false
            };

        case messageConstants.GET_ALL_MESSAGED_USER_SUCCESS:
            return { isGetAllMessagedUser: true, getAllMessagedUser: action.successAction };

        case messageConstants.GET_ALL_MESSAGED_USER_FAILURE:
            return {
                isGetAllMessagedUser: false,
            };

        default:
            return state
    }
}