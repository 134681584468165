import { globalPoolUserConstants } from '../constants';

//-- get pool user reducers here...
export function getPoolUser(state = {}, action) {
    switch (action.type) {
        case globalPoolUserConstants.GET_POOL_USER_REQUEST:
            return {
                isGetPoolUser: false
            };

        case globalPoolUserConstants.GET_POOL_USER_SUCCESS:
            return { isGetPoolUser: true, getPoolUser: action.successAction };

        case globalPoolUserConstants.GET_POOL_USER_FAILURE:
            return {
                isGetPoolUser: false,
            };

        default:
            return state
    }
}

//-- get available plan reducers here...
export function getAvailablePlan(state = {}, action) {
    switch (action.type) {
        case globalPoolUserConstants.GET_AVAILABLE_PLAN_REQUEST:
            return {
                isGetAvailablePlan: false
            };

        case globalPoolUserConstants.GET_AVAILABLE_PLAN_SUCCESS:
            return { isGetAvailablePlan: true, getAvailablePlan: action.successAction };

        case globalPoolUserConstants.GET_AVAILABLE_PLAN_FAILURE:
            return {
                isGetAvailablePlan: false,
            };

        default:
            return state
    }
}

//-- get pool request reducers here...
export function getPoolRequest(state = {}, action) {
    switch (action.type) {
        case globalPoolUserConstants.GET_POOL_REQUEST_REQUEST:
            return {
                isGetPoolRequest: false
            };

        case globalPoolUserConstants.GET_POOL_REQUEST_SUCCESS:
            return { isGetPoolRequest: true, getPoolRequest: action.successAction };

        case globalPoolUserConstants.GET_POOL_REQUEST_FAILURE:
            return {
                isGetPoolRequest: false,
            };

        default:
            return state
    }
}
