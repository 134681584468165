import { nomineeConstants } from "../constants";

const initialState = {
  nominee: '',
  loading: false
}

export function getNominee(state = initialState, action) {
  switch (action.type) {
    case nomineeConstants.GET_NOMINEE_REQUEST:
      return {
        ...state,
        loading: false
      }
    case nomineeConstants.GET_NOMINEE_SUCCESS:
      return {
        ...state,
        loading: true,
        nominee: action.successAction.data.data
      }
    case nomineeConstants.GET_NOMINEE_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export function updateNominee(state = initialState, action) {
  switch (action.type) {
    case nomineeConstants.UPDATE_NOMINEE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case nomineeConstants.UPDATE_NOMINEE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case nomineeConstants.UPDATE_NOMINEE_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export function createNominee(state = initialState, action) {
  switch (action.type) {
    case nomineeConstants.CREATE_NOMINEE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case nomineeConstants.CREATE_NOMINEE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case nomineeConstants.CREATE_NOMINEE_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}