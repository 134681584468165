import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RestrictedAuthRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={props => (
        sessionStorage.getItem('token') && JSON.parse(sessionStorage.getItem('user')).role_type
            && JSON.parse(sessionStorage.getItem('user')).role_type !== "support"
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)

export default RestrictedAuthRoute;