import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { history } from "../../../helpers";
import { useSelector } from "react-redux";

const Sidebar = (userData) => {
  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const poolStatusDetails = sessionStorage.getItem("poolStatusDetails");
  const [siteConstants, setSiteConstants] = useState([]);
  const [activeClass, setActiveClass] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenSupport, setMenuOpenSupport] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showSubTwo, setShowSubTwo] = useState(false);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language]);

  const handleRedirection = (name) => {
    if (
      name !== "wallet" ||
      name !== "transaction-history" ||
      name !== "wallet-transaction"
    ) {
      setMenuOpen(false);
    }
    setMenuOpen(false);
    setMenuOpenSupport(false);
    setActiveClass(name);
    history.push("/" + name);
  };

  return (
    <>
      <div className="sidebar-wrap mb-5">
        <div className="sidebar-menu">
          <ul className="side-nav">
            <li className={activeClass === "dashboard" ? "active" : ""}>
              <Nav.Link onClick={() => handleRedirection("dashboard")}>
                <span className="menu-icon">
                  <img
                    src="assets/images/dashboard.png"
                    className="menu-icons-normal menu-padding"
                    alt="dashboard"
                  />
                  <img
                    src="assets/images/dashboard-h.png"
                    alt=""
                    className="menu-icons-hover"
                  />
                </span>
                <span className="menu-text">{siteConstants.GEN_DASHBOARD}</span>
              </Nav.Link>
            </li>

            {userDetails && userDetails.role_type !== "support" ? (
              <>
                {/* User Profile */}
                <li className={activeClass === "profile" ? "active" : ""}>
                  <Nav.Link onClick={() => handleRedirection("profile")}>
                    <span className="menu-icon">
                      <img
                        src="assets/images/admin-menu.png"
                        className="menu-icons-normal menu-padding"
                        alt="menu-admin"
                      />
                      <img
                        src="assets/images/admin-menu-h.png"
                        alt=""
                        className="menu-icons-hover"
                      />
                    </span>
                    <span className="menu-text">
                      {siteConstants.LABEL_PROFILE}
                    </span>
                  </Nav.Link>
                </li>

                {/* Global Pool */}
                {poolStatusDetails && poolStatusDetails === "completed" ?
                  <li className={activeClass === 'global-pool' ? 'active' : ''}>
                    <Nav.Link className='text-light' onClick={() => handleRedirection('global-pool')}>
                      <span className="menu-icon">
                        <i className="fas fa-list" aria-hidden="true"></i>
                      </span>
                      <span className="menu-text">
                        {siteConstants.LABEL_GLOBAL_POOL}</span>
                    </Nav.Link>
                  </li>
                  : ''}
                {/* Next in line */}
                <li className={activeClass === 'next-in-line' ? 'active' : ''}>
                    <Nav.Link className='text-light' onClick={() => handleRedirection('next-in-line')}>
                      <span className="menu-icon">
                        <i className="fas fa-hourglass-half" aria-hidden="true"></i>
                      </span>
                      <span className="menu-text">
                       Next In Line</span>
                    </Nav.Link>
                  </li>
                {/* Message Menu */}
                <li className={activeClass === "message" ? "active" : ""}>
                  <Nav.Link onClick={() => handleRedirection("message")}>
                    <span className="menu-icon">
                      <i className="fas fa-envelope" aria-hidden="true"></i>
                    </span>

                    <span className="menu-text">Message</span>
                  </Nav.Link>
                </li>

                {/* Change Password */}
                <li
                  className={activeClass === "change-password" ? "active" : ""}
                >
                  <Nav.Link
                    onClick={() => handleRedirection("change-password")}
                  >
                    <span className="menu-icon">
                      <i className="fas fa-key"></i>
                      {/* <img src="assets/images/padlock5.png" className="menu-icons-normal" alt="lock" />
                                            <img src="assets/images/padlock4.png" alt="" className="menu-icons-hover" /> */}
                    </span>
                    <span className="menu-text">
                      {siteConstants.LABEL_CHANGE_PASSWORD}
                    </span>
                  </Nav.Link>
                </li>

                {/* Nominee Menu */}
                <li className={activeClass === "nominee-user" ? "active" : ""}>
                  <Nav.Link onClick={() => handleRedirection("nominee-user")}>
                    <span className="menu-icon">
                      <i className="far fa-user" aria-hidden="true"></i>
                    </span>
                    <span className="menu-text">
                      {siteConstants.LABEL_NOMINEE}
                    </span>
                  </Nav.Link>
                </li>

                {/* Promo Tools */}
                <li className={activeClass === "promo-tools" ? "active" : ""}>
                  <Nav.Link onClick={() => handleRedirection("promo-tools")}>
                    <span className="menu-icon">
                      <i className="f-17 fa fa-bullhorn" aria-hidden="true"></i>
                    </span>
                    <span className="menu-text">
                      Promo Tools
                    </span>
                  </Nav.Link>
                </li>

                {/* Advertisment SideBar Menu*/}
                <li>
                  <span
                    className="sidebar-text"
                    onClick={() => setShowSubTwo(!showSubTwo)}
                  >
                    <i className="fas fa-ad mr-4" aria-hidden="true"></i>
                    <span className="menu-text notify-cursor">
                      Advertisment
                    </span>
                  </span>

                  <div
                    className="ml-5 my-3 text-light"
                    style={{ display: showSubTwo ? "block" : "none" }}
                  >
                    <Nav.Link
                      className="text-light"
                      onClick={() => handleRedirection("add-advertisment")}
                    >
                      <span className="menu-icon">
                        <i className="fa fa-plus-circle f-18" aria-hidden="true"></i>

                      </span>
                      <span className="menu-text">
                        {siteConstants.ADVERTISMENT_ADD}
                      </span>
                    </Nav.Link>

                    <Nav.Link
                      className="text-light"
                      onClick={() => handleRedirection("advertisment-listing")}
                    >
                      <span className="menu-icon">
                        <i className="fas fa-list" aria-hidden="true"></i>
                      </span>
                      <span className="menu-text">
                        {siteConstants.ADVERTISMENT_LISTING}
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      className="text-light"
                      onClick={() => handleRedirection("advertisment-transaction")}
                    >
                      <span className="menu-icon">
                        <i className="fas fa-list" aria-hidden="true"></i>
                      </span>
                      <span className="menu-text">
                        {siteConstants.CREDIT_LISTING}
                      </span>
                    </Nav.Link>
                  </div>
                </li>

                {/* Testimonials Menu */}
                <li className={activeClass === "testimonials" ? "active" : ""}>
                  <Nav.Link onClick={() => handleRedirection("testimonials")}>
                    <span className="menu-icon">
                      <i className="fas fa-plus" aria-hidden="true"></i>
                    </span>
                    <span className="menu-text">
                      {siteConstants.LABEL_ADD_TESTIMONIALS}
                    </span>
                  </Nav.Link>
                </li>
              </>
            ) : (
              ""
            )}

            <li className={activeClass === "user-listing" ? "active" : ""}>
              <Nav.Link onClick={() => handleRedirection("user-listing")}>
                <span className="menu-icon">
                  <i className="fas fa-list" aria-hidden="true"></i>
                </span>
                <span className="menu-text">
                  {siteConstants.LABEL_USER_LIST}
                </span>
              </Nav.Link>
            </li>

            <li className={activeClass === "position-listing" ? "active" : ""}>
              <Nav.Link onClick={() => handleRedirection("position-listing")}>
                <span className="menu-icon">
                  <i className="fas fa-list" aria-hidden="true"></i>
                </span>
                <span className="menu-text">
                  {siteConstants.LABEL_POSITION_LIST}
                </span>
              </Nav.Link>
            </li>

            <li className={activeClass === "re-entry-listing" ? "active" : ""}>
              <Nav.Link onClick={() => handleRedirection("re-entry-listing")}>
                <span className="menu-icon">
                  <i className="fas fa-th-list" aria-hidden="true"></i>
                </span>
                <span className="menu-text">
                  {siteConstants.LABEL_REENTRY_LIST}
                </span>
              </Nav.Link>
            </li>

            <Nav.Item
              as="li"
              className={
                menuOpen ? "nav-item-submenu nav-item-open" : "nav-item-submenu"
              }
            >
              <Nav.Link onClick={() => setMenuOpen(!menuOpen)}>
                <span className="menu-icon">
                  <i className="fas fa-wallet" aria-hidden="true"></i>
                </span>
                <span className="menu-text">Wallet</span>
              </Nav.Link>
              <Nav
                as="ul"
                className="nav nav-group-sub mt-3"
                style={{ display: menuOpen ? "block" : "none" }}
                data-submenu-title="wallet"
              >
                {userDetails && userDetails.role_type !== "support" ? (
                  <Nav.Item
                    as="li"
                    className={activeClass === "wallet" ? "active" : ""}
                  >
                    <Nav.Link
                      className="menu-text-wallet"
                      onClick={() => handleRedirection("wallet")}
                    >
                      <span className="ml-5"> Wallet Details </span>
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  ""
                )}
                <Nav.Item
                  as="li"
                  className={
                    activeClass === "wallet-transaction" ? "active" : ""
                  }
                >
                  <Nav.Link
                    className="menu-text-wallet"
                    onClick={() => handleRedirection("wallet-transaction")}
                  >
                    <span className="ml-5"> Wallet Transactions </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={
                    activeClass === "withdraw-transaction" ? "active" : ""
                  }
                >
                  <Nav.Link
                    className="menu-text-wallet"
                    onClick={() => handleRedirection("withdraw-transaction")}
                  >
                    <span className="ml-5"> Withdrawal History </span>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li" className={activeClass==='transaction-history' ? 'active':''}>
                                    <Nav.Link onClick={() => handleRedirection("transaction-history")} className="menu-text-wallet">
                                        <span className="ml-5"> Transaction History </span></Nav.Link>
                                </Nav.Item> */}
              </Nav>
            </Nav.Item>

            {userDetails && userDetails.role_type !== "support" ? (
              <Nav.Item
                as="li"
                className={
                  menuOpenSupport
                    ? "nav-item-submenu nav-item-open"
                    : "nav-item-submenu"
                }
              >
                <Nav.Link onClick={() => setMenuOpenSupport(!menuOpenSupport)}>
                  <span className="menu-icon">
                    <i className="fas fa-headphones" aria-hidden="true"></i>
                  </span>
                  <span className="menu-text">Support Ticket</span>
                </Nav.Link>
                <Nav
                  as="ul"
                  className="nav nav-group-sub mt-3"
                  style={{ display: menuOpenSupport ? "block" : "none" }}
                  data-submenu-title="wallet"
                >
                  <Nav.Item
                    as="li"
                    className={activeClass === "support" ? "active" : ""}
                  >
                    <Nav.Link
                      className="menu-text-wallet"
                      onClick={() => handleRedirection("support")}
                    >
                      <span className="ml-5"> Support Ticket </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    as="li"
                    className={activeClass === "track-ticket" ? "active" : ""}
                  >
                    <Nav.Link
                      className="menu-text-wallet"
                      onClick={() => handleRedirection("track-ticket")}
                    >
                      <span className="ml-5"> Track Ticket </span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Nav.Item>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
