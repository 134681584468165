export const advertismentConstants = {
    GET_ADVERTISMENT_REQUEST: "GET_ADVERTISMENT_REQUEST",
    GET_ADVERTISMENT_SUCCESS: "GET_ADVERTISMENT_SUCCESS",
    GET_ADVERTISMENT_FAILURE: "GET_ADVERTISMENT_FAILURE",

    ADD_ADVERTISMENT_REQUEST: " ADD_ADVERTISMENT_REQUEST",
    ADD_ADVERTISMENT_SUCCESS: "ADD_ADVERTISMENT_SUCCESS",
    ADD_ADVERTISMENT_FAILURE: "ADD_ADVERTISMENT_FAILURE",

    GET_CREDIT_REQUEST: "GET_CREDIT_REQUEST",
    GET_CREDIT_SUCCESS: "GET_CREDIT_SUCCESS",
    GET_CREDIT_FAILURE: "GET_CREDIT_FAILURE",

    DELETE_BANNER_REQUEST: 'DELETE_BANNER_REQUEST',
    DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
    DELETE_BANNER_FAILURE: 'DELETE_BANNER_FAILURE',

    BANNER_STATUS_REQUEST: 'BANNER_STATUS_REQUEST',
    BANNER_STATUS_SUCCESS: 'BANNER_STATUS_SUCCESS',
    BANNER_STATUS_FAILURE: 'BANNER_STATUS_FAILURE',

    GET_RANDOM_ADVERTISMENT_REQUEST: 'GET_RANDOM_ADVERTISMENT_REQUEST',
    GET_RANDOM_ADVERTISMENT_SUCCESS: 'GET_RANDOM_ADVERTISMENT_SUCCESS',
    GET_RANDOM_ADVERTISMENT_FAILURE: 'GET_RANDOM_ADVERTISMENT_FAILURE',

    GET_ADVERTISMENT_SETTING_REQUEST: 'GET_ADVERTISMENT_SETTING_REQUEST',
    GET_ADVERTISMENT_SETTING_SUCCESS: 'GET_ADVERTISMENT_SETTING_SUCCESS',
    GET_ADVERTISMENT_SETTING_FAILURE: 'GET_ADVERTISMENT_SETTING_FAILURE',

    PURCHASE_CREDIT_REQUEST: "PURCHASE_CREDIT_REQUEST",
    PURCHASE_CREDIT_SUCCESS: "PURCHASE_CREDIT_SUCCESS",
    PURCHASE_CREDIT_FAILURE: "PURCHASE_CREDIT_FAILURE",

    EDIT_ADVERTISMENT_REQUEST: 'EDIT_ADVERTISMENT_REQUEST',
    EDIT_ADVERTISMENT_SUCCESS: 'EDIT_ADVERTISMENT_SUCCESS',
    EDIT_ADVERTISMENT_FAILURE: 'EDIT_ADVERTISMENT_FAILURE',

    ADD_ADVERTISMENT_REPORT_REQUEST: " ADD_ADVERTISMENT_REPORT_REQUEST",
    ADD_ADVERTISMENT_REPORT_SUCCESS: "ADD_ADVERTISMENT_REPORT_SUCCESS",
    ADD_ADVERTISMENT_REPORT_FAILURE: "ADD_ADVERTISMENT_REPORT_FAILURE",

    GET_FOOTER_ADVERTISMENT_REQUEST: 'GET_FOOTER_ADVERTISMENT_REQUEST',
    GET_FOOTER_ADVERTISMENT_SUCCESS: 'GET_FOOTER_ADVERTISMENT_SUCCESS',
    GET_FOOTER_ADVERTISMENT_FAILURE: 'GET_FOOTER_ADVERTISMENT_FAILURE',

    GET_FOOTER_BANNER_ADVERTISMENT_REQUEST: 'GET_FOOTER_BANNER_ADVERTISMENT_REQUEST',
    GET_FOOTER_BANNER_ADVERTISMENT_SUCCESS: 'GET_FOOTER_BANNER_ADVERTISMENT_SUCCESS',
    GET_FOOTER_BANNER_ADVERTISMENT_FAILURE: 'GET_FOOTER_BANNER_ADVERTISMENT_FAILURE',

    GET_PROMO_BANNER_REQUEST: 'GET_PROMO_BANNER_REQUEST',
    GET_PROMO_BANNER_SUCCESS: 'GET_PROMO_BANNER_SUCCESS',
    GET_PROMO_BANNER_FAILURE: 'GET_PROMO_BANNER_FAILURE',

    GET_TEXT_RANDOM_ADVERTISMENT_REQUEST: 'GET_TEXT_RANDOM_ADVERTISMENT_REQUEST',
    GET_TEXT_RANDOM_ADVERTISMENT_SUCCESS: 'GET_TEXT_RANDOM_ADVERTISMENT_SUCCESS',
    GET_TEXT_RANDOM_ADVERTISMENT_FAILURE: 'GET_TEXT_RANDOM_ADVERTISMENT_FAILURE'
}