import { genealogyConstants, apiConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
import { alertActions } from "./";

export const genealogyActions = {
  getNewMember,
  getPurchasedPosition,
  addPosition,
  getPositionView,
  positionByNumber,
  getReEntryPosition,
  getEarnedIncome,
  getPendingPositionCount
};

//-- get pending position count  function

function getPendingPositionCount(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.GET_PENDING_POSITION_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.GET_PENDING_POSITION_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.GET_PENDING_POSITION_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}

//-- get new memeber function
function getNewMember(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.GET_NEW_MEMBER_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.GET_NEW_MEMBER_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.GET_NEW_MEMBER_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}

//-- get purchased position function
function getPurchasedPosition(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.GET_POSITION_PURCHASED_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.GET_POSITION_PURCHASED_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.GET_POSITION_PURCHASED_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}

//-- get re-entry position function
function getReEntryPosition(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.GET_RE_ENTRY_POSITION_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.GET_RE_ENTRY_POSITION_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.GET_RE_ENTRY_POSITION_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}

//-- add position function
function addPosition(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.ADD_POSITION_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.ADD_POSITION_SUCCESS, user));

        if (user.data.success) {
          sessionStorage.setItem('poolStatusDetails', 'completed')
          // dispatch(alertActions.success("Position Added Successfully ..."));
          if (user.data.data) {
            let newAlert = user.data.data
            newAlert = newAlert + " ^specialPositionAlert"
            dispatch(alertActions.success(newAlert))
          }
          dispatch(alertActions.clear())
          dispatch(getPositionView(apiConstants.GET_POSITION_VIEW))
        }
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.ADD_POSITION_FAILURE, error));
        if (error.response !== undefined) {
          let newAlert = error.response.data.error;

          if (typeof newAlert == 'object') {

            let keys = Object.keys(newAlert);

            if (keys.length !== 0) {
              let temp = [];
              keys.forEach(data => {
                let res = errorFuncForPosition(data, newAlert);
                if (res) temp = temp + " " + res
              })

              newAlert = temp
            }
          }
          else {
            newAlert = newAlert.errors + " ^specialPositionAlert"
          }

          dispatch(alertActions.error(newAlert));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}

const errorFuncForPosition = (data, newAlert) => {

  if (data === 'errors') {
    if (typeof newAlert.errors === "object") {
      let key1 = Object.keys(newAlert.errors);
      key1.map(x => {
        if (typeof newAlert.errors[x] === "string") {
          return newAlert.errors[x] = newAlert.errors[x] + " ^specialPositionAlert"
        }
        return x;
      })
    }
    else {
      return newAlert.errors + " ^specialPositionAlert"
    }
  }
}

//-- get earned income function
function getEarnedIncome(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.GET_EARNED_INCOME_REQUEST, obj));

    commonService.getDataWithToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.GET_EARNED_INCOME_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.GET_EARNED_INCOME_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}


//-- get position view function
function getPositionView(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.GET_POSITION_VIEW_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.GET_POSITION_VIEW_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.GET_POSITION_VIEW_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}

//-- position by number function
function positionByNumber(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(genealogyConstants.POSITION_BY_NUMBER_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(genealogyConstants.POSITION_BY_NUMBER_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(genealogyConstants.POSITION_BY_NUMBER_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      }
    );
  };
}
