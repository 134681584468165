import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { walletAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';

var momentTimeZone = require('moment-timezone');

export default function WithdrawTransaction() {
    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [transactionList, setTransactionList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const searchVal = { 'value': 'positionNumber', 'label': 'By Position' };
    const [totalPages, setTotalpages] = useState(1);
    const [siteConstants, setSiteConstants] = useState([]);
    const [query, setQuery] = useState('')
    const timeZone = momentTimeZone.tz.setDefault("America/New_York");

    const { alert, language, getTransaction } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language,
            getTransaction: state.getWithdrawTransaction
        })
    );

    useEffect(() => {
        let obj = {
            page: 1,
            perPage: 10,
            searchBy: searchVal.value
        };
        dispatch(walletAction.getWithdrawTransaction(apiConstants.GET_WITHDRAW_TRANSACTION, obj));
        return () => {
            setAlertRes('')
        }
    }, []);

    useEffect(() => {
        if (Object.keys(alert).length > 0) {

            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                if (alert.message.errors !== undefined) {

                    if (typeof alert.message.errors == 'object') {

                        let keys = Object.keys(alert.message.errors)

                        if (keys.length !== 0) {
                            keys.map(data => {
                                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                            })
                        }
                    }
                    else {
                        tempErr = [{ 'type': alert.type, message: alert.message.errors }]
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);
        }


    }, [alert]);

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

        if (Object.keys(getTransaction).length > 0) {
            if (getTransaction.getWithdrawTransaction !== undefined) {
                if (getTransaction.getWithdrawTransaction.data !== undefined) {
                    const { totalPages, currentPage, data } = getTransaction.getWithdrawTransaction.data.data;
                    if (data.length > 0) {
                        setTransactionList(data);
                        setCurrentPage(currentPage)
                        setTotalpages(totalPages);
                    }
                }
            }
        }
    }, [language, getTransaction]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        let obj = {
            page: pageNumber,
            perPage: 10,
            searchBy: searchVal.value
        };
        if (query !== '') {
            obj.query = query;
        }
        dispatch(walletAction.getWithdrawTransaction(apiConstants.GET_WITHDRAW_TRANSACTION, obj));

    }

    const searchPosition = (e) => {
        let obj = {
            page: 1,
            perPage: 10
        };
        setQuery(e.target.value);
        if (e.target.value !== '') {
            obj.query = e.target.value;
            obj.searchBy = searchVal.value;

            dispatch(walletAction.getWithdrawTransaction(apiConstants.GET_WITHDRAW_TRANSACTION, obj));
        } else {
            dispatch(walletAction.getWithdrawTransaction(apiConstants.GET_WITHDRAW_TRANSACTION, obj));
        }
    }

    let totalPage = typeof transactionList !== 'undefined' && Object.keys(transactionList).length > 0 ? totalPages : 0;

    return (
        <div className="main-wrap">
            <Header />
            <div className="dashboard-wraper">
                <Sidebar />
                <div className="content-wrap">
                    {alertRes !== '' ?
                        alertRes.length > 0 ? alertRes.map((data, index) => {

                            return (
                                <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                    className="alertBox">
                                    {data.message}
                                </Alert>)
                        }) : '' : ''}

                    <div className="dashboard-content p">

                        <div className="dash-body-blk">
                            <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row progress-bar-sec">
                                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                <div className="card dash-card tag-block p-3 box_shadow">
                                                    <div className="row">
                                                        <div className="col-lg-12"> <h2 className="heading-top"> Withdraw History </h2>  </div>
                                                    </div>

                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-sm-12 col-md-12 d-flex justify-content-between">
                                                            {/* <div className="col-md-3">
                                                                <Select
                                                                    value={searchVal}
                                                                    name="searchVal"
                                                                    placeholder="Search By"
                                                                    onChange={(e) => { setSearchVal(e) }}
                                                                    options={selectedSearch}
                                                                />
                                                            </div> */}
                                                            <div id="dataTable_filter" className="dataTables_filter">
                                                                <label>
                                                                    <input type="search" placeholder="search (by username)"
                                                                        className="form-control form-control-sm"
                                                                        aria-controls="dataTable"
                                                                        onChange={(e) => searchPosition(e)} />

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="owned-sec scroll-layout transaction-layout">
                                                        <table className="m-2">
                                                            <thead>
                                                                <tr>
                                                                    <td>ID</td>
                                                                    <td>Remark</td>
                                                                    <td>From username</td>
                                                                    <td>To username</td>
                                                                    <td>Amount</td>
                                                                    <td>Time</td>

                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    transactionList !== undefined && transactionList.length > 0 ?
                                                                        transactionList.map((item, index) => {

                                                                            return (

                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>
                                                                                        <small><b>Credit</b>-{item.remark !== undefined && item.remark.credit !== undefined ? item.remark.credit : ''}</small><br></br>
                                                                                        <small><b>Debit</b>-{item.remark !== undefined && item.remark.debit !== undefined ? item.remark.debit : ''}</small>
                                                                                    </td>
                                                                                    <td>{item.fromUser !== undefined && item.fromUser.username !== undefined && item.fromUser.username !== null ? item.fromUser.username : '-'}<br></br><small>{item.fromWallet !== undefined ? item.fromWallet.wallet_address.base58 : '-'}</small></td>
                                                                                    <td>{item.toUser !== undefined && item.toUser.username !== undefined && item.toUser.username !== null ? item.toUser.username : '-'}<br></br><small>{item.toWallet !== undefined ? item.toWallet.wallet_address.base58 : item.to.to}</small></td>
                                                                                    <td>{item.amount !== undefined ? parseFloat(item.amount).toFixed(2) : ''}</td>
                                                                                    <td>{momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                                                                        momentTimeZone(item.createdAt).tz(momentTimeZone.tz.guess()).format('DD-MM-YYYY HH:mm:ss')
                                                                                        :
                                                                                        momentTimeZone(item.createdAt).tz(timeZone).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                                                    {/* <td>{Moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td> */}

                                                                                </tr>)
                                                                        }) : <tr>
                                                                            <td colSpan="8" className="noRecordFound">{siteConstants.GEN_NO_RECORD}</td>
                                                                        </tr>
                                                                }


                                                            </tbody>
                                                        </table>
                                                        {typeof transactionList !== 'undefined' ?
                                                            <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Banner Scetion */}
                        <div className='col-md-12 mt-3'>
                            <BannerRandomFooter />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </div >
    )
}
