import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import Select from 'react-select';

export default function EditTicket({ onSelect, editData }) {

    const [values, setValues] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [fileData, setFileData] = useState('');
    const [descErr ,setDescError] = useState('');
    const priorityList = [
        { 'value': 'low', 'label': 'low' },
        { 'value': 'medium', 'label': 'medium' },
        { 'value': 'high', 'label': 'high' }
    ]

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        setValues({ ...values, ticketId: editData._id, 'priority': editData.priority, 'description': editData.description })
    }, [])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            // use a regex to remove data url part
            const base64String = reader.result
            setFileData(base64String);
        };
        reader.readAsDataURL(file);

    }

    const handleSubmit = (val) => {
        if (val === "Yes") {
            if(values.description !==''){
            let obj = { 'priority': values.priority, 'description': values.description, 'attachment': fileData }
            onSelect({ res: true, data: obj, ticketId: values.ticketId })
            }
            else{
                setDescError('Description required...')
            }
        }
        else {
            onSelect({ res: false, obj: val })
        }
    }

    return (
        <Modal show={true} size="xxxxl" animation={true}>
            <Modal.Header>
                <div>
                    <Modal.Title> <h6 className="heading-top text-primary">
                        {siteConstants.GEN_EDIT_TICKET} </h6>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>

                <div className="form-group col-lg-12 col-md-12">
                    <label>{siteConstants.LABEL_PRIORITY}</label>
                    <Select
                        name="priority"
                        placeholder="priority"
                        onChange={(e) => { setValues({ ...values, priority: e.value }) }}
                        options={priorityList ? priorityList : []}
                        classNamePrefix="select-layout"
                        value={{ 'value': values.priority, 'label': values.priority }}
                    />
                </div>

                <div className='mb-2'>
                    <label>{siteConstants.LABEL_DESCRIPTION}</label>
                    <textarea type="text" className="form-control" name="description"
                        onChange={(e) => { setValues({ ...values, description: e.target.value }) }}
                        value={values.description} />
                    <label className="text-danger">
                        {descErr ? descErr : ''}
                    </label>
                </div>

                <div className='mb-2'>
                    <label>{siteConstants.LABEL_ATTACHMENT}</label>
                    <input accept="image" type="file" className="btn form-control mt-2 mb-2" id="fileData"
                        onChange={(e) => handleFileChange(e)} name="fileData" />

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary btn-gray" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_CLOSE}</Button>
                <Button variant="secondary modal-btn" onClick={() => handleSubmit('Yes')}>{siteConstants.LABEL_SAVE}</Button>
            </Modal.Footer>

        </Modal>
    )
}
