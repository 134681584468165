import React, { useState, useEffect } from 'react';
import { feedbackAction,userActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function Marketing() {

  const dispatch = useDispatch();
  const [marketingList, setMarketingList] = useState([]);
  const [link ,setLink ] = useState([])

  const { getMarketing, getSetting } = useSelector(
    state => ({
      getMarketing: state.getMarketing,
      getSetting: state.getSetting
    })
  );

  useEffect(() => {

    if (getMarketing !== undefined && getMarketing !== null) {
       
      if (Object.keys(getMarketing).length > 0) {
        if (getMarketing.getMarketing !== undefined) {
          if (getMarketing.getMarketing.data !== undefined) {

            const items = getMarketing.getMarketing.data.data.data;
           
            if (items.length > 0) {
              setMarketingList(items);
            }
          }
        }
      }
    }
    if (Object.keys(getSetting).length > 0) {
      if (getSetting.isGetSetting) {
          if (getSetting.getSetting.data) {

              const { data } = getSetting.getSetting;
              setLink([
                  {ppt: data.data.ppt !== null && data.data.ppt !== '' && data.data.ppt.length > 0 ? data.data.ppt : '#'},
                  {pdf: data.data.pdf !== null && data.data.ppt !== '' && data.data.ppt.length > 0 ? data.data.pdf : '#'
              }])
          }
      }
  }
  }, [ getMarketing ,getSetting])

  useEffect(() => {
    dispatch(feedbackAction.getMarketing(apiConstants.GET_MARKETING_LIST));
    setTimeout(() => {
      dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
  }, 300);
  }, [])

  return (
    <>
      <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Presentation</h1>
            </div>
          </div>
        </div>
      </section>
      <i className="card-img-top text-center p-1 fa fa-file-powerpoint-o" aria-hidden="true"></i>

      <section className="m-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              
              <h4 className='text-center f-22 m-3 blue-text'>Marketing</h4>
              <div className="d-flex justify-content-start flex-wrap m-1 p-3 box_shadow w-100">
                {marketingList && marketingList.length > 0 ?
                  marketingList.map((x , i) => {
                    let src = "/assets/images/banner.png";
                    if(x.fileType === "flyers"){
                        src = "/assets/images/flyer.png";
                    }
                    return (
                      <div className="card text-center m-2" key={i} style={{ width: "18rem" }}>
                        <img className="card-img-top text-center p-1" src={src}
                        style={{ 'width': '150px', 'height': '150px','margin':'0 auto' }}
                          alt="Card img cap" />
                        <div className="card-body">
                          <h5 className="card-title text-secondary">{ x.fileType }</h5>
                          <p className={"card-text" + ( x.type !== "unpaid" ? "text-sucess" : "text-danger") }> { x.type } </p>
                          <p className="card-text"> { x.price } </p>
                          <a target='blank' href={process.env.REACT_APP_BACKEND_URL +"/" + x.fileLink} className='btn btn-primary'>Download</a>
                        </div>
                      </div>
                    )
                  })
                  :
                  <p>No record found</p>
                }
              </div>

              <h4 className='text-center f-22 m-3 blue-text'>PPT</h4>
              <div className="d-flex justify-content-start flex-wrap m-1  p-3 box_shadow w-100">
                {link && link.length > 0 && link[0].ppt && link[0].ppt.length ?
                  link[0].ppt.map((x , i) => {
                    let src = "/assets/images/ppt.png";
                  
                    return (
                      <div className="card text-center m-2" key={i} style={{ width: "18rem" }}>
                        <img className="card-img-top text-center p-1" src={src} 
                         style={{ 'width': '180px','margin':'0 auto' }}  alt="pptImg" />
                                    
                        <div className="card-body">
                          <h5 className="card-title text-secondary">{ x.language }</h5>                       
                          <a target='blank' href={process.env.REACT_APP_BACKEND_URL +"/" + x.fileLink} className='btn btn-primary'>Download</a>
                        </div>
                      </div>
                    )
                  })
                  :
                  <p>No record found</p>
                }
              </div>

              <h4 className='text-center f-22 m-3 blue-text'>PDF</h4>
              <div className="d-flex justify-content-start flex-wrap m-1  p-3 box_shadow w-100">
                {link && link.length > 0 &&  link[1].pdf &&  link[1].pdf.length ?
                  link[1].pdf.map((x , i) => {
                    let src = "/assets/images/pdf.png";
                  
                    return (
                      <div className="card text-center m-2" key={i} style={{ width: "18rem" }}>
                        <img className="card-img-top text-center p-1" src={src}
                        style={{ 'width': '180px','margin':'0 auto' }}
                          alt="Card img cap" />
                        <div className="card-body">
                          <h5 className="card-title text-secondary">{ x.language }</h5>
                       
                          <a target='blank' href={process.env.REACT_APP_BACKEND_URL +"/" + x.fileLink} className='btn btn-primary'>Download</a>
                        </div>
                      </div>
                    )
                  })
                  :
                  <p>No record found</p>
                }
              </div>
            </div>
        
          </div>
        </div>
      </section>

    </>
  );
}