import React from 'react'
import { Nav } from 'react-bootstrap';
import { history } from '../../../helpers';

const Header = () => {

    return (
        <header style={{ padding: "5px", position: "absolute", zIndex: "1", 'backgroundColor': 'transparent', 'width': '100%' }}>
            <nav className="navbar navbar-expand-lg">
                <div className="container d-flex justify-content-between">
                    <div>
                        <Nav.Link className="navbar-brand p-0" onClick={() => history.push('/')}>
                            <img src="/assets/images/logo1.png" alt="logo" />
                        </Nav.Link>
                        {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-top">
                            <span className="navbar-toggler-icon"></span>
                        </button> */}
                    </div>
                    {/* <div className='d-flex justify-content-between'> */}
                        <div className='d-flex'>
                        <i className="fas fa-home mr-3 mt-1 text-white" onClick={() => history.push('/')}></i>
                        <div id="google_translate_element" className='register-page-translate'></div>
                        </div>
                    {/* </div> */}

                    {/* <div className="collapse navbar-collapse justify-content-end" id="nav-top">
                        <Nav as="ul" className="navbar-nav">
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/')}>Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/about-us')}>About</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/mission-vision')}>Mission / Vision</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/')}>Services</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/faq')}>FAQ's</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/')}>Charity</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/contact-us')}>Contact </Nav.Link>
                            </Nav.Item>
                            
                            {!isLoggedIn ?
                            <><Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link btn btn-header" onClick={()=>history.push('/login')}>Log in</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                 <Nav.Link className="nav-link" onClick={()=>history.push('/signup')}>Sign up</Nav.Link>
                            </Nav.Item></>
                            : 
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link className="nav-link" onClick={()=>logout()}>Logout</Nav.Link>
                            </Nav.Item> }
                        </Nav>
                    </div> */}
                </div>
            </nav>
        </header>
    )
}

export default Header;